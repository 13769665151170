import React from 'react'
import styled from 'styled-components'
// import Noise from '../assets/images/noise.png'

export const BodyWrapper = styled.div<{
  disabled?: boolean
  overflow?: string
  wideContainer?: boolean
  margin?: string
  padding?: string
  maxWidth?: string
}>`
  position: relative;
  max-width: ${({ wideContainer, maxWidth }) => maxWidth || (wideContainer ? '90%' : '451px')};
  width: 100%;
  background-color: ${({ theme }) => theme.bgMain};
  border-radius: 24px;
  padding: ${({ padding }) => padding || '20px'};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  margin: ${({ margin }) => margin || 0};
  z-index: 2;
  &::before {
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({
  children,
  disabled,
  overflow,
  wideContainer,
  margin,
  padding,
  maxWidth
}: {
  children: React.ReactNode
  disabled?: boolean
  overflow?: string
  wideContainer?: boolean
  margin?: string
  padding?: string
  maxWidth?: string
}) {
  return (
    <BodyWrapper
      disabled={disabled}
      overflow={overflow}
      wideContainer={wideContainer}
      margin={margin}
      padding={padding}
      maxWidth={maxWidth}
    >
      {children}
    </BodyWrapper>
  )
}
