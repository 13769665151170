import Modal from '../Modal'
import React, { useEffect, useMemo, useState } from 'react'
import Column from '../Column'
import { InputContainer, PaddedColumn, SearchInput } from '../SearchModal/styleds'
import { SelectToken } from '../NavigationTabs'
// import { SearchBarIcon } from '../SearchModal/CurrencySearchModal'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { MOONPAY_KEY, MOONPAY_TOKEN_LIST_URL, SIGN_MOONPAY_URL } from '../../constants'
import { useActiveWeb3React } from '../../hooks'

const GET_CHAIN_NAME = {
  1: 'ERC-20',
  56: 'BEP-20',
  137: 'Polygon',
  101: 'Solana',
  43114: 'Avalanche',
  2203: 'Multi Coin',
  42161: 'Arbitrum'
}

export default function SelectMoonpayToken({ open, onDismiss, type }: { open: boolean; onDismiss: any; type: string }) {
  const [textSearch, setTextSearch] = useState('')
  const [tokens, setTokens] = useState({
    buyTokens: [],
    sellTokens: []
  })

  const { account } = useActiveWeb3React()

  useEffect(() => {
    const getMoonpayTokens = async () => {
      const result = await axios.get(MOONPAY_TOKEN_LIST_URL)

      const buyTokens = []
      const sellTokens = []

      result.data.data.forEach(item => {
        if (item.allowBuy) {
          buyTokens.push(item)
        }
        if (item.allowSell) {
          sellTokens.push(item)
        }
      })

      setTokens({
        buyTokens,
        sellTokens
      })
    }

    getMoonpayTokens()
  }, [])

  const { t } = useTranslation()

  const listTokens = useMemo(() => {
    if (type === 'BUY') {
      return !textSearch
        ? tokens.buyTokens
        : tokens.buyTokens.filter(
            item => `${item.name} ${item.symbol}`.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1
          )
    }

    return !textSearch
      ? tokens.sellTokens
      : tokens.sellTokens.filter(
          item => `${item.name} ${item.symbol}`.toLowerCase().indexOf(textSearch.toLowerCase()) !== -1
        )
  }, [tokens, textSearch, type])

  const handleBuySellMoonpayToken = async token => {
    const windowReference = window.open()
    const BUY_MOONPAY_URL = 'buy.moonpay.com'
    const SELL_MOONPAY_URL = 'sell.moonpay.com'

    const endpoint = type === 'BUY' ? BUY_MOONPAY_URL : SELL_MOONPAY_URL
    const baseCurrencyCode = type === 'BUY' ? 'USD' : token.moonpayCode
    const currencyCode = type === 'BUY' ? token.moonpayCode : 'USD'

    const moonpayUrl = `https://${endpoint}?apiKey=${MOONPAY_KEY}&baseCurrencyCode=${baseCurrencyCode}&currencyCode=${currencyCode}&walletAddress=${
      token.chainId === 101 || token.chainId === 2203 ? '' : account
    }&colorCode=%2300a79d`

    const result = await axios.post(SIGN_MOONPAY_URL, { originalUrl: moonpayUrl })

    // window.open(result.data.urlWithSignature)

    windowReference.location = result.data.urlWithSignature
  }

  return (
    <Modal isOpen={open} onDismiss={onDismiss} maxHeight={70} minHeight={70} forceMaxHeight={'70vh'}>
      <Column style={{ width: '100%' }}>
        <PaddedColumn gap="0">
          <SelectToken
            onDismiss={onDismiss}
            title={type === 'BUY' ? 'Select a token to buy' : 'Select a token or coin to sell'}
          />
          <div className="mt-[16px] pb-[24px]">
            <InputContainer>
              {/* <SearchBarIcon /> */}
              <SearchInput
                type="text"
                id="token-search-input"
                placeholder={t('tokenSearchPlaceholder')}
                value={textSearch}
                onChange={e => setTextSearch(e.target.value)}
              />
            </InputContainer>
          </div>
        </PaddedColumn>
        <div className="list-tokens scroll">
          {listTokens?.map(token => (
            <div
              className="flex items-center py-[16px] px-[20px] moonpay-token cursor-pointer"
              key={token.moonpayCode}
              onClick={() => {
                handleBuySellMoonpayToken(token)
              }}
            >
              <div className="flex items-center flex-1">
                <img src={token.image} className="w-[24px] mr-[14px]" alt="logo" />
                <p className="mb-0 text-[16px] font-bold">{token.symbol}</p>
              </div>
              <p className="mb-0 text-[16px] font-medium">
                <span className="label-text font-normal">{GET_CHAIN_NAME[token.chainId]}</span>
              </p>
            </div>
          ))}
        </div>
      </Column>
    </Modal>
  )
}
