import React, { useRef, useState } from "react"
import classNames from "classnames"
import renderField from "../renderField"
import classes from "./InputField.module.scss"

const InputField = ({
  name,
  options,
  customClass,
  errorClass,
  note,
  maxLength,
  hasError,
  placeholder,
  input,
  h50,
  theme,
  disabled,
  inputType
}: any) => {
  const inputRef = useRef(null)
  const [focus, setFocus] = useState(false)

  const onChange = (e) => {
    const value = e.target.value
    if (inputType === "number") {
      if (!value || !isNaN(value)) {
        input.onChange(value)
      }
    } else {
      input.onChange(value)
    }
  }

  const onFocus = () => {
    setFocus(true)
    if (input && input.onFocus) {
      input.onFocus()
    }
  }

  const onBlur = () => {
    setFocus(false)
    if (input && input.onBlur) {
      input.onBlur()
    }
  }

  return (
    (
      <div>
        <div className={classes.inputWrapper}>
          <div
            className={classNames(
              classes.inputContainer,
              theme === "light" && classes.light
            )}
          >
            <input
              {...options}
              {...input}
              onChange={onChange}
              name={name}
              className={classNames(
                classes.input,
                customClass,
                hasError && classes.errorField,
                hasError && errorClass,
                focus && classes.focus,
                disabled && classes.disabled,
                h50 && classes.h50,
                "inputField"
              )}
              disabled={disabled}
              onFocus={onFocus}
              onBlur={onBlur}
              maxLength={maxLength}
              placeholder={placeholder}
              ref={inputRef}
            />
            
          </div>
        </div>
        {note && <p className={classes.note}>{note}</p>}
      </div>
    )
  )
}



export default renderField(InputField)
