import React, { useState } from 'react'
import CopyHelper from '../../pages/Swap/Copy'
import { useActiveWeb3React } from '../../hooks'
import { NETWORK_ICON } from '../Header'
import { displayAddress, getChainName, getContract, getEtherscanLink, getExplore } from '../../utils'
import warningIcon from '../../assets/images/warning.svg'
import checkIcon from '../../assets/images/check.svg'
import checkedIcon from '../../assets/images/checked.svg'
import questionIcon from '../../assets/images/question.svg'
import { Helper } from '../QuestionHelper'
import { IInactiveToken } from '../../interfaces/Tokens'
import CurrencyLogo from '../CurrencyLogo'
import { ERC20_ABI } from '../../constants/abis/erc20'
import { ExendToken, useAddUserToken } from '../../state/user/hooks'
import { Currency } from '@safemoon/sdk'
import { ButtonError } from '../Button'

interface WarningImportProps {
  onDismiss: () => void
  setInactiveTokens: React.Dispatch<React.SetStateAction<IInactiveToken[]>>
  token: IInactiveToken
  onCurrencySelect: (currecy: Currency) => void
}

const WarningImport = ({ onDismiss, token, setInactiveTokens, onCurrencySelect }: WarningImportProps) => {
  const [confirmed, setConfirmed] = useState(false)
  const { chainId, library } = useActiveWeb3React()
  const addToken = useAddUserToken()

  const onImportToken = async (currency: IInactiveToken) => {
    if (setInactiveTokens) {
      setInactiveTokens(prevTokens => prevTokens.filter(item => item?.address !== currency?.address))
    }
    let tk: any = currency
    if (currency?.decimals) {
      addToken(currency as any)
    } else {
      const erc20Contract = getContract(currency.address, ERC20_ABI, library)
      const decimals = await erc20Contract.decimals()
      addToken({
        ...currency,
        decimals
      } as any)
      tk = {
        ...currency,
        decimals
      }
    }

    console.log('tk ==>', tk)

    if (onCurrencySelect) {
      onCurrencySelect(new ExendToken(tk.chainId, tk.address, tk.decimals, tk.symbol, tk.name, tk.logoURI))
    }

    onDismiss()
  }

  return (
    <div className="p-[20px]">
      <div className="flex items-center">
        <a className="cursor-pointer mr-[10px]" onClick={onDismiss}>
          <img src={'/images/right-arrow.svg'} className="w-[24px]" />
        </a>
        <CurrencyLogo currency={token} />
        <p className="px-[6px] font-semibold text-[16px]">{token?.symbol}</p>
        <Helper text="Open on Explore" className="h-[20px] mx-[10px]" placement="top">
          <a href={getEtherscanLink(chainId, token?.address, 'address')} target="_blank" rel="noreferrer">
            <img src={NETWORK_ICON[chainId]} className="w-[20px]" alt="networkIcon" />
          </a>
        </Helper>
        <Helper text="Copy address" className=" h-[20px]" placement="top">
          <CopyHelper toCopy={token?.address} />
        </Helper>
      </div>

      <div className="flex items-start my-[28px] border-[1px] border-[#ffb237] rounded-[20px] p-[20px]">
        <img src={warningIcon} className="w-[20px]" />
        <div className="flex-1 px-[10px]">
          <p className="mb-[16px] text-[16px]">
            Anyone can create tokens on {getChainName(chainId)} with any name, including creating fake versions of
            existing tokens and tokens that claim to represent projects that do not have a token.
          </p>
          <p className="font-bold text-[16px]">
            If you purchase a fraudulent token, you may be exposed to permanent loss of funds.
          </p>
        </div>
      </div>

      <div className="flex items-center mb-[28px]">
        <div className="flex-1">
          <p className="text-[16px] mb-[4px]">
            {token?.name} ({token?.symbol})
          </p>
          <p className="text-[16px] mb-[4px]">{displayAddress(token?.address)}</p>
          <a
            className="!text-[16px] link"
            href={getEtherscanLink(chainId, token?.address, 'address')}
            target="_blank"
            rel="noreferrer"
          >
            (View on {getExplore(chainId)})
          </a>
        </div>
        <div className="">
          <Helper text="Although no obvious risk is found in the address, it is not guaranteed that there is 100% no risk for the address as the project can still fail or the token price can go down under natural market forces.<br />Risk scan results are provided by a third party, HashDit">
            <div className="flex items-center px-[8px] h-[32px] bg-[#03D5C0] rounded-full text-[13px] text-black font-semibold">
              Some Risk
              <img src={questionIcon} alt="question" className="w-[15px] ml-[6px] mb-[2px]" />
            </div>
          </Helper>
        </div>
      </div>

      <div
        className={'flex items-center justify-start cursor-pointer mb-[12px]'}
        onClick={() => {
          setConfirmed((prev: boolean) => !prev)
        }}
      >
        <img src={confirmed ? checkedIcon : checkIcon} className={'checkIcon mr-[8px]'} alt="check" />
        <span>I understand</span>
      </div>

      <ButtonError
        disabled={!confirmed}
        onClick={() => {
          if (confirmed) {
            onImportToken(token)
          }
        }}
      >
        Continue
      </ButtonError>
    </div>
  )
}

export default WarningImport
