import { useEffect, useState } from 'react'
import axios from 'axios'
import { MARKETDATA_URL } from '../constants'

export const useShowSFMBridge = () => {
  const [showSFMBridge, setShowSFMBridge] = useState()

  useEffect(() => {
    const getBridgeConfig = async () => {
      try {
        const result = await axios.get(`${MARKETDATA_URL}/api/param/bridge-config`)

        setShowSFMBridge(result?.data?.showSFMBridge)
      } catch (e) {}
    }

    getBridgeConfig()
  }, [])

  return showSFMBridge
}
