import React, { useContext, useMemo, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Pair } from '@safemoon/sdk'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FullPositionCard from '../../components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from '../../state/wallet/hooks'
import { StyledInternalLink, TYPE } from '../../theme'
import { Text } from 'rebass'
import { LightCard } from '../../components/Card'
import { RowBetween } from '../../components/Row'
import { ButtonPrimary } from '../../components/Button'

import { useActiveWeb3React } from '../../hooks'
import { usePairs } from '../../data/Reserves'
import { useTokenPairsWithLiquidityTokens } from '../../data/PairAddress'
import { useTrackedTokenPairs } from '../../state/user/hooks'
import AppBody from '../AppBody'
import { Dots } from '../../components/swap/styleds'
import QuestionHelper from '../../components/QuestionHelper'

import { useSettingsMenuOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { SettingsWrapper } from '../Swap'
import SVG from 'react-inlinesvg'
import SettingsModal from '../../components/SettingsModal'
import { LiquidityTabs } from '../../components/NavigationTabs'
import { InputContainer, SearchInput } from '../../components/SearchModal/styleds'

const InternalLink = styled(StyledInternalLink)`
  color: ${({ theme }) => theme.primaryMain};
  font-weight: bold;
`

export default function Pool() {
  const [currentTab, setCurrentTab] = useState('YOUR_LIQUIDITY')
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [text, setText] = useState('')
  const [textSearch, setTextSearch] = useState('')
  const timeout = useRef<any>()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()

  const open = useSettingsMenuOpen()
  const toggle = useToggleSettingsMenu()

  const node = useRef<HTMLDivElement>()

  const handleClickOutside = (e: any) => {
    if (node.current?.contains(e.target) ?? false) {
    } else {
      toggle()
    }
  }

  const tokenPairsWithLiquidityTokens = useTokenPairsWithLiquidityTokens(trackedTokenPairs)

  // console.log('tokenPairsWithLiquidityTokens', tokenPairsWithLiquidityTokens)
  // const tokenPairsWithLiquidityTokens = useMemo(
  //   () => trackedTokenPairs.map(tokens => ({ liquidityToken: toV2LiquidityToken(tokens), tokens })),
  //   [trackedTokenPairs]
  // )
  const liquidityTokens = useMemo(() => tokenPairsWithLiquidityTokens.map(tpwlt => tpwlt.liquidityToken), [
    tokenPairsWithLiquidityTokens
  ])
  // console.log('liquidityTokens', liquidityTokens)
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2PairsAll = usePairs(tokenPairsWithLiquidityTokens.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some(V2Pair => !V2Pair)
  // console.log(v2PairsAll)
  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
  const allV2PairsWithLiquidityAll = v2PairsAll
    .map(([, pair]) => pair)
    .filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))
    .sort(pair1 =>
      pair1.token0.symbol === 'VGX' || pair1.token1.symbol === 'VGX'
        ? -1
        : pair1.token0.symbol === 'SFM' || pair1.token1.symbol === 'SFM'
        ? -1
        : 1
    )

  const allV2PairsWithLiquidityAllWithSearch = useMemo(() => {
    const search = textSearch.toLowerCase()
    return textSearch
      ? allV2PairsWithLiquidityAll.filter(
          pair =>
            `${pair.token0.symbol}#${pair.token0.name}#${pair.token1.symbol}#${pair.token1.name}#${pair.liquidityToken.address}`
              .toLowerCase()
              .indexOf(search) !== -1
        )
      : allV2PairsWithLiquidityAll
  }, [textSearch, allV2PairsWithLiquidityAll])

  const handleChangeText = (e: any) => {
    const val = e.target.value
    setText(val)
    clearTimeout(timeout.current)
    timeout.current = setTimeout(() => {
      setTextSearch(val)
    }, 500)
  }

  return (
    <>
      <AppBody>
        <RowBetween className="!mb-[24px]">
          <QuestionHelper text={t('whenyouaddtokenliquidity')} className="!ml-[-4px]" />
          <p className="text-[24px] font-bold text-white">Liquidity Pair Farming</p>
          <SettingsWrapper onClick={toggle}>
            <SVG src={'/images/settings.svg'} width={24} height={24} />
          </SettingsWrapper>
        </RowBetween>

        <LiquidityTabs currentTab={currentTab} handleSelectTab={setCurrentTab} />
        <>
          {!account ? (
            <LightCard padding="64px">
              <TYPE.body fontSize={14} color={theme.text3} textAlign="center">
                {t('connectToViewLiquidity')}
              </TYPE.body>
            </LightCard>
          ) : v2IsLoading ? (
            <LightCard padding="64px">
              <TYPE.body fontSize={16} color={theme.text3} textAlign="center" fontWeight={500}>
                <Dots>{t('Loading')}</Dots>
              </TYPE.body>
            </LightCard>
          ) : allV2PairsWithLiquidity?.length > 0 && currentTab === 'YOUR_LIQUIDITY' ? (
            <>
              {allV2PairsWithLiquidity.map(v2Pair => (
                <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} border={theme.text3} />
              ))}
            </>
          ) : allV2PairsWithLiquidityAll?.length > 0 && currentTab === 'ALL_LPF_POOLS' ? (
            <div>
              <div className="mt-[16px] pb-[12px]">
                <InputContainer>
                  {/* <SearchBarIcon /> */}
                  <SearchInput
                    type="text"
                    id="pair-search-input"
                    placeholder={'Search token name or pair address'}
                    value={text}
                    onChange={handleChangeText}
                  />
                </InputContainer>
              </div>
              {allV2PairsWithLiquidityAllWithSearch.map(v2Pair => (
                <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} border={theme.text3} />
              ))}
            </div>
          ) : (
            <LightCard padding="64px">
              <TYPE.body fontSize={16} color={theme.text3} textAlign="center" fontWeight={500}>
                {t('noLiquidityFound')}
              </TYPE.body>
            </LightCard>
          )}
        </>

        <ButtonPrimary id="join-pool-button" as={Link} style={{ padding: 16 }} to="/lpf/add/ETH" className="mt-[24px]">
          <Text fontWeight={700} fontSize={16}>
            {t('addLiquidity')}
          </Text>
        </ButtonPrimary>
        <NavLink
          to="/lpf/reward"
          className="text-[16px] font-bold underline underline-offset-4 text-white mb-[48px] mt-[24px] block text-center"
        >
          Manage Your LPF Rewards
        </NavLink>

        <div>
          <Text fontSize={14} color={theme.text3} textAlign={'center'}>
            {t('dontseeapoolyoujoined')}{' '}
            <InternalLink id="import-pool-link" to={'/lpf/find'}>
              {t('importit')}
            </InternalLink>
          </Text>
        </div>
        <SettingsModal open={open} onDismiss={handleClickOutside} isBridge={true} />
      </AppBody>
    </>
  )
}
