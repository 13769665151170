import { ChainId, Currency, ETHER, Token } from '@safemoon/sdk'
import React, { useState } from 'react'
import styled from 'styled-components'

import BNBLogo from '../../assets/images/bnb.svg'
import EthereumLogo from '../../assets/images/Ethereum.svg'
import MATICLogo from '../../assets/images/matic.png'
import AvalancheLogo from '../../assets/images/avalanche.png'
import ftmLogo from '../../assets/images/ftm.png'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { useActiveWeb3React } from '../../hooks'
import { ExendToken } from '../../state/user/hooks'

const getTokenLogoURL = (token: WrappedTokenInfo | string) => {
  if (token instanceof WrappedTokenInfo) {
    return token.logoURI
  }
  return `/images/${token}.svg`
}
const BAD_URIS: { [tokenAddress: string]: true } = {}

const Image = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 1rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const Emoji = styled.span<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ size }) => size};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-bottom: -4px;
`

const StyledEthereumLogo = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  border-radius: 24px;
`

export default function CurrencyLogo({
  currency,
  size = '24px',
  ...rest
}: {
  currency?: Currency
  size?: string
  style?: React.CSSProperties
  className?: any
}) {
  const { chainId } = useActiveWeb3React()
  const [, refresh] = useState<number>(0)

  if (currency === ETHER) {
    return (
      <StyledEthereumLogo
        src={
          chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET
            ? BNBLogo
            : chainId === ChainId.MUMBAI_TESTNET || chainId === ChainId.POLYGON
            ? MATICLogo
            : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
            ? AvalancheLogo
            : chainId === ChainId.FANTOM
            ? ftmLogo
            : EthereumLogo
        }
        size={size}
        {...rest}
      />
    )
  }

  if (currency?.symbol === 'WMATIC' || currency?.symbol === 'WPOL') {
    return (
      <StyledEthereumLogo
        src={
          'https://safemoon-dashboard.s3.ap-southeast-1.amazonaws.com/16812452514220x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270.png'
        }
        size={size}
        {...rest}
      />
    )
  }

  if (currency?.symbol === 'SAFEMOON' || currency?.symbol === 'SFMV1') {
    return <StyledEthereumLogo src={'https://swap.safemoon.com/logo.png'} size={size} {...rest} />
  }

  if ((currency as ExendToken)?.logoURI) {
    return (
      <Image
        {...rest}
        alt={`${currency.name} Logo`}
        src={(currency as ExendToken).logoURI}
        size={size}
        onError={() => {
          if (currency instanceof Token) {
            BAD_URIS[(currency as ExendToken).logoURI] = true
          }
          refresh(i => i + 1)
        }}
      />
    )
  }

  if (currency instanceof Token) {
    let uri: string | undefined

    if (currency instanceof WrappedTokenInfo) {
      uri = getTokenLogoURL(currency)
    }

    if (!uri) {
      const defaultUri = getTokenLogoURL(currency?.symbol)
      if (!BAD_URIS[defaultUri]) {
        uri = defaultUri
      }
    }

    if (uri) {
      return (
        <Image
          {...rest}
          alt={`${currency.name} Logo`}
          src={uri}
          size={size}
          onError={() => {
            if (currency instanceof Token) {
              BAD_URIS[uri] = true
            }
            refresh(i => i + 1)
          }}
        />
      )
    }
  }

  return (
    <Emoji {...rest} size={size}>
      <span role="img" aria-label="Thinking" style={{ fontSize: 16 }}>
        🤔
      </span>
    </Emoji>
  )
}
