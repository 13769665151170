import { ChainId } from '@safemoon/sdk'
import MULTICALL_ABI from './abi.json'

const MULTICALL_NETWORKS: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  [ChainId.ROPSTEN]: '0x53C43764255c17BD724F74c4eF150724AC50a3ed',
  [ChainId.KOVAN]: '0x2cc8688C5f75E365aaEEb4ea8D6a480405A48D2A',
  [ChainId.RINKEBY]: '0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821',
  [ChainId.GÖRLI]: '0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e',
  [ChainId.BSC_MAINNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [ChainId.BSC_TESTNET]: '0xe348b292e8eA5FAB54340656f3D374b259D658b8',
  [ChainId.SEPOLIA_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.MUMBAI_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.POLYGON]: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  [ChainId.ARB_TESTNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.AVALANCHE_FUJI]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.BASE_SEPOLIA]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.ARB_MAINNET]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.AVALANCHE_C]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.BASE_MAINNET]: '0xca11bde05977b3631167028862be2a173976ca11',
  [ChainId.LINEA]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.FANTOM]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.OPTIMISM]: '0xcA11bde05977b3631167028862bE2a173976CA11',
  [ChainId.BLAST]: '0xcA11bde05977b3631167028862bE2a173976CA11'
}

export { MULTICALL_ABI, MULTICALL_NETWORKS }
