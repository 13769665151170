import React, { useEffect, useState } from 'react'
import { Field, Form, localForm } from '../../Form'
import InputField from '../../Form/InputField/InputField'
import { useTokenAllowance } from '../../data/Allowances'
import { useToken } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { LPF_ADDRESS } from '../../constants'
import { useLPFContract, usePairContract } from '../../hooks/useContract'
import { ButtonPrimary } from '../../components/Button'
import { parseUnits } from 'ethers/lib/utils'
import { CustomLightSpinner } from '../../components/TransactionConfirmationModal'
import Circle from '../../assets/images/blue-loader.svg'
import classNames from 'classnames'
import { toast } from 'react-toastify'

const TransferLp = ({handleClose, selectedItem, updateProperty, values, handleTouched, initialValues, submitForm, getLpsPriceRangeInfo}: any) => {
  const { account, chainId } = useActiveWeb3React()

  const [loading, setLoading] = useState(false)

  const token = useToken(selectedItem?.pairAddress)

  useEffect(() => {
    setTimeout(() => {
      initialValues({
        pairAddress: selectedItem.pairAddress
      })
    }, 300)
  }, [selectedItem])

  const LPF = useLPFContract()
  const pairContract = usePairContract(selectedItem?.pairAddress)

  const currentAllowance = useTokenAllowance(token, account ?? undefined, LPF_ADDRESS[chainId])

  const handleApprove = async (values) => {
    try {
      setLoading(true)
      const spender = LPF_ADDRESS[chainId]
      const amount = parseUnits(values.amount, 'ether').toString()

      await pairContract
        ?.approve(spender, amount)
        .then((receipt) => {
          receipt.wait().then(() => {
            setLoading(false)
          })
        })
        .catch((e: any) => {
          setLoading(false)
          let message = ''
          if (e?.code === 4001) {
            message = 'User Rejected the transaction'
          } else {
            if (typeof e === 'string') {
              message = 'Error:' + `${e}`
            } else if (e instanceof Error) {
              message = 'Error:' + `${e.message}`
            }
          }
          toast(message, {
            type: "error",
            theme: "dark",
          })
        })
    } catch(e) {
      setLoading(false)
    }
  }

  const handleTransfer = async (values) => {
    try {
      setLoading(true)

      const amount = parseUnits(values.amount, 'ether').toString()

      await LPF.transferLp(values.toAddress, values.pairAddress, amount)
        .then((receipt) => {
          receipt.wait().then(() => {
            getLpsPriceRangeInfo()
            setLoading(false)
          })
        })
        .catch((e: any) => {
          console.log('error', e.message)
          setLoading(false)
          let message = ''
          if (e?.code === 4001) {
            message = 'User Rejected the transaction'
          } else {
            message = e.data?.message || 'Some thing went wrong, please try again later'
          }
          toast(message, {
            type: "error",
            theme: "dark",
          })
        })

      setLoading(false)
    } catch(e) {
      setLoading(false)
    }
  }



  return (
    <div className='w-full'>
      <div className='flex items-center justify-between px-[20px] py-[12px]'>
        <h2 className={'text-[20px] font-bold'}>
          Transfer LP
        </h2>
        <a className='cursor-pointer' onClick={handleClose}>
          <img src={'/images/close.svg'} className='w-[24px]'/>
        </a>
      </div>

      <Form className='p-[20px]'
        onSubmit={submitForm(
          +values?.amount?.value &&
          currentAllowance?.raw.toString() <
            parseUnits(values?.amount?.value, 'ether').toString() ? handleApprove : handleTransfer, true
        )}
      >
        <Field name='pairAddress'
          value={values.pairAddress}
          updateProperty={updateProperty}
          handleTouched={handleTouched}
          component={InputField}
          label='Pair'
          placeholder='Enter pair address'
        />

        <Field name='amount'
          value={values.amount}
          updateProperty={updateProperty}
          handleTouched={handleTouched}
          component={InputField}
          label='Amount'
          placeholder='Amount To Transfer'
        />

        <Field name='toAddress'
          value={values.toAddress}
          updateProperty={updateProperty}
          handleTouched={handleTouched}
          component={InputField}
          label='Transfer To'
          placeholder='Transfer To'
        />

          <ButtonPrimary type='submit' className={classNames(loading && 'pointer-events-none')}>
            {(+values?.amount?.value &&
              currentAllowance?.raw.toString() <
              parseUnits(values?.amount?.value, 'ether').toString()) ? 'Approve' : 'Transfer'}
              {loading && <CustomLightSpinner src={Circle} alt="loader" size={'20px'} className='ml-[10px]' />}
          </ButtonPrimary>
      </Form>
      
    </div>
  )
}

const validate = (values) => {
  const errors: any = {}
  if (!values.amount) {
    errors.amount = 'Please enter transfer amount'
  } else if (+values.amount <= 0) {
    errors.amount = 'Please enter valid amount > 0'
  }

  if (!values.toAddress) {
    errors.toAddress = 'Please enter transfer to address'
  }

  if (!values.pairAddress) {
    errors.pairAddress = 'Please enter pair address'
  }

  return errors
}

export default localForm({
  validate,
  MyComponent: TransferLp
}) 
