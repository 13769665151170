import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '@safemoon/sdk'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  injected,
  binanceinjected,
  walletconnectBSC,
  walletconnectPolygon,
  walletconnect,
  walletconnectMultiTestnet
} from '../connectors'
import { NetworkContextName, popupEmitter, PopupTypes, CHECK_WALLETADDRESS_BLACKLIST_URL } from '../constants'
import { useGetCurrentAccount, useGetCurrentConnector } from '../state/user/hooks'
import axios from 'axios'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  // console.log('context', context)
  // console.log('contextNetwork', contextNetwork)
  return context.active ? context : contextNetwork
}

export function useEagerConnect() {
  const { activate, active, account, deactivate, connector } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)
  const [currentAccount, setCurrentAccount] = useGetCurrentAccount()
  const [currentConnector, setCurrentConnector] = useGetCurrentConnector()
  useEffect(() => {
    const checkBlacklist = async () => {
      const result = await axios.post(CHECK_WALLETADDRESS_BLACKLIST_URL, {
        addresses: [account],
        source: 'WEB_SWAP'
      })
      const isBadAccount = result?.data?.data?.[0]?.isBlacklist
      if (isBadAccount) {
        popupEmitter.emit(PopupTypes.BLACKLIST_WALLET)
        deactivate()
        localStorage.clear()
      }
    }

    if (account && typeof account === 'string') {
      checkBlacklist()
    }
  }, [account, deactivate])

  useEffect(() => {
    if (connector === binanceinjected) {
      setCurrentConnector('BINANCE')
    } else {
      setCurrentConnector('')
    }
  }, [connector])

  useEffect(() => {
    if (account) {
      setCurrentAccount(account)
    } else {
      setCurrentAccount('')
    }
  }, [account, setCurrentAccount])

  const activateInjectedProvider = (providerName: 'MetaMask' | 'CoinBase') => {
    const { ethereum }: any = window

    if (!ethereum?.providers) {
      return undefined
    }

    let provider
    switch (providerName) {
      case 'CoinBase':
        provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet)
        break
      case 'MetaMask':
        provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask)
        break
    }

    if (provider) {
      ethereum.setProvider(provider)
    }
  }

  useEffect(() => {
    const walletconnectstr = localStorage.getItem('walletConnect')

    if (walletconnectstr) {
      const chainId: any = walletconnectstr
      let walletConnector: any = walletconnect
      if (
        +chainId === ChainId.MAINNET ||
        +chainId === ChainId.BSC_MAINNET ||
        +chainId === ChainId.POLYGON ||
        +chainId === ChainId.ARB_MAINNET ||
        +chainId === ChainId.AVALANCHE_C ||
        +chainId === ChainId.BASE_MAINNET ||
        +chainId === ChainId.LINEA ||
        +chainId === ChainId.FANTOM ||
        +chainId === ChainId.OPTIMISM ||
        +chainId === ChainId.BLAST
      ) {
        walletConnector = walletconnect
      }
      if (
        +chainId === ChainId.BSC_TESTNET ||
        +chainId === ChainId.SEPOLIA_TESTNET ||
        +chainId === ChainId.ARB_TESTNET ||
        +chainId === ChainId.MUMBAI_TESTNET ||
        +chainId === ChainId.BASE_SEPOLIA ||
        +chainId === ChainId.AVALANCHE_FUJI
      ) {
        walletConnector = walletconnectMultiTestnet
      }

      activate(walletConnector, undefined, true).catch(() => {
        setTried(true)
      })
      return
    }

    activateInjectedProvider('MetaMask')
    injected.isAuthorized().then(isAuthorized => {
      if (isAuthorized && currentAccount && currentConnector !== 'BINANCE') {
        activate(injected, undefined, true).catch(() => {
          setTried(true)
        })
      } else {
        if (isMobile && window.ethereum) {
          activate(injected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          setTried(true)
        }
      }
    })
  }, []) // intentionally only running on mount (make sure it's only mounted once :))

  useEffect(() => {
    if (currentConnector === 'BINANCE') {
      binanceinjected.isAuthorized().then(isAuthorized => {
        if (isAuthorized && currentAccount && currentConnector === 'BINANCE') {
          activate(binanceinjected, undefined, true).catch(() => {
            setTried(true)
          })
        } else {
          if (isMobile && window.BinanceChain) {
            activate(binanceinjected, undefined, true).catch(() => {
              setTried(true)
            })
          } else {
            setTried(true)
          }
        }
      })
    }
  }, [currentConnector]) // intentionally only running on mount (make sure it's only mounted once :))

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active && !tried) {
      setTried(true)
    }
  }, [active, tried])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
  const { active, error, activate, deactivate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { ethereum } = window

    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleChainChanged = () => {
        // eat errors
        activate(injected, undefined, true).catch(error => {
          console.log('error', error)
        })
      }

      const handleAccountsChanged = async (accounts: string[]) => {
        if (accounts.length > 0) {
          const result = await axios.post(CHECK_WALLETADDRESS_BLACKLIST_URL, {
            addresses: accounts,
            source: 'WEB_SWAP'
          })
          const isBadAccount = result?.data?.data.find((item: any) => item.isBlacklist)

          if (isBadAccount) {
            popupEmitter.emit(PopupTypes.BLACKLIST_WALLET)
            deactivate()
            localStorage.clear()
          } else {
            // eat errors
            activate(injected, undefined, true).catch(error => {
              console.error('Failed to activate after accounts changed', error)
            })
          }
        }
      }

      ethereum.on('chainChanged', handleChainChanged)
      ethereum.on('accountsChanged', handleAccountsChanged)

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged)
          ethereum.removeListener('accountsChanged', handleAccountsChanged)
        }
      }
    }
    return
  }, [active, error, suppress, activate, deactivate])
}
