import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div`
  width: 320px;
  padding: 1.125rem 1rem;
  line-height: 24px;
  font-size: 0.875rem;
  font-weight: 400;
  white-space: pre-wrap;
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text?: string
  component?: any
}

export default function Tooltip({ text, component, ...rest }: TooltipProps) {
  return (
    <Popover
      content={
        component || (
          <TooltipContainer>
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </TooltipContainer>
        )
      }
      {...rest}
    />
  )
}

export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip placement="right" {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  )
}
