/* eslint-disable */
import { BigNumber as BigNumberEther } from '@ethersproject/bignumber'
import { ChainId, CurrencyAmount, ETHER, JSBI, TokenAmount, Trade, WETH } from '@safemoon/sdk'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import SVG from 'react-inlinesvg'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import axios from 'axios'
import { concat, numberToHex, size, type Hex } from "viem"
import { CustomLightSpinner } from '../../components/TransactionConfirmationModal'
import { ButtonError, ButtonPrimary } from '../../components/Button'
import { GreyCard } from '../../components/Card'
import { AutoColumn } from '../../components/Column'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, Dots, Wrapper } from '../../components/swap/styleds'
import { TokenWarningCards } from '../../components/TokenWarningCard'

import { getTradeVersion } from '../../data/V1'
import { useActiveWeb3React } from '../../hooks'
import { useETHFee, useGasPrice, useDexFee, useIsUseGasless } from '../../state/user/hooks'
import {
  ApprovalState,
  useApproveCallbackFromMigrate,
  useApproveCallbackFromOxApis,
  useApproveCallbackFromTrade
} from '../../hooks/useApproveCallback'
import useENSAddress from '../../hooks/useENSAddress'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { useSettingsMenuOpen, useToggleSettingsMenu, useWalletModalToggle } from '../../state/application/hooks'
import { Field } from '../../state/swap/actions'
import {
  tryParseAmount,
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserDeadline,
  useUserSlippageTolerance,
  useHideSlippageWarning
} from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import { WalletConnectConnector } from '../../connectors/WalletConnector'

import QuestionHelper from '../../components/QuestionHelper'
import SettingsModal from '../../components/SettingsModal'
import getTokenSymbol, { getNativeSymbol } from '../../utils/getTokenSymbol'
import { SlippageWarning } from '../../components/SlippageWarning/SlippageWarning'
import './Swap.css'
import { useAllTokens, useCurrency } from '../../hooks/Tokens'
import { useFFSContract } from '../../hooks/useContract'
import ConsolidateV2Intro from './ConsolidateV2Intro'
import SlippageWarningPopup from './SlippageWarningPopup'
import Circle from '../../assets/images/blue-loader.svg'


import {
  appEnv,
  BINANCE_NETWORK,
  BLACKLIST_TOKENS_SAFEMOON_V1,
  ChainIdHex,
  consolidation,
  GET_PRICE_URL,
  GET_QUOTE_URL,
  HEX_CHAINS,
  MARKETDATA_URL,
  MAX_PRIORITY_FEE,
  SFM_V1,
  SFM_V2,
  TOKENS_SAFEMOON_V2
} from '../../constants'

import useMigrationCallback, { MigrateType } from '../../hooks/useMigrationCallback'
import BigNumber from 'bignumber.js'
// import WarningMigrate from './WarningMigrate'
import { WrappedTokenInfo } from '../../state/lists/hooks'
// import Banner from './Banner'
import DropdowAction from './DropdownActions'
import { activeNetwork, binanceinjected, injected } from '../../connectors'
import Web3 from 'web3'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { calculateGasMargin, formatStringToNumber, getSigner, handleSwitchChainInjected, toWei } from '../../utils'
import { NetworkConnector } from '../../connectors/NetworkConnector'
import { useETHBalances } from '../../state/wallet/hooks'
import { PairState, usePair } from '../../data/Reserves'
import { SignatureType, splitSignature } from '../../utils/signature'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { use0xApiSupport, useSupportedGasless } from '../../hooks/0xApis'
import { isMobile } from 'react-device-detect'

export const SettingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 0;
  opacity: 0.5;

  :hover,
  :focus {
    opacity: 1;
  }
`

export default function Swap({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const [showConsolidateV2Intro, setShowConsolidateV2Intro] = useState(false)
  const [showSlippageWarning, setShowSlippageWarning] = useState(false)
  const [priceUsd, setPriceUsd] = useState<any>({})
  const [allowSetSlippage, setAllowSetSlippage] = useState(true)
  const [initial, setInitial] = useState(true)
  const [nativePriceUsd, setNativePriceUsd] = useState(0)
  const [priceInfo, setPriceInfo] = useState<any>()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [quoteInfo, setQuoteInfo] = useState<any>()
  const [signApprovalSignature, setSignApprovalSignature] = useState('')
  const [signTradeSignature, setSignTradeSignature] = useState('')
  
  const timeout = useRef<any>(null)

  const [maxSwapFee, setMaxSwapFee] = useState<string>()

  const ffsContract = useFFSContract()

  const allTokens = useAllTokens()

  const params = useParsedQueryString()

  const node = useRef<HTMLDivElement>()
  const open = useSettingsMenuOpen()
  const toggle = useToggleSettingsMenu()

  const handleClickOutside = (e: any) => {
    if (node.current?.contains(e.target) ?? false) {
    } else {
      toggle()
    }
  }

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const { account, chainId, activate, connector, library } = useActiveWeb3React()

  const ETHBalance = useETHBalances([account as string])[account as string]

  useEffect(() => {
    if (account && chainId && +chainId === 56 && connector instanceof WalletConnectConnector) {
      ;(connector as WalletConnectConnector)?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ChainIdHex.BSC_MAINNET }]
      })
    }
  }, [account, chainId])

  useEffect(() => {
    const handleConnectWallet = async () => {
      if (params?.viewMode === 'mobile' && params.platform === 'macos' && params.currentChain) {
        const currenctChainId: any = +params.currentChain
        if (window.ethereum) {
          const web3 = new Web3(window.ethereum as any)
          await handleSwitchChainInjected(currenctChainId)
          const accounts = await web3.eth.getAccounts()
          if (accounts[0]) {
            activate(injected, undefined, true).catch(error => {
              console.error('Failed to activate after accounts changed', error)
            })
          }
        }
      }
    }

    handleConnectWallet()
  }, [params.viewMode, params.platform, params.currentChain])

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle()

  // for expert mode
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [allowedSlippage, setAllowedSlippage] = useUserSlippageTolerance()

  const [hideSlippageWarning, handleHideSlippageWarning] = useHideSlippageWarning()
  const ethFee = useETHFee()
  const dexFee = useDexFee()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()
  const { v2Trade, currencyBalances, parsedAmount, currencies, inputError: swapScreenInputError } = useDerivedSwapInfo()
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )

  

  const { migrateType, execute: onMigrate, inputError: migrateInputError } = useMigrationCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )

  const gasPrice = useGasPrice()

  useEffect(() => {
    if (params.chainId && chainId && +params.chainId !== +chainId && !isNaN(+params.chainId)) {
      handleChangeNetwork(+params.chainId)
    }
  }, [params?.chainId])

  // useEffect(() => {
  //   const outputAddress = (currencies[Field.OUTPUT] as any)?.address
  //   const inputAddress = (currencies[Field.INPUT] as any)?.address
  //   if (
  //     allTokens &&
  //     Object.keys(allTokens)?.length > 0 &&
  //     !(
  //       inputAddress?.toLowerCase() === '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3' &&
  //       outputAddress?.toLowerCase() === '0x42981d0bfbaf196529376ee702f2a9eb9092fcb5'
  //     ) &&
  //     !(
  //       inputAddress?.toLowerCase() === SFM_V1[ChainId.BSC_TESTNET]?.toLowerCase() &&
  //       outputAddress?.toLowerCase() === SFM_V2[ChainId.BSC_TESTNET]?.toLowerCase()
  //     ) &&
  //     ((outputAddress && !allTokens[outputAddress]) ||
  //       (inputAddress &&
  //         !allTokens[inputAddress] &&
  //         BLACKLIST_TOKENS_SAFEMOON_V1.indexOf(inputAddress.toUpperCase()) === -1)) &&
  //     (!params.chainId || isNaN(+params.chainId) || +params.chainId === chainId)
  //   ) {
  //     onClearCurrency()
  //     setSwapState({
  //       attemptingTxn: false,
  //       tradeToConfirm,
  //       showConfirm: true,
  //       swapErrorMessage: 'Token is not supported.',
  //       txHash: undefined
  //     })

  //     history.push('/swap')
  //   }
  // }, [currencies[Field.INPUT], currencies[Field.OUTPUT], allTokens, params.chainId, chainId])

  const showMigrate: boolean = migrateType !== MigrateType.NOT_APPLICABLE
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const showLegacyError: boolean =
    (currencies[Field.INPUT] instanceof WrappedTokenInfo &&
      currencies[Field.INPUT]?.address?.toUpperCase() ===
        consolidation.tokens.v1[chainId as ChainId]?.address?.toUpperCase() &&
      (currencies[Field.OUTPUT] as any)?.address?.toUpperCase() !==
        consolidation.tokens.v2[chainId as ChainId]?.address?.toUpperCase()) ||
    (currencies[Field.INPUT] instanceof WrappedTokenInfo &&
      (currencies[Field.OUTPUT] as any)?.address?.toUpperCase() ===
        consolidation.tokens.v1[chainId as ChainId]?.address?.toUpperCase() &&
      currencies[Field.INPUT]?.address?.toUpperCase() !==
        consolidation.tokens.v2[chainId as ChainId]?.address?.toUpperCase())
  const { address: recipientAddress } = useENSAddress(recipient)
  const isForceUse0xApi = (currencies?.[Field.INPUT] as any)?.tokenInfo?.isForceUse0xApi || (currencies?.[Field.OUTPUT] as any)?.tokenInfo?.isForceUse0xApi
  const trade = showWrap || showMigrate || showLegacyError || isForceUse0xApi ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade
            ? trade?.outputAmount
            : priceInfo?.buyAmount && currencies[Field.OUTPUT]
            ? new CurrencyAmount(currencies[Field.OUTPUT], priceInfo?.buyAmount)
            : undefined
      }

  const { onSwitchTokens, onCurrencySelection, onUserInput, onClearCurrency } = useSwapActionHandlers()

  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showMigrate
      ? parsedAmounts[independentField]
        ? independentField === Field.INPUT
          ? new BigNumber(parsedAmounts[independentField]?.toExact() ?? 0)?.dividedBy(1000).toString(10)
          : new BigNumber(parsedAmounts[independentField]?.toExact() ?? 0)?.times(1000).toString(10)
        : ''
      : showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? ''
  }

  const pairs = usePair(currencies?.[Field.INPUT] ?? undefined, currencies?.[Field.OUTPUT] ?? undefined)
  const pairState = isForceUse0xApi ?  PairState.NOT_EXISTS : pairs?.[0] || PairState.NOT_EXISTS

  const [isUseGasless] = useIsUseGasless()
  const isSupportedGasless = useSupportedGasless()
  const isSupport0xApi = use0xApiSupport()
  const useGasless = useMemo(() => {
    return isUseGasless && !!(isSupportedGasless && currencies[Field.INPUT] !== ETHER && pairState === PairState.NOT_EXISTS)
  }, [chainId, currencies[Field.INPUT], pairState, isUseGasless])

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  
  const noRoute = !route || isForceUse0xApi

  useEffect(() => {
    const getMaxSwapFee = async () => {
      try {
        const balance = +(currencyBalances[Field.INPUT]?.toExact() || 0)
        if (balance === 0) {
          return
        }
        const path = [WETH[chainId as ChainId].address, (currencies[Field.OUTPUT] as any)?.address]
        const amount = toWei({
          val: balance,
          decimals: 18
        })

        let ethFee = await ffsContract?.getFees(path, amount, account)
        ethFee = ethFee.totalBNBFee

        // console.log('ethFee', ethFee)

        const gasLimit = await ffsContract?.estimateGas.swapExactETHForTokensWithFeeAmount(
          {
            amountIn: toWei({
              val: 0.00000001,
              decimals: 18
            }),
            amountOut: '0',
            deadline: `${new Date().valueOf() + 1000000}`,
            path,
            to: account
          },
          ethFee,
          {
            value: ethFee
          }
        )

        const gasWithMargin = +calculateGasMargin(gasLimit as BigNumberEther).toString()
        const gasPriceInWei =
          chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET || chainId === ChainId.POLYGON
            ? +gasPrice
            : +BigNumberEther.from(gasPrice)
                .mul(2)
                .add(MAX_PRIORITY_FEE)
        console.log(
          'getMaxSwapFee ===>',
          +ethFee.toString() / 10 ** 18 + (+(gasWithMargin || 0) * gasPriceInWei) / 10 ** 18
        )
        setMaxSwapFee(`${+ethFee.toString() + +(gasWithMargin || 0) * gasPriceInWei}`)
      } catch (e) {
        console.log(e)
      }
    }

    if (
      currencies &&
      chainId &&
      currencies[Field.INPUT]?.symbol === 'ETH' &&
      !(currencies[Field.INPUT] as any)?.address &&
      (currencies[Field.OUTPUT] as any)?.address &&
      currencyBalances[Field.INPUT] &&
      ffsContract &&
      !noRoute
    ) {
      getMaxSwapFee()
    }
  }, [
    chainId,
    currencies[Field.INPUT]?.symbol,
    (currencies[Field.OUTPUT] as any)?.address,
    currencyBalances[Field.INPUT]?.toExact(),
    ffsContract,
    noRoute
  ])

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage, dexFee !== ethFee)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const [migrationApproval, migrationApprovalCallback] = useApproveCallbackFromMigrate(
    typedValue && parsedAmount
      ? independentField === Field.INPUT || !showMigrate
        ? parsedAmount
        : tryParseAmount(parsedAmount.multiply('1000').toSignificant(12), consolidation.tokens.v1[chainId as ChainId])
      : new TokenAmount(consolidation.tokens.v1[chainId as ChainId], '0')
  )

  const [migrationApprovalSubmitted, setMigrationApprovalSubmitted] = useState<boolean>(false)
  
  const [sellTokenApproval, sellTokenApprovalCallback] = useApproveCallbackFromOxApis(
    priceInfo?.issues?.allowance ? parsedAmounts[Field.INPUT] : undefined,
    priceInfo?.issues?.allowance?.spender
  )

  const [sellTokenApprovalSubmitted, setSellTokenApprovalSubmitted] = useState<boolean>(false)

  // const checkApproval = useMemo(() => {
  //   const isSellTokenPermit = !!((currencies[Field.INPUT] as Token)?.address && permitTokensDataTyped && permitTokensDataTyped[(currencies[Field.INPUT] as Token)?.address])

  //   const hasSufficientAllowance =
  //   account && sellTokenAllowance ? +(sellTokenAllowance?.toExact() || 0) === 0 : false;

  //   return isSellTokenPermit && !hasSufficientAllowance;
  // }, [(currencies[Field.INPUT] as Token)?.address, account, sellTokenAllowance?.toExact()])


  useEffect(() => {
    if (migrationApproval === ApprovalState.PENDING) {
      setMigrationApprovalSubmitted(true)
    }
  }, [migrationApproval, migrationApprovalSubmitted])

  useEffect(() => {
    if (sellTokenApproval === ApprovalState.PENDING) {
      setSellTokenApprovalSubmitted(true)
    }
  }, [sellTokenApproval, sellTokenApprovalSubmitted])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT], maxSwapFee || ethFee)
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError, networkFee } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient
  )

  const swapInputError = useMemo(() => {
    // console.log('networkFee', networkFee)
    if (
      (networkFee && networkFee?.maxNetworkFee + networkFee?.value > +(ETHBalance?.toFixed() || 0)) ||
      networkFee?.insufficient
    ) {
      return `Insufficient ${getNativeSymbol(chainId)} Balance`
    }
    return swapScreenInputError
  }, [swapScreenInputError, ETHBalance, networkFee, chainId])

  const isValid = !swapInputError

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const addTransaction = useTransactionAdder()

  const handleExecuteSwap0xApi = async () => {
    if (!library || !account || !chainId) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    const web3 = new Web3(library.provider as any)
    if (quoteInfo.permit2?.eip712) {
      let signature: Hex | undefined
      try {
        

        if (isMobile && (window as any)?.ethereum && library?.provider?.request) {
          signature = await library.provider.request({
            method: 'eth_signTypedData_v4',
            params: [account, JSON.stringify(quoteInfo.permit2?.eip712)]
          })
        } else {
          signature = await web3.eth.signTypedData(account, quoteInfo.permit2?.eip712) as Hex
        }

      } catch (error) {
        console.error('Error signing permit2 coupon:', error)
      }

      // (2) Append signature length and signature data to calldata

      if (signature && quoteInfo?.transaction?.data) {
        const signatureLengthInHex = numberToHex(size(signature), {
          signed: false,
          size: 32
        })

        const transactionData = quoteInfo.transaction.data
        const sigLengthHex = signatureLengthInHex
        const sig = signature

        quoteInfo.transaction.data = concat([transactionData, sigLengthHex, sig])

      } else {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: 'Failed to obtain signature or transaction data',
          txHash: undefined
        })
      }
    }
    try {
      if (chainId === ChainId.ARB_MAINNET) {
        const signer = getSigner(library, account)
        signer.sendTransaction({
          from: account,
          gasLimit: !!quoteInfo?.transaction.gas
            ? BigInt(quoteInfo?.transaction.gas)
            : undefined,
          to: quoteInfo?.transaction.to,
          data: quoteInfo.transaction.data, // submit
          value: +quoteInfo?.transaction.value
            ? BigInt(quoteInfo.transaction.value)
            : undefined, // value is used for native tokens
          chainId: chainId,
          gasPrice: quoteInfo?.transaction?.gasPrice,
          // gasLimit: 4000000
        }).then((transactionResponse) => {
          addTransaction({
            chainId,
            hash: transactionResponse?.hash,
            from: account
          } as any, {
            summary: `Swap ${parsedAmounts[Field.INPUT]?.toSignificant(6)} ${getTokenSymbol(
              parsedAmounts[Field.INPUT]?.currency,
              chainId
            )} for ${parsedAmounts[Field.OUTPUT]?.toSignificant(6)} ${getTokenSymbol(
              parsedAmounts[Field.OUTPUT]?.currency,
              chainId
            )}`
          })
          setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: transactionResponse?.hash })
        }).catch(error => {
          let message = (error as any).message
          if ((error as any)?.code === 'ACTION_REJECTED') {
            message = 'User rejected transaction'
          }
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: message,
            txHash: undefined
          })
          
        });
      } else {
        web3.eth.sendTransaction({
          from: account,
          gas: !!quoteInfo?.transaction.gas
            ? BigInt(quoteInfo?.transaction.gas)
            : undefined,
          to: quoteInfo?.transaction.to,
          data: quoteInfo.transaction.data, // submit
          value: +quoteInfo?.transaction.value
            ? BigInt(quoteInfo.transaction.value)
            : undefined, // value is used for native tokens
          chainId: chainId,
          gasPrice: quoteInfo?.transaction?.gasPrice,
          // gasLimit: 4000000
        }).on('transactionHash', hash => {
          addTransaction({
            chainId,
            hash,
            from: account
          } as any, {
            summary: `Swap ${parsedAmounts[Field.INPUT]?.toSignificant(6)} ${getTokenSymbol(
              parsedAmounts[Field.INPUT]?.currency,
              chainId
            )} for ${parsedAmounts[Field.OUTPUT]?.toSignificant(6)} ${getTokenSymbol(
              parsedAmounts[Field.OUTPUT]?.currency,
              chainId
            )}`
          })
          setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })
        }).catch(error => {
          setSwapState({
            attemptingTxn: false,
            tradeToConfirm,
            showConfirm,
            swapErrorMessage: (error as any).message,
            txHash: undefined
          })
          
        });
      }
      
    } catch(e) {
      console.log(e)
    }
     
  }

  const requestGaslessStatus = async (tradeHash: string) => {
    const res = await axios.get(`${MARKETDATA_URL}/api/swap/gasless/status`, {
      params: {
        chainId,
        tradeHash
      }
    });

    const data = res.data;
    return data?.transactions?.[0]?.hash;
  }

  const checkGaslessStatus = async (tradeHash: string) => {
    return new Promise(async (resolve, reject) => {
      const transactionHash = await requestGaslessStatus(tradeHash)
      if (transactionHash) {
        resolve(transactionHash)
      } else {
        setTimeout(async () => {
          const transactionHash = await requestGaslessStatus(tradeHash)
          if (transactionHash) {
            resolve(transactionHash)
          } else {
            setTimeout(async () => {
              const transactionHash = await requestGaslessStatus(tradeHash)
              if (transactionHash) {
                resolve(transactionHash)
              } else {
                reject('')
              }
            }, 1500)
          }
          
        }, 1500)
      }
    })
  }

  const handleSubmitGasless = async () => {
    let approvalDataToSubmit;
    let tradeDataToSubmit;
    let succeessfulTradeHash;

    // if approval exists, split signature for approval
    if (signApprovalSignature) {
      const approvalSplitSig = await splitSignature(signApprovalSignature as Hex);

      approvalDataToSubmit = {
        type: quoteInfo?.approval?.type,
        eip712: quoteInfo?.approval?.eip712,
        signature: {
          ...approvalSplitSig,
          v: Number(approvalSplitSig.v),
          signatureType: SignatureType.EIP712,
        },
      };
    }
    // split signature for trade
    if (signTradeSignature) {
      const tradeSplitSig = await splitSignature(signTradeSignature as Hex);
      
      tradeDataToSubmit = {
        type: quoteInfo?.trade?.type,
        eip712: quoteInfo?.trade?.eip712,
        signature: {
          ...tradeSplitSig,
          v: Number(tradeSplitSig.v),
          signatureType: SignatureType.EIP712,
        },
      };
    }
   

    try {
      // POST approval and trade data to submit trade
      
      const response = await axios.post(`${MARKETDATA_URL}/api/swap/gasless/submit`, {
        trade: tradeDataToSubmit,
        approval: approvalDataToSubmit,
        chainId,
      });

      const hash = await checkGaslessStatus(response?.data?.tradeHash)

      succeessfulTradeHash = hash
      addTransaction({
        chainId,
        hash: succeessfulTradeHash,
        from: account
      } as any, {
        summary: `Swap ${parsedAmounts[Field.INPUT]?.toSignificant(6)} ${getTokenSymbol(
          parsedAmounts[Field.INPUT]?.currency,
          chainId
        )} for ${parsedAmounts[Field.OUTPUT]?.toSignificant(6)} ${getTokenSymbol(
          parsedAmounts[Field.OUTPUT]?.currency,
          chainId
        )}`
      })
      setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: succeessfulTradeHash })
    } catch (error) {
      setSwapState({
        attemptingTxn: false,
        tradeToConfirm,
        showConfirm,
        swapErrorMessage: (error as any).message,
        txHash: undefined
      })
    }
  }

  const handleSwap = useCallback(() => {
    if (!trade && quoteInfo) {
      if (useGasless) {
        handleSubmitGasless()
      } else {
        handleExecuteSwap0xApi()
      }
      
      return
    }
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }

    const outputAddress = (trade?.outputAmount?.currency as any)?.address
    const inputAddress = (trade?.outputAmount?.currency as any)?.address

    if (
      (outputAddress && BLACKLIST_TOKENS_SAFEMOON_V1.indexOf(outputAddress.toUpperCase()) !== -1) ||
      (outputAddress &&
        inputAddress &&
        BLACKLIST_TOKENS_SAFEMOON_V1.indexOf(inputAddress.toUpperCase()) !== -1 &&
        TOKENS_SAFEMOON_V2.indexOf(outputAddress.toUpperCase()) === -1)
    ) {
      setSwapState({
        attemptingTxn: false,
        tradeToConfirm,
        showConfirm,
        swapErrorMessage: 'SafeMoon V1 is no longer supported.',
        txHash: undefined
      })
      return
    }

    // if (
    //   !(
    //     inputAddress === '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3' &&
    //     outputAddress === '0x42981d0bfbaf196529376ee702f2a9eb9092fcb5'
    //   ) &&
    //   !(
    //     inputAddress?.toLowerCase() === SFM_V1[ChainId.BSC_TESTNET]?.toLowerCase() &&
    //     outputAddress?.toLowerCase() === SFM_V2[ChainId.BSC_TESTNET]?.toLowerCase()
    //   ) &&
    //   ((outputAddress && !allTokens[outputAddress]) ||
    //     (inputAddress &&
    //       !allTokens[inputAddress] &&
    //       BLACKLIST_TOKENS_SAFEMOON_V1.indexOf(inputAddress.toUpperCase()) === -1))
    // ) {
    //   setSwapState({
    //     attemptingTxn: false,
    //     tradeToConfirm,
    //     showConfirm,
    //     swapErrorMessage: 'Token is not supported.',
    //     txHash: undefined
    //   })
    //   return
    // }

    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })

    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action:
            recipient === null
              ? 'Swap w/o Send'
              : (recipientAddress ?? recipient) === account
              ? 'Swap w/o Send + recipient'
              : 'Swap w/ Send',
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade)
          ].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [
    tradeToConfirm,
    account,
    priceImpactWithoutFee,
    recipient,
    recipientAddress,
    showConfirm,
    swapCallback,
    trade,
    allTokens,
    quoteInfo,
    useGasless,
    signApprovalSignature,
    signTradeSignature
  ])


  // errors
  const [showInverted, setShowInverted] = useState<boolean>(true)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const showMigrateApproveFlow =
    migrationApproval === ApprovalState.NOT_APPROVED ||
    migrationApproval === ApprovalState.PENDING ||
    (migrationApprovalSubmitted && migrationApproval === ApprovalState.APPROVED)

  const showSellTokenApproveFlow =
    sellTokenApproval === ApprovalState.NOT_APPROVED ||
    sellTokenApproval === ApprovalState.PENDING ||
    (sellTokenApprovalSubmitted && sellTokenApproval === ApprovalState.APPROVED)
  // const [dismissedToken0] = useTokenWarningDismissal(chainId, currencies[Field.INPUT])
  // const [dismissedToken1] = useTokenWarningDismissal(chainId, currencies[Field.OUTPUT])
  // console.log(dismissedToken0, dismissedToken1)
  const showWarning = false // (!dismissedToken0 && !!currencies[Field.INPUT]) || (!dismissedToken1 && !!currencies[Field.OUTPUT])

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)

  const handleChangeSlippage = (tokenA: any, tokenB: any) => {
    // console.log(tokenA, tokenB)
    if (
      (tokenA?.tokenInfo?.sellSlippage || tokenB?.tokenInfo?.buySlippage) &&
      tokenA &&
      tokenB &&
      tokenA?.address !== tokenB?.address
    ) {
      const moreSlippage = tokenA?.tokenInfo?.sellSlippage && tokenB?.tokenInfo?.buySlippage ? 1 : 0

      const allowedSlippage =
        (+(tokenA?.tokenInfo?.sellSlippage || 0) + +(tokenB?.tokenInfo?.buySlippage || 0) + moreSlippage) * 100

      setAllowedSlippage(allowedSlippage)
      // console.log('allowedSlippage ====>', allowedSlippage)
      if (!hideSlippageWarning && allowedSlippage > 50) {
        setShowSlippageWarning(true)
      }
    } else {
      setAllowedSlippage(50)
    }
  }

  useEffect(() => {
    if (chainId) {
      setAllowSetSlippage(true)
      setInitial(true)
      handleTypeInput('')
      handleTypeOutput('')
      setPriceInfo(null)
    }
  }, [chainId])

  useEffect(() => {
    try {
      if (
        allowSetSlippage &&
        ((params.inputCurrency && params.outputCurrency) || initial) &&
        currencies &&
        currencies[Field.INPUT] &&
        currencies[Field.OUTPUT]
      ) {
        const tokenA: any = currencies[Field.INPUT]
        const tokenB: any = currencies[Field.OUTPUT]
        setInitial(false)
        if (
          (tokenA?.tokenInfo?.sellSlippage || tokenB?.tokenInfo?.buySlippage) &&
          tokenA &&
          tokenB &&
          tokenA?.address !== tokenB?.address
        ) {
          const moreSlippage = tokenA?.tokenInfo?.sellSlippage && tokenB?.tokenInfo?.buySlippage ? 1 : 0
          const allowedSlippage =
            (+(tokenA?.tokenInfo?.sellSlippage || 0) + +(tokenB?.tokenInfo?.buySlippage || 0) + moreSlippage) * 100
          setAllowedSlippage(allowedSlippage)
        } else {
          setAllowedSlippage(50)
        }

        setAllowSetSlippage(false)
      }
    } catch (e) {
      console.log('e')
    }
  }, [params?.inputCurrency, params.outputCurrency, currencies, allowSetSlippage, initial])

  const handleChangeNetwork = async (selectedChainId: number) => {
    console.log('connector', connector)
    if (connector instanceof NetworkConnector) {
      activeNetwork(selectedChainId, activate)
      return
    }
    if (connector instanceof WalletConnectConnector) {
      connector.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: HEX_CHAINS[selectedChainId] }]
      })
      return
    }
    if (window.ethereum && connector === injected) {
      if (chainId !== selectedChainId) {
        const web3 = new Web3(window.ethereum as any)
        await handleSwitchChainInjected(selectedChainId)
        const accounts = await web3.eth.getAccounts()
        if (accounts[0]) {
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      }
    } else if (connector === binanceinjected && window.BinanceChain) {
      if (+selectedChainId === ChainId.BSC_MAINNET || +selectedChainId === ChainId.MAINNET) {
        await window.BinanceChain?.switchNetwork(BINANCE_NETWORK[selectedChainId])
        activate(binanceinjected, undefined, true).catch(error => {
          console.error('Failed to activate after accounts changed', error)
        })
      }
    }
  }

  useEffect(() => {
    const getPriceUsd = async () => {
      if (!chainId) {
        return
      }
      try {
        const addresses: any = []

        const WBNBAddress = '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c'
        const WETHAddress = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'

        let currencyInput: any
        let currencyOutput: any

        if (currencies && currencies[Field.INPUT]) {
          currencyInput = currencies[Field.INPUT]
          if (currencyInput?.address) {
            addresses.push(currencyInput.address)
          }
        }
        if (currencies && currencies[Field.OUTPUT]) {
          currencyOutput = currencies[Field.OUTPUT]
          if (currencyOutput?.address) {
            addresses.push(currencyOutput.address)
          }
        }


        addresses.push(WETH[chainId]?.address)
        const wrappedAddress = WETH[chainId]?.address

        if (currencyInput?.symbol === 'ETH' && (currencyInput?.address || currencyOutput?.address) && chainId === 56) {
          addresses.push(WETHAddress)
        }

        if (addresses?.length > 0) {
          const result = await axios.post(`https://marketdata.safemoon.com/api/cryptocurrency/tokens-price-info`, {
            tokenAddresses: addresses,
            chainId
          })

          const priceUsd = result.data.data

          const slugs: any = []

          if (
            currencyInput?.symbol !== 'ETH' &&
            currencyInput?.tokenInfo?.slug &&
            !priceUsd[currencyInput?.address?.toLowerCase()]
          ) {
            slugs.push(currencyInput?.tokenInfo?.slug)
          }

          if (
            currencyOutput?.symbol !== 'ETH' &&
            currencyOutput?.tokenInfo?.slug &&
            !priceUsd[currencyOutput?.address?.toLowerCase()]
          ) {
            slugs.push(currencyOutput?.tokenInfo?.slug)
          }

          if (slugs.length > 0) {
            const data = await axios.get(`${MARKETDATA_URL}/api/cryptocurrency/v7/quotes/latest`, {
              params: {
                slugs: slugs.join(',')
              }
            })

            Object.values(data.data).forEach((item: any) => {
              let address = item?.platform?.token_address
              if (item.symbol === 'BUSD' || item.symbol === 'USDC') {
                if (currencyInput?.symbol === 'BUSD' || currencyInput?.symbol === 'USDC') {
                  address = currencyInput?.address
                } else if (currencyOutput?.symbol === 'BUSD' || currencyOutput?.symbol === 'USDC') {
                  address = currencyOutput?.address
                }
              }

              if (!priceUsd[address?.toLowerCase()]) {
                priceUsd[address?.toLowerCase()] = item?.quote?.USD?.price
              }
            })
          }

          setPriceUsd(priceUsd)

          setNativePriceUsd(+priceUsd[wrappedAddress?.toLowerCase()])
        }
      } catch (e) {
        console.log(e)
      }
    }

    // console.log('priceUsd', priceUsd)

    getPriceUsd()

    const id = setInterval(() => {
      // console.log('aaaaaaa==>>>')
      getPriceUsd()
    }, 60000)
    return () => clearInterval(id)
  }, [(currencies[Field.INPUT] as any)?.symbol, (currencies[Field.OUTPUT] as any)?.symbol, chainId])

  const handleInputSelect = useCallback(
    inputCurrency => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      /*
      const showSwapWarning = shouldShowSwapWarning(inputCurrency)
      if (showSwapWarning) {
        setSwapWarningCurrency(inputCurrency)
      } else {
        setSwapWarningCurrency(null)
      } */

      handleChangeSlippage(inputCurrency, currencies[Field.OUTPUT])
    },
    [onCurrencySelection, currencies]
  )

  const handleOutputSelect = useCallback(
    outputCurrency => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      /*
      const showSwapWarning = shouldShowSwapWarning(outputCurrency)
      if (showSwapWarning) {
        setSwapWarningCurrency(outputCurrency)
      } else {
        setSwapWarningCurrency(null)
      } */

      handleChangeSlippage(currencies[Field.INPUT], outputCurrency)
    },
    [onCurrencySelection, currencies]
  )

  useEffect(() => {
    if (currencyA) {
      handleInputSelect(currencyA)
    }
    if (currencyB) {
      handleOutputSelect(currencyB)
    }
  }, [currencyA, currencyB, handleOutputSelect, handleInputSelect])

  const handleConvertV1ToV2 = useCallback(async () => {
    const hex = appEnv === 'development' ? ChainIdHex.BSC_TESTNET : ChainIdHex.BSC_MAINNET
    const chain = appEnv === 'development' ? ChainId.BSC_TESTNET : ChainId.BSC_MAINNET
    if (!(chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET)) {
      if (window.ethereum && connector === injected) {
        const web3 = new Web3(window.ethereum as any)
        await handleSwitchChainInjected(chain)
        const accounts = await web3.eth.getAccounts()
        if (accounts[0]) {
          activate(injected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      } else if (connector === binanceinjected && window.BinanceChain) {
        await window.BinanceChain?.switchNetwork(hex)
        activate(binanceinjected, undefined, true).catch(error => {
          console.error('Failed to activate after accounts changed', error)
        })
      } else if (connector instanceof WalletConnectConnector) {
        connector.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hex }]
        })
      } else {
        return
      }
    }
    setMigrationApprovalSubmitted(false)
    onCurrencySelection(Field.INPUT, consolidation.tokens.v1[chain])
    onCurrencySelection(Field.OUTPUT, consolidation.tokens.v2[chain])
    history.push(
      params.viewMode === 'mobile'
        ? `/swap?inputCurrency=${consolidation.addresses.v1[chain]}&outputCurrency=${consolidation.addresses.v2[chain]}&viewMode=mobile`
        : `/swap?inputCurrency=${consolidation.addresses.v1[chain]}&outputCurrency=${consolidation.addresses.v2[chain]}`
    )
  }, [chainId, history, onCurrencySelection, params.viewMode])

  const isTradeRouter = useMemo(() => {
    return true
  }, [chainId])

  // console.log(currencies, parsedAmounts[Field.INPUT]?.raw.toString())

  useEffect(() => {
    const get0xPrice = async () => {
      try {
        setError('')
        setLoading(true)
        let url: string = '';
        if (!useGasless) {
          url = GET_PRICE_URL
        } else {
          url = `${MARKETDATA_URL}/api/swap/gasless/price`
        }

        const result = await axios.get(url, {
          params: {
            chainId,
            taker: account,
            sellToken:
              currencies[Field.INPUT] === ETHER
                ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                : (currencies[Field.INPUT] as WrappedTokenInfo)?.address,
            buyToken:
              currencies[Field.OUTPUT] === ETHER
                ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
                : (currencies[Field.OUTPUT] as WrappedTokenInfo)?.address,
            sellAmount: parsedAmounts[Field.INPUT]?.raw?.toString(),
            allowedSlippage
          }
        })
        

        const data = result?.data

        if (data.liquidityAvailable) {
          if (data?.issues?.balance) {
            if (+data?.issues?.balance?.actual < +data?.issues?.balance?.expected) {
              setError(`Insufficient ${getTokenSymbol(currencies[Field.INPUT], chainId)} Balance`)
            }
          } else {
            setError('')
          }
          setPriceInfo(data)
        } else {
          setPriceInfo(null)
        }

        setLoading(false)
      } catch (e: any) {
        const data = e?.response?.data
        if (data?.message) {
          let errorMessage = data?.message
          console.log('alo', data, currencies[Field.INPUT]?.decimals)
          if (data?.data?.name === "SELL_AMOUNT_TOO_SMALL" && currencies[Field.INPUT]?.decimals) {
            errorMessage += `\nMin=${formatStringToNumber(+data?.data?.data?.minSellAmount / 10 ** (+currencies[Field.INPUT]?.decimals), 8)}`
          }

          setError(errorMessage)
        }
        setPriceInfo(null)
        setLoading(false)
      }
    }
    if (userHasSpecifiedInputOutput && noRoute && chainId && account && parsedAmounts[Field.INPUT]?.raw?.toString() && isSupport0xApi) {
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        get0xPrice()
      }, 500)
    }
  }, [
    userHasSpecifiedInputOutput,
    noRoute,
    chainId,
    account,
    parsedAmounts[Field.INPUT]?.raw?.toString(),
    currencies[Field.INPUT]?.symbol,
    currencies[Field.OUTPUT]?.symbol,
    useGasless,
    isSupport0xApi
  ])

  const handleGetQuote = async () => {
    try {
      setSignApprovalSignature('')
      setSignTradeSignature('')
      setLoading(true)

      let url: string = '';
      if (!useGasless) {
        url = GET_QUOTE_URL
      } else {
        url = `${MARKETDATA_URL}/api/swap/gasless/quote`
      }

      const result = await axios.get(url, {
        params: {
          chainId,
          taker: account,
          sellToken:
            currencies[Field.INPUT] === ETHER
              ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
              : (currencies[Field.INPUT] as WrappedTokenInfo)?.address,
          buyToken:
            currencies[Field.OUTPUT] === ETHER
              ? '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
              : (currencies[Field.OUTPUT] as WrappedTokenInfo)?.address,
          sellAmount: parsedAmounts[Field.INPUT]?.raw?.toString(),
          allowedSlippage
        }
      })

      setQuoteInfo(result.data)

      setLoading(false)
      setSwapState({
        tradeToConfirm: trade,
        attemptingTxn: false,
        swapErrorMessage: undefined,
        showConfirm: true,
        txHash: undefined
      })
    } catch (e: any) {
      setLoading(false)
      const data = e?.response?.data
      if (data?.message) {
        let errorMessage = data?.message
        console.log('alo', data, currencies[Field.INPUT]?.decimals)
        if (data?.data?.name === "SELL_AMOUNT_TOO_SMALL" && currencies[Field.INPUT]?.decimals) {
          errorMessage += `\nMin=${formatStringToNumber(+data?.data?.data?.minSellAmount / 10 ** (+currencies[Field.INPUT]?.decimals), 8)}`
        }

        setError(errorMessage)
      }
    }
  }

  const signApproval = useCallback(async () => {
    if (!account || !library) {
      return
    }
    const web3 = new Web3(library.provider as any)
    try {
      const approvalEip712 = quoteInfo.approval?.eip712
      const data = {
        domain: approvalEip712.domain,
        message: approvalEip712.message,
        primaryType: approvalEip712.primaryType,
        types: approvalEip712.types,
      }
      let signature

      if (isMobile && (window as any)?.ethereum && library?.provider?.request) {
        signature = await library.provider.request({
          method: 'eth_signTypedData_v4',
          params: [account, JSON.stringify(data)]
        })
      } else {
        signature = await web3.eth.signTypedData(account, data) as Hex
      }
      setSignApprovalSignature(signature)
    } catch (error) {
      console.error('Error signing permit2 coupon:', error)
    }
      
  }, [quoteInfo, account])

  const signTrade = useCallback(async () => {
    if (!account || !library) {
      return
    }
    const web3 = new Web3(library.provider as any)
    try {
      const tradeEip712 = quoteInfo.trade?.eip712
      const data = {
        types: tradeEip712.types,
        domain: tradeEip712.domain,
        message: tradeEip712.message,
        primaryType: tradeEip712.primaryType,
      }

      console.log('data', data)
      let signature: Hex;
      if (isMobile && (window as any)?.ethereum && library?.provider?.request) {
        signature = await library.provider.request({
          method: 'eth_signTypedData_v4',
          params: [account, JSON.stringify(data)]
        })
      } else {
        signature = await web3.eth.signTypedData(account, data) as Hex
      }

      console.log('signature', signature)
      
      setSignTradeSignature(signature)
    } catch (error: any) {
      console.error('Error signing permit2 coupon:', error, error.data)
    }
  }, [quoteInfo, account])

  return (
    <>
      <TokenWarningCards currencies={currencies} open={showWarning} onDismiss={() => {}} />
      <SlippageWarning
        onDismiss={() => {
          setSwapWarningCurrency(null)
          handleChangeSlippage(currencies[Field.OUTPUT], currencies[Field.INPUT])
        }}
        open={swapWarningCurrency !== null}
        token={swapWarningCurrency}
      />

      <AppBody disabled={showWarning}>
        <RowBetween className="!mb-[24px]">
          <QuestionHelper text={t('swapDescription')} className="!ml-[-4px]" />
          <p className="text-[24px] font-bold text-white">Swap</p>
          <SettingsWrapper onClick={toggle}>
            <SVG src={'/images/settings.svg'} width={24} height={24} />
          </SettingsWrapper>
          <SettingsModal open={open} onDismiss={handleClickOutside} pairState={pairState}/>
        </RowBetween>

        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            ethFee={ethFee}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
            networkFee={networkFee}
            nativePriceUsd={nativePriceUsd}
            priceUsd={priceUsd}
            priceInfo={quoteInfo}
            parsedAmounts={parsedAmounts}
            useGasless={useGasless}
            signApproval={signApproval}
            signApprovalSignature={signApprovalSignature}
            signTrade={signTrade}
            signTradeSignature={signTradeSignature}
            showSellTokenApproveFlow={showSellTokenApproveFlow}
            sellTokenApprovalSubmitted={sellTokenApprovalSubmitted}
            sellTokenApproval={sellTokenApproval}
            sellTokenApprovalCallback={sellTokenApprovalCallback}
          />

          <AutoColumn gap={'lg'}>
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !(showWrap || showMigrate)
                  ? t('fromestimated')
                  : t('fromCapitalized')
              }
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={() => {
                maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact())
              }}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
              priceUsd={priceUsd}
              showBalance={params.showBalance}
            />

            <AutoColumn justify="space-between">
              <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                <ArrowWrapper
                  clickable
                  onClick={() => {
                    setApprovalSubmitted(false) // reset 2 step UI for approvals
                    setMigrationApprovalSubmitted(false)
                    setSellTokenApprovalSubmitted(false)
                    onSwitchTokens()
                    handleChangeSlippage(currencies[Field.OUTPUT], currencies[Field.INPUT])
                    if (pairState === PairState.NOT_EXISTS) {
                      handleTypeInput(formattedAmounts[Field.OUTPUT])
                      handleTypeOutput('')
                    }
                  }}
                >
                  <SVG src={'/images/swap.svg'} width={24} height={24} />
                </ArrowWrapper>
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && !(showWrap || showMigrate) ? t('toestimated') : t('toCapitalized')
              }
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
              priceUsd={priceUsd}
              showBalance={params.showBalance}
              disabledInput={pairState === PairState.NOT_EXISTS}
            />
          </AutoColumn>
          <div className="mt-[40px]">
            <BottomGrouping>
              {!account ? (
                <ButtonPrimary onClick={toggleWalletModal}>{t('connectWallet')}</ButtonPrimary>
              ) : showWrap ? (
                <ButtonPrimary disabled={Boolean(wrapInputError)} onClick={onWrap}>
                  {wrapInputError ??
                    (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                </ButtonPrimary>
              ) : showMigrate ? (
                showMigrateApproveFlow ? (
                  <div>
                    <div className="mb-[10px]">
                      <ButtonPrimary
                        onClick={migrationApprovalCallback}
                        disabled={migrationApproval !== ApprovalState.NOT_APPROVED || migrationApprovalSubmitted}
                        width="100%"
                        altDisbaledStyle={migrationApproval === ApprovalState.PENDING} // show solid button while waiting
                      >
                        {migrationApproval === ApprovalState.PENDING ? (
                          <Dots>Approving</Dots>
                        ) : migrationApprovalSubmitted && migrationApproval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          'Approve ' + getTokenSymbol(currencies[Field.INPUT], chainId)
                        )}
                      </ButtonPrimary>
                    </div>
                    <ButtonError
                      onClick={() => {
                        onMigrate()
                      }}
                      width="100%"
                      id="migrate-button"
                      disabled={migrationApproval !== ApprovalState.APPROVED || Boolean(migrateInputError)}
                    >
                      <Text fontSize={16} fontWeight={500}>
                        {migrateInputError ?? (migrateType === MigrateType.MIGRATE ? 'Migrate' : null)}
                      </Text>
                    </ButtonError>
                  </div>
                ) : (
                  <ButtonPrimary
                    disabled={Boolean(migrateInputError)}
                    onClick={() => {
                      onMigrate()
                    }}
                  >
                    {migrateInputError ?? (migrateType === MigrateType.MIGRATE ? 'Migrate' : null)}
                  </ButtonPrimary>
                )
              ) : showLegacyError ? (
                <GreyCard style={{ textAlign: 'center' }}>
                  <TYPE.main mb="4px">Not Allowed Swapping v1</TYPE.main>
                </GreyCard>
              ) : noRoute && userHasSpecifiedInputOutput ? (
                (priceInfo || loading || (useGasless && error)) ? (
                  <>
                    {useGasless
                      ? <>
                        <div className='flex-1'>
                        <ButtonError onClick={handleGetQuote} id="swap-button" disabled={!!error || loading || (sellTokenApproval !== ApprovalState.APPROVED && showSellTokenApproveFlow && !useGasless) || !priceInfo}>
                          <Text fontSize={16} fontWeight={'bold'} className='whitespace-pre-wrap'>
                            {error || 'Review trade'}
                          </Text>
                          {loading && (
                            <CustomLightSpinner src={Circle} alt="loader" size={'20px'} style={{ marginLeft: '6px' }} color='#ffffff'/>
                          )}
                        </ButtonError>
                      </div>
                      </>
                      : <div className='flex items-center'>
                      {showSellTokenApproveFlow
                        && <div className="flex-1 mr-[10px]">
                        <ButtonPrimary
                          onClick={sellTokenApprovalCallback}
                          disabled={sellTokenApproval !== ApprovalState.NOT_APPROVED || sellTokenApprovalSubmitted}
                          width="100%"
                          altDisbaledStyle={sellTokenApproval === ApprovalState.PENDING} // show solid button while waiting
                        >
                          {sellTokenApproval === ApprovalState.PENDING ? (
                            <Dots>Approving</Dots>
                          ) : sellTokenApprovalSubmitted && sellTokenApproval === ApprovalState.APPROVED ? (
                            'Approved'
                          ) : (
                            'Approve ' + getTokenSymbol(currencies[Field.INPUT], chainId)
                          )}
                        </ButtonPrimary>
                      </div>
                      }
                      <div className='flex-1'>
                        <ButtonError onClick={handleGetQuote} id="swap-button" disabled={!!error || loading || (sellTokenApproval !== ApprovalState.APPROVED && showSellTokenApproveFlow) || !priceInfo}>
                          <Text fontSize={16} fontWeight={'bold'}>
                            {error || 'Swap'}
                          </Text>
                          {loading && (
                            <CustomLightSpinner src={Circle} alt="loader" size={'20px'} style={{ marginLeft: '6px' }} color='#ffffff'/>
                          )}
                        </ButtonError>
                      </div>
                      
                    </div>
                    }
                  </>
                  
                ) : (
                  <GreyCard style={{ textAlign: 'center' }}>
                    <TYPE.main mb="4px">{t('insufficientLiquidityForThisTrade')}</TYPE.main>
                  </GreyCard>
                )
              ) : showApproveFlow ? (
                <RowBetween>
                  <ButtonPrimary
                    onClick={approveCallback}
                    disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                    width="48%"
                    altDisbaledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  >
                    {approval === ApprovalState.PENDING ? (
                      <Dots>Approving</Dots>
                    ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                      'Approved'
                    ) : (
                      'Approve ' + getTokenSymbol(currencies[Field.INPUT], chainId)
                    )}
                  </ButtonPrimary>
                  <ButtonError
                    onClick={() => {
                      if (isExpertMode) {
                        handleSwap()
                      } else {
                        setSwapState({
                          tradeToConfirm: trade,
                          attemptingTxn: false,
                          swapErrorMessage: undefined,
                          showConfirm: true,
                          txHash: undefined
                        })
                      }
                    }}
                    width="48%"
                    id="swap-button"
                    disabled={
                      !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                    }
                    error={isValid && priceImpactSeverity > 2}
                  >
                    <Text fontWeight={'bold'}>
                      {priceImpactSeverity > 3 && !isExpertMode
                        ? `Price Impact High`
                        : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                    </Text>
                  </ButtonError>
                </RowBetween>
              ) : (
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap()
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined
                      })
                    }
                  }}
                  id="swap-button"
                  disabled={
                    !isValid ||
                    (priceImpactSeverity > 3 && !isExpertMode) ||
                    !!swapCallbackError ||
                    (!ethFee && isTradeRouter)
                  }
                  error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
                >
                  <Text fontSize={16} fontWeight={'bold'}>
                    {swapInputError
                      ? swapInputError
                      : priceImpactSeverity > 3 && !isExpertMode
                      ? `Price Impact Too High`
                      : `Swap${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                  </Text>
                </ButtonError>
              )}
            </BottomGrouping>
          </div>
          <AdvancedSwapDetailsDropdown
            trade={trade}
            ethFee={ethFee}
            currencies={currencies}
            showInverted={showInverted}
            setShowInverted={setShowInverted}
            networkFee={networkFee}
            nativePriceUsd={nativePriceUsd}
            priceUsd={priceUsd}
            priceInfo={priceInfo}
            useGasless={useGasless}
          />
        </Wrapper>
      </AppBody>

      <div className="mt-[15px] z-[2] w-full max-w-[451px] px-[20px]">
        {/* <a className={`btn btnCosolidate ${disabledConsolidate ? 'disabed' : ''}`} onClick={handleConvertV1ToV2}>
            <span>Consolidate to V2 SafeMoon!!</span>
          </a>
          <a
            className="mt-[15px] block text-center text-[16px] leading-[28px] link-color cursor-pointer link"
            onClick={() => {
              setShowConsolidateV2Intro(true)
            }}
          >
            Learn more about consolidation
          </a> */}
        <DropdowAction
          showConsolidateIntro={() => {
            setShowConsolidateV2Intro(true)
          }}
          handleConvertV1ToV2={handleConvertV1ToV2}
        />
      </div>

      {/* <a className='link mt-[16px]' target='_blank' href='https://psfm.safemoon.com/'>
          Still hold pSafeMoon? Migrate here
        </a> */}

      <ConsolidateV2Intro
        show={showConsolidateV2Intro}
        handleClose={() => {
          setShowConsolidateV2Intro(false)
        }}
        handleConvertV1ToV2={handleConvertV1ToV2}
      />

      <SlippageWarningPopup
        show={showSlippageWarning}
        handleClose={() => {
          setShowSlippageWarning(false)
        }}
        handleHideSlippageWarning={handleHideSlippageWarning}
        slippage={allowedSlippage}
      />
    </>
  )
}
