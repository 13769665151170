import { ChainId, Currency, ETHER, Token } from '@safemoon/sdk'
import React, { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { useAllTokens } from '../../hooks/Tokens'
import useInterval from '../../hooks/useInterval'
import { useAllTokenBalances, useTokenBalance } from '../../state/wallet/hooks'
import { isAddress } from '../../utils'
import Column from '../Column'
import Modal from '../Modal'
import Tooltip from '../Tooltip'
import { filterTokens } from './filtering'
import { useTokenComparator } from './sorting'
import { InputContainer, PaddedColumn, SearchInput } from './styleds'
import CurrencyList from './CurrencyList'
import { SelectToken } from '../NavigationTabs'
import SVG from 'react-inlinesvg'
import SearchIcon from '../../assets/icons/search-normal.svg'
import axios from 'axios'
import { SEARCH_TOKENS_URL, ZERO_ADDRESS } from '../../constants'
import { IInactiveToken, IType } from '../../interfaces/Tokens'
import CustomCurrencies from './CustomCurrencies'
import FixedCurrencies from './FixedCurrencies'
import { useFixedTokenList } from '../../state/lists/hooks'
import WarningImport from './WarningImport'

export const SearchBarIcon = styled(SVG).attrs(props => ({
  ...props,
  src: SearchIcon,
  width: 24,
  height: 24
}))`
  color: ${({ theme }) => theme.text1};
  position: absolute;
  top: 16px;
  left: 16px;
`
interface CurrencySearchModalProps {
  isOpen?: boolean
  onDismiss?: () => void
  hiddenCurrency?: Currency
  showSendWithSwap?: boolean
  onCurrencySelect?: (currency: Currency) => void
  otherSelectedCurrency?: Currency
}

export default function CurrencySearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  hiddenCurrency,
  showSendWithSwap,
  otherSelectedCurrency
}: CurrencySearchModalProps) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const [tokenImport, setTokenImport] = useState<IInactiveToken | null>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const [invertSearchOrder] = useState<boolean>(false)
  const allTokens = useAllTokens()
  const fixedTokenList = useFixedTokenList()
  const [inactiveTokens, setInactiveTokens] = useState<IInactiveToken[]>([])

  const [showCustomCurrencies, setShowCustomCurrencies] = useState<boolean>(false)

  const searchToken = null
  const searchTokenBalance = useTokenBalance(account, searchToken)
  const allTokenBalances_ = useAllTokenBalances()

  const allTokenBalances = searchToken
    ? {
        [searchToken.address]: searchTokenBalance
      }
    : allTokenBalances_ ?? {}

  const allAddresses = useMemo(() => {
    return Object.keys(allTokens)
  }, [allTokens])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const fixedTokens = useMemo(() => [ETHER, ...fixedTokenList], [fixedTokenList])

  const { filteredTokens }: any = useMemo(() => {
    if (searchToken) return [searchToken]
    const tokens: Token[] = filterTokens(Object.values(allTokens), searchQuery)
    return {
      filteredTokens: tokens
    }
  }, [searchToken, allTokens, searchQuery])

  const filteredSortedTokens: Token[] = useMemo(() => {
    if (searchToken) return [searchToken]
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter(s => s.length > 0)

    if (symbolMatch.length > 1) return sorted

    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter(token => token.symbol.toLowerCase() === symbolMatch[0]),
      ...sorted.filter(token => token.symbol.toLowerCase() !== symbolMatch[0])
    ]
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])

  const currencies: (Currency | IType)[] = useMemo(() => {
    let expandTokens: (IInactiveToken | IType)[] = inactiveTokens
    if (inactiveTokens && inactiveTokens?.length > 0) {
      expandTokens = [{ type: 'label', address: ZERO_ADDRESS }, ...inactiveTokens]
    }
    if (
      ((chainId === ChainId.MAINNET || chainId === ChainId.SEPOLIA_TESTNET) &&
        ('eth'.includes(searchQuery.toLowerCase()) || 'ethereum'.includes(searchQuery.toLowerCase()))) ||
      ((chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET) &&
        ('bnb'.includes(searchQuery.toLowerCase()) || 'bnb'.includes(searchQuery.toLowerCase()))) ||
      (chainId === ChainId.MUMBAI_TESTNET && 'matic'.includes(searchQuery.toLowerCase())) ||
      (chainId === ChainId.POLYGON && 'pol'.includes(searchQuery.toLowerCase())) ||
      ((chainId === ChainId.ARB_TESTNET ||
        chainId === ChainId.ARB_MAINNET ||
        chainId === ChainId.BASE_SEPOLIA ||
        chainId === ChainId.BASE_MAINNET ||
        chainId === ChainId.LINEA ||
        chainId === ChainId.OPTIMISM ||
        chainId === ChainId.BLAST) &&
        'eth'.includes(searchQuery.toLowerCase())) ||
      ((chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C) &&
        'avax'.includes(searchQuery.toLowerCase())) ||
      (chainId === ChainId.FANTOM && 'ftm'.includes(searchQuery.toLowerCase()))
    ) {
      return [ETHER, ...filteredSortedTokens, ...expandTokens]
    }

    return [...filteredSortedTokens, ...expandTokens]
  }, [searchQuery, filteredSortedTokens, chainId, inactiveTokens])

  useEffect(() => {
    let timeout
    const getInactiveTokens = async textSearch => {
      try {
        const result: { data: IInactiveToken[] } = await axios.get(SEARCH_TOKENS_URL, {
          params: {
            textSearch,
            chainId,
            excludeAddress: allAddresses
          }
        })
        setInactiveTokens(result.data)
      } catch (e) {}
    }
    if (searchQuery && searchQuery?.length >= 3) {
      timeout = setTimeout(() => {
        getInactiveTokens(searchQuery)
      }, 500)
    } else {
      setInactiveTokens([])
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [searchQuery, chainId, allAddresses])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
    },
    [onDismiss, onCurrencySelect]
  )

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen, setSearchQuery])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(event => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    setTooltipOpen(false)
  }, [])

  const closeTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen])

  useInterval(
    () => {
      setTooltipOpen(false)
    },
    tooltipOpen ? 4000 : null,
    false
  )

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && filteredSortedTokens.length > 0) {
        if (
          filteredSortedTokens[0].symbol.toLowerCase() === searchQuery.trim().toLowerCase() ||
          filteredSortedTokens.length === 1
        ) {
          handleCurrencySelect(filteredSortedTokens[0])
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, searchQuery]
  )

  const alertHeight = useMemo(() => {
    return isOpen ? document.getElementById('messageAlerts')?.clientHeight || 0 : 0
  }, [isOpen])

  const onDismissWarningImport = () => {
    setTokenImport(null)
    setShowCustomCurrencies(false)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onDismiss={onDismiss}
        maxHeight={90}
        initialFocusRef={isMobile ? undefined : inputRef}
        minHeight={70}
        forceMaxHeight={'80vh'}
        // alignSelf="flex-end"
      >
        <Column style={{ width: '100%' }}>
          <PaddedColumn gap="0">
            <SelectToken onDismiss={onDismiss} />
            <Tooltip text={t('importAnyToken')} show={tooltipOpen} placement="bottom">
              <div className="mt-[16px] pb-[12px]">
                <InputContainer>
                  {/* <SearchBarIcon /> */}
                  <SearchInput
                    type="text"
                    id="token-search-input"
                    placeholder={t('tokenSearchPlaceholder')}
                    value={searchQuery}
                    ref={inputRef}
                    onChange={handleInput}
                    onFocus={closeTooltip}
                    onBlur={closeTooltip}
                    onKeyDown={handleEnter}
                  />
                </InputContainer>
              </div>
            </Tooltip>
          </PaddedColumn>
          <div className="bg-black">
            {fixedTokens && fixedTokens.length > 0 && (
              <FixedCurrencies
                onCurrencySelect={handleCurrencySelect}
                selectedCurrency={hiddenCurrency}
                tokens={fixedTokens}
                chainId={chainId}
              />
            )}

            <CurrencyList
              currencies={currencies}
              allBalances={allTokenBalances}
              onCurrencySelect={handleCurrencySelect}
              otherCurrency={otherSelectedCurrency}
              selectedCurrency={hiddenCurrency}
              showSendWithSwap={showSendWithSwap}
              height={isMobile ? window.innerHeight - 440 - alertHeight : window.innerHeight - 536 - alertHeight}
              setInactiveTokens={setInactiveTokens}
              setTokenImport={setTokenImport}
            />
          </div>
          <a
            className={'link text-center justify-center bg-black py-[12px] flex items-center rounded-b-[24px]'}
            onClick={() => {
              setShowCustomCurrencies(true)
            }}
          >
            Manage Tokens
          </a>
        </Column>
      </Modal>
      {showCustomCurrencies && (
        <CustomCurrencies
          isOpen={showCustomCurrencies}
          onDismiss={() => {
            setShowCustomCurrencies(false)
          }}
          onCurrencySelect={handleCurrencySelect}
          setTokenImport={setTokenImport}
        />
      )}
      <Modal isOpen={!!tokenImport} maxHeight={90} forceMaxHeight={'80vh'} onDismiss={onDismissWarningImport}>
        <WarningImport
          onDismiss={onDismissWarningImport}
          token={tokenImport}
          setInactiveTokens={setInactiveTokens}
          onCurrencySelect={handleCurrencySelect}
        />
      </Modal>
    </>
  )
}
