import React, { useRef, useState, useEffect } from 'react'

const DropdowAction = ({ showConsolidateIntro, handleConvertV1ToV2 }: any) => {
  const [showMenus, setShowMenus] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref.current as any)?.contains(event.target)) {
        setShowMenus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <div className="relative w-full pb-[120px]" ref={ref}>
      <div
        className="w-full flex items-center h-[48px] px-[20px] rounded-[10px] border-[2px] border-main justify-between cursor-pointer"
        onClick={() => {
          setShowMenus(prev => !prev)
        }}
      >
        <p className="text-[16px] font-bold text-white">SafeMoon Migration Tools</p>
        <img src="/images/down.svg" className="w-[24px]" alt="down" />
      </div>
      {showMenus && (
        <div className="dropdown-wrapper">
          <div className="py-[20px] pl-[20px] pr-[15px] border-b-[1px] border-main flex items-center justify-between">
            <div
              className="mb-[0] text-[16px] font-bold main-text consolidate cursor-pointer"
              onClick={() => {
                setShowMenus(false)
                handleConvertV1ToV2()
              }}
            >
              Consolidate to V2 SafeMoon
            </div>

            <div
              className="info-button cursor-pointer"
              onClick={() => {
                setShowMenus(false)
                showConsolidateIntro()
              }}
            >
              <img src="/images/info.svg" className="w-[24px]" alt="info" />
            </div>
          </div>
          <a
            className="px-[20px] pt-[20px] pb-[24px] cursor-pointer text-[16px] font-bold main-text link-dropdown block"
            href="https://migrate.safemoon.com/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              setShowMenus(false)
            }}
          >
            Migration Completion Program
          </a>
        </div>
      )}
    </div>
  )
}

export default DropdowAction
