import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
import { NavLink, Link, useLocation } from 'react-router-dom'
import SVG from 'react-inlinesvg'

// import ArrowLeft from '../../assets/icons/arrow-left-3.svg'

import Row, { RowBetween } from '../Row'
import QuestionHelper from '../QuestionHelper'
import { ButtonGray } from '../Button'
import { useActiveWeb3React } from '../../hooks'
import { SFM_V2 } from '../../constants'
import { SettingsWrapper } from '../../pages/Swap'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import classNames from 'classnames'

const Tabs = styled.div<any>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  border-radius: 30px;
  justify-content: space-evenly;
  margin-bottom: 2rem;
`

const LimitedTabs = styled(Tabs)<{ viewMode?: string }>`
  background-color: ${({ theme }) => theme.bgTabs};
  height: 46px;
  padding: 4px;
  border-radius: 23px;
  width: 451px;
  z-index: 99;
  margin-bottom: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: calc(100% - 40px);
    margin-bottom: ${({ viewMode }) => (viewMode === 'mobile' ? 0 : '20px')};
  `};
`

const WalletTabs = styled(Tabs)`
  background-color: ${({ theme }) => theme.bgDark};
`

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  font-weight: bold;
  transition: all ease 0.3s;
  flex: 1;

  &.${activeClassName} {
    width: 78px;
    font-weight: bold;
    color: black;
    background-color: ${({ theme }) => theme.primaryMain};
  }

  :hover,
  :focus {
  }
`

const WalletButton = styled.button<{ active?: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme, active }) => (active ? `${theme.black} !important` : theme.text3)};
  background-color: ${({ theme, active }) => (active ? theme.primaryMain : 'transparent')};
  font-weight: ${({ active }) => (active ? 'bold' : '600')};
  border: none;
  font-size: 0.875rem;
  width: 50%;
  transition: all ease 0.3s;

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.text1)};
    outline: none;
  }
`

const ActiveText = styled.div`
  font-weight: bold;
  font-size: 22px;
  margin-left: 10px;
  color: #edf7f6;
`

const StyledArrowLeft = styled(SVG).attrs(props => ({
  ...props,
  src: '/images/right-arrow.svg',
  width: 24,
  height: 24
}))`
  color: ${({ theme }) => theme.text1};
  width: 24px;
  height: 24px;
`

const HistoryLink = styled(Link)`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const HistoryLinkButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StyledGrayButton = styled(ButtonGray)`
  height: 40px;
  border-radius: 12px;
  color: ${({ theme }) => theme.text1};
  max-width: 103px;
`

export function SwapPoolTabs() {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const { viewMode, showBalance } = useParsedQueryString()
  const { pathname } = useLocation()

  return (
    <LimitedTabs viewMode={viewMode}>
      <StyledNavLink
        id={`swap-nav-link`}
        to={
          viewMode
            ? `/swap?viewMode=${viewMode}&showBalance=${showBalance}&inputCurrency=ETH&outputCurrency=${SFM_V2[chainId]}`
            : '/swap'
        }
      >
        {t('Swap')}
      </StyledNavLink>
      <StyledNavLink
        id={`swap-nav-link`}
        to={viewMode ? `/bridge?viewMode=${viewMode}&showBalance=${showBalance}` : '/bridge'}
      >
        {t('Bridge')}
      </StyledNavLink>
      <StyledNavLink
        id={`swap-nav-link`}
        to={viewMode ? `/lpf/pool?viewMode=${viewMode}&showBalance=${showBalance}` : '/lpf/pool'}
        isActive={() => pathname?.indexOf('/lpf') !== -1}
      >
        {t('LPF')}
      </StyledNavLink>
    </LimitedTabs>
  )
}

export function LiquidityTabs({
  currentTab,
  handleSelectTab
}: {
  currentTab: string
  handleSelectTab: (currentTab: string) => void
}) {
  const { t } = useTranslation()

  return (
    <div className="flex items-center !h-[44px] p-[3px] bg-black rounded-full mb-[20px]">
      <a
        id={`swap-nav-link`}
        onClick={() => handleSelectTab('YOUR_LIQUIDITY')}
        className={classNames(
          'flex-1 flex items-center justify-center h-full rounded-full text-gray text-[14px] font-bold cursor-pointer',
          currentTab === 'YOUR_LIQUIDITY' && 'text-white bg-active-tab'
        )}
      >
        {t('Your Liquidity')}
      </a>
      <a
        id={`swap-nav-link`}
        onClick={() => handleSelectTab('ALL_LPF_POOLS')}
        className={classNames(
          'flex-1 flex items-center justify-center h-full rounded-full text-gray text-[14px] font-bold cursor-pointer',
          currentTab === 'ALL_LPF_POOLS' && 'text-white bg-active-tab'
        )}
      >
        {t('All LPF Pools')}
      </a>
    </div>
  )
}

export function FindPoolTabs() {
  const { t } = useTranslation()
  return (
    <Tabs className="relative">
      <NavLink className="cursor-pointer absolute left-0 top-[50%] translate-y-[-50%]" to={'/lpf/pool'}>
        <img src={'/images/left.svg'} className="w-[24px]" />
      </NavLink>
      <ActiveText className="text-center">{t('importpool')}</ActiveText>
      <div className="absolute right-0 top-[50%] translate-y-[-50%]">
        <QuestionHelper text={t('usethistool')} />
      </div>
    </Tabs>
  )
}

export function AddTabs({ toggle }: any) {
  const { t } = useTranslation()
  return (
    <Tabs className="relative">
      <NavLink className="cursor-pointer absolute left-0 top-[50%] translate-y-[-50%]" to={'/lpf/pool'}>
        <img src={'/images/left.svg'} className="w-[24px]" />
      </NavLink>
      <ActiveText className="text-center">{t('addLiquidity')}</ActiveText>
      <div className="flex justify-end absolute right-0 top-[50%] translate-y-[-50%]">
        <SettingsWrapper onClick={toggle}>
          <SVG src={'/images/settings.svg'} width={24} height={24} />
        </SettingsWrapper>
        <QuestionHelper text={t('whenyouaddliquidity')} />
      </div>
    </Tabs>
  )
}

export function LpfTabs({ toggle }: any) {
  const { t } = useTranslation()
  return (
    <Tabs className="relative">
      <NavLink className="cursor-pointer absolute left-0 top-[50%] translate-y-[-50%]" to={'/lpf/pool'}>
        <img src={'/images/left.svg'} className="w-[24px]" />
      </NavLink>
      <ActiveText className="text-center !ml-0">Manage Your LPF Rewards</ActiveText>
      <div className="flex justify-end absolute right-0 top-[50%] translate-y-[-50%] ">
        <QuestionHelper text={t('whenyouaddliquidity')} />
      </div>
    </Tabs>
  )
}

export function AddSTPTabs() {
  const { t } = useTranslation()
  return (
    <Tabs>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLink to="/manage-stp">
            <StyledArrowLeft />
          </HistoryLink>
          <ActiveText>{t('addEditSTP')}</ActiveText>
        </Row>
      </RowBetween>
    </Tabs>
  )
}

export function RemoveTabs({ onChangeDetails, detailed }: any) {
  const { t } = useTranslation()
  return (
    <Tabs className="relative">
      <NavLink className="cursor-pointer absolute left-0 top-[50%] translate-y-[-50%]" to={'/lpf/pool'}>
        <img src={'/images/left.svg'} className="w-[24px]" />
      </NavLink>
      <ActiveText className="text-center">{t('removeLiquidity')}</ActiveText>
      <StyledGrayButton onClick={onChangeDetails} className="!absolute right-0 top-[50%] translate-y-[-50%]">
        {detailed ? 'Simple' : 'Detailed'}
      </StyledGrayButton>
    </Tabs>
  )
}

export function SelectToken({ onDismiss, title }: { onDismiss: () => void; title?: string }) {
  const { t } = useTranslation()
  return (
    <Tabs style={{ marginBottom: '0.625rem', position: 'relative' }}>
      <a className="cursor-pointer absolute left-0 top-[50%] translate-y-[-50%]" onClick={onDismiss}>
        <img src={'/images/left.svg'} className="w-[24px]" />
      </a>

      <ActiveText className="text-center !ml-0">{title || t('selectToken')}</ActiveText>
    </Tabs>
  )
}

export function SettingsTab({ onDismiss }: { onDismiss: any }) {
  return (
    <Tabs style={{ marginBottom: '0.625rem' }}>
      <RowBetween>
        <Row align={'center'}>
          <HistoryLinkButton onClick={onDismiss}>
            <StyledArrowLeft />
          </HistoryLinkButton>
          <ActiveText>Settings</ActiveText>
        </Row>
      </RowBetween>
    </Tabs>
  )
}
