import React from 'react'
import styled from 'styled-components'
// import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'

const AdvancedDetailsFooter = styled.div<{ show: boolean }>`
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: ${({ theme }) => theme.text3};

  display: ${({ show }) => (show ? 'flex' : 'none')};
`

export default function AdvancedSwapDetailsDropdown({ trade, priceInfo, ...rest }: AdvancedSwapDetailsProps) {
  // const lastTrade = useLastTruthy(trade)

  return (
    <AdvancedDetailsFooter show={Boolean(trade || priceInfo)}>
      <AdvancedSwapDetails {...rest} trade={trade ?? undefined} priceInfo={priceInfo} />
    </AdvancedDetailsFooter>
  )
}
