import React, { useState } from 'react'
import { Text } from 'rebass'
import { formatUnits } from 'ethers/lib/utils'
import AppBody from '../AppBody'
import { ButtonPrimary } from '../../components/Button'
import { useActiveWeb3React } from '../../hooks'
import { useLPFContract } from '../../hooks/useContract'
import { getNativeSymbol } from '../../utils/getTokenSymbol'
import { useTrackedTokenPairs } from '../../state/user/hooks'
import { useTokenPairsWithLiquidityTokens } from '../../data/PairAddress'
import { ERC20_ABI } from '../../constants/abis/erc20'
import { getContract, getEtherscanLink } from '../../utils'
import TransferLp from './TransferLP'
import ClaimReward from './ClaimReward'
import { LpfTabs } from '../../components/NavigationTabs'
import Modal from '../../components/Modal'
import LPFWarningPopup from './LPFWarningPopup'
import { ETHER } from '@safemoon/sdk'
import CurrencyLogo from '../../components/CurrencyLogo'
import CurrencyLogoAdded from '../../components/CurrencyLogo/CurrencyLogoAdded'

const ManageLpf = () => {
  const { account, chainId, library } = useActiveWeb3React()
  const LPF = useLPFContract()
  const [selectedItem, setSelectedItem] = useState<any>()
  const [openTransferLP, setOpenTransferLP] = useState(false)
  const [openClaimReward, setOpenClaimReward] = useState(false)

  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useTokenPairsWithLiquidityTokens(trackedTokenPairs)
  const [totalRewards, setTotalRewards]: any = useState('')
  const [lpsPriceRange, setLpsPriceRange]: any = useState([])

  const [loadingRegister, setLoadingRegister] = useState({})

  const getAllRewards = async () => {
    const controller = new AbortController()

    const { totalRewards }: any = await LPF.getUserTotalRewards(account)

    console.log('totalRewards', totalRewards)

    setTotalRewards(totalRewards?.toString())
    return () => {
      controller.abort()
    }
  }

  const getLpsPriceRangeInfo = async () => {
    // const userPools = await LPF.getUserPools(account)

    const userPairsInfo: any = []

    const requests = []

    for (let i = 0; i < tokenPairsWithLiquidityTokens?.length; i++) {
      const pair = tokenPairsWithLiquidityTokens[i]
      const pairContract = getContract(pair.liquidityToken.address, ERC20_ABI, library, account)
      requests.push(
        Promise.all([LPF.getUserRewardsPerPid(account, pair.liquidityToken.address), pairContract.balanceOf(account)])
      )
    }

    const result = await Promise.all(requests)

    for (let i = 0; i < tokenPairsWithLiquidityTokens?.length; i++) {
      const pair = tokenPairsWithLiquidityTokens[i]
      const [rewards, pairBalance] = result[i]

      if (+rewards || +pairBalance) {
        userPairsInfo.push({
          pairAddress: pair.liquidityToken.address,
          tokens: `${pair.tokens[0].symbol}, ${pair.tokens[1].symbol}`,
          rewards: rewards?.toString(),
          pairBalance: pairBalance.toString(),
          logo1: (pair.tokens[0] as any)?.tokenInfo?.logoURI || (pair.tokens[0] as any)?.logoURI,
          logo2: (pair.tokens[1] as any)?.tokenInfo?.logoURI || (pair.tokens[1] as any)?.logoURI,
          token0: pair.tokens[0].symbol,
          token1: pair.tokens[1].symbol
        })
      }
    }

    // for (const pair of tokenPairsWithLiquidityTokens) {
    //   const { rewards, isBoolNotExist } = await LPF.getUserRewardsPerPid(account, pair.liquidityToken.address)
    //   const pairContract = getContract(pair.liquidityToken.address, ERC20_ABI, library, account)
    //   const pairBalance = await pairContract.balanceOf(account)

    //   if (+rewards || +pairBalance) {
    //     userPairsInfo.push({
    //       pairAddress: pair.liquidityToken.address,
    //       tokens: `${pair.tokens[0].symbol}, ${pair.tokens[1].symbol}`,
    //       rewards: rewards.toString(),
    //       pairBalance: pairBalance.toString(),
    //       isBoolNotExist
    //     })
    //   }

    // }

    // console.log('userPairsInfo', userPairsInfo)
    userPairsInfo.length && setLpsPriceRange(userPairsInfo)
  }

  const handleRegister = async detail => {
    setLoadingRegister(prev => ({
      ...prev,
      [detail.pairAddress]: true
    }))
    LPF.registerUserToLpToken(detail.pairAddress)
      .then(receipt => {
        receipt.wait().then(() => {
          // setMsg('Pair Registered Successfully')
          // setIsModalOpen(true)
          // setIsRegisterClicked(false)
          // window.location.reload()

          setLpsPriceRange(prev =>
            prev.map(item => (item.pairAddress === detail.pairAddress ? { ...item, isBoolNotExist: false } : item))
          )

          setLoadingRegister(prev => ({
            ...prev,
            [detail.pairAddress]: false
          }))
        })
      })
      .catch((e: any) => {
        // if the user rejected the tx, pass this along
        if (e?.code === 4001) {
          // setMsg('User Rejected the transaction')
          // setIsModalOpen(true)
          // setIsRegisterClicked(false)
        } else {
          // setMsg(e.data?.message ? e.data?.message : 'Some thing went wrong, please try again later')
          // setIsModalOpen(true)
          // setIsRegisterClicked(false)
        }

        setLoadingRegister(prev => ({
          ...prev,
          [detail.pairAddress]: false
        }))
      })
  }

  React.useEffect(() => {
    account && LPF && getLpsPriceRangeInfo()
  }, [account, LPF, JSON.stringify(tokenPairsWithLiquidityTokens)])

  React.useEffect(() => {
    account && LPF && getAllRewards()
  }, [account, LPF])

  return (
    <AppBody disabled={false}>
      <LpfTabs />

      <div className="flex items-center mb-[12px]">
        <p className="text-left label-text2 text-[16px] font-normal w-[170px] px-[12px]">Pair Tokens</p>
        <p className="text-left label-text2 text-[16px] font-normal flex-1">Rewards</p>
      </div>

      {lpsPriceRange?.map((d, index) => (
        <div key={d.pairAddress} className="row items-center mb-[8px] rounded-[100px] bg-black">
          <a
            className="w-[170px] px-[12px] flex items-center"
            href={`${getEtherscanLink(chainId, d.pairAddress, 'address')}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="relative mr-[22px]">
              <span className="w-[22px] h-[22px] rounded-full bg-gray flex items-center justify-center relative z-[2]">
                {d.logo1 ? (
                  <img src={d.logo1} className="h-[22px] " />
                ) : d.token0 === 'WETH' || d.token0 === 'WBNB' || d.token0 === 'WMATIC' || d.token0 === 'WPOL' ? (
                  <CurrencyLogo currency={ETHER} size="20px" />
                ) : (
                  <CurrencyLogoAdded symbol={d.token0} size="20px" />
                )}
              </span>
              <span className="w-[22px] h-[22px] rounded-full bg-gray flex items-center justify-center ml-[-8px] absolute top-0 right-[-16px] z-[1]">
                {d.logo2 ? (
                  <img src={d.logo2} className="h-[22px] " />
                ) : d.token1 === 'WETH' || d.token1 === 'WBNB' || d.token1 === 'WMATIC' || d.token1 === 'WPOL' ? (
                  <CurrencyLogo currency={ETHER} size="20px" />
                ) : (
                  <CurrencyLogoAdded symbol={d.token1} size="20px" />
                )}
              </span>
            </span>

            <span className="text-[14px] text-white">{d.tokens}</span>
          </a>
          <div className="flex-1 flex items-center">
            <p className="text-left p-[0] text-[16px] font-bold main-text flex-1">
              {(parseInt(d.rewards) / 1e18).toFixed(7)}
            </p>
            <div className="flex items-center justify-end">
              <ButtonPrimary
                width="99px"
                size={'small'}
                height={'50px'}
                onClick={() => {
                  setSelectedItem(d)
                  setOpenClaimReward(true)
                }}
                disabled={d.rewards <= 0}
              >
                <Text fontSize={16} fontWeight={'bold'}>
                  Claim
                </Text>
              </ButtonPrimary>
            </div>
          </div>
        </div>
      ))}
      <div className="flex justify-center flex-col mt-[36px]">
        <p className="text-center text-[16px] label-text2 mb-[18px]">
          All Rewards:{' '}
          <strong className="text-white text-[18px]">
            &nbsp;&nbsp;{totalRewards && parseFloat(formatUnits(totalRewards, 'ether')).toFixed(7)}{' '}
            {getNativeSymbol(chainId)}
          </strong>
        </p>
        <ButtonPrimary
          onClick={() => {
            setSelectedItem({
              rewards: totalRewards
            })
            setOpenClaimReward(true)
          }}
          height="50px"
          disabled={totalRewards <= 0}
        >
          <Text fontSize={16} fontWeight={'bold'}>
            Claim All Rewards
          </Text>
        </ButtonPrimary>
      </div>

      <Modal isOpen={openTransferLP} onDismiss={() => setOpenTransferLP(false)}>
        <TransferLp
          handleClose={() => {
            setOpenTransferLP(false)
          }}
          selectedItem={selectedItem}
          getLpsPriceRangeInfo={getLpsPriceRangeInfo}
        />
      </Modal>

      <Modal isOpen={openClaimReward} onDismiss={() => setOpenClaimReward(false)} maxHeight={100}>
        <ClaimReward
          handleClose={() => {
            setOpenClaimReward(false)
          }}
          selectedItem={selectedItem}
          getLpsPriceRangeInfo={getLpsPriceRangeInfo}
          chainId={chainId}
        />
      </Modal>

      <LPFWarningPopup />
    </AppBody>
  )
}

export default ManageLpf
