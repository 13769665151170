/* eslint-disable */
import React, { useRef, useEffect, useMemo, useState } from 'react'
import SVG from 'react-inlinesvg'
import { Text } from 'rebass'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import { WalletConnectConnector } from '../../connectors/WalletConnector'
import { ButtonError, ButtonPrimary } from '../../components/Button'
import { AutoColumn } from '../../components/Column'
import CurrencyInputPanel from './CurrencyInputPanel'
import { AutoRow, RowBetween } from '../../components/Row'
import { ArrowWrapper, BottomGrouping, Wrapper } from '../../components/swap/styleds'
import bridgeABI from '../../constants/abis/bridgeABI.json'
import Circle from '../../assets/images/blue-loader.svg'

import { useActiveWeb3React } from '../../hooks'

import {
  useAddPopup,
  useSettingsMenuOpen,
  useToggleSettingsMenu,
  useWalletModalToggle,
  useWrongNetwork
} from '../../state/application/hooks'
import { useDefaultsFromURLSearch } from '../../state/swap/hooks'

import AppBody from '../AppBody'

// import Banner from '../Swap/Banner'
import {
  BINANCE_NETWORK,
  BirdgeChain,
  BRIDGES,
  BRIDGE_API_URL,
  BRIDGE_CONTRACT,
  getChainType,
  SFM_V2,
  MARKETDATA_URL,
  ChainIdHex
} from '../../constants'
import Web3 from 'web3'
import { fromWei, handleSwitchChainInjected, toWei } from '../../utils'
import ConfirmBridgeModal from './ConfirmBridgeModal'
import { ERC20_ABI } from '../../constants/abis/erc20'
import { ethers } from 'ethers'
import classNames from 'classnames'
import { CustomLightSpinner } from '../../components/TransactionConfirmationModal'
import { activeNetwork, binanceinjected, injected } from '../../connectors'
import { useTransactionAdder } from '../../state/transactions/hooks'
import ChainWarning from './ChainWarning'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { ChainId, WETH } from '@safemoon/sdk'
import QuestionHelper from '../../components/QuestionHelper'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import { useBridgeContract, useTokenContract } from '../../hooks/useContract'
import { SettingsWrapper } from '../Swap'
import SettingsModal from '../../components/SettingsModal'
import { useGasPrice } from '../../state/user/hooks'
import { useShowSFMBridge } from '../../hooks/useShowSFMBridge'

export default function Bridge({}: RouteComponentProps<{}>) {
  useDefaultsFromURLSearch()

  const [showChainWarning, setShowChainWarning] = useState(false)
  const [switchChainId, setSwitchChainId] = useState(null)

  const [showConfirm, setShowConfirm] = useState(false)
  const [swapError, setSwapError] = useState('')
  const [transactionHash, setTransactionHash] = useState('')
  const [bridgeInfo, setBridgeInfo] = useState<any>()
  const showSFMBridge = useShowSFMBridge()

  const [balances, setBalances] = useState({})
  const [allowances, setAllowances] = useState({})
  const [paused, setPaused] = useState({})
  const [whitelist, setWhitelist] = useState({})

  const [loadingApprove, setLoadingApprove] = useState(false)

  const query = useParsedQueryString()

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [priceUsd, setPriceUsd] = useState<any>({})

  const [inputChain, setInputChain] = useState<{ value: string; chain: BirdgeChain | null }>({ chain: null, value: '' })
  const [outputChain, setOutputChain] = useState<{ value: string; chain: BirdgeChain | null }>({
    chain: null,
    value: ''
  })

  const toggleWalletModal = useWalletModalToggle()

  const { account, library, chainId, activate, connector } = useActiveWeb3React()

  const tokenContract = useTokenContract(inputChain?.chain?.tokenAddress)
  const bridgeContractHook = useBridgeContract(BRIDGE_CONTRACT[chainId])
  const node = useRef<HTMLDivElement>()
  const open = useSettingsMenuOpen()
  const toggle = useToggleSettingsMenu()

  const gasPrice = useGasPrice()

  const handleClickOutside = (e: any) => {
    if (node.current?.contains(e.target) ?? false) {
    } else {
      toggle()
    }
  }

  useEffect(() => {
    if (account && chainId && +chainId === 56 && connector instanceof WalletConnectConnector) {
      ;(connector as WalletConnectConnector)?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: ChainIdHex.BSC_MAINNET }]
      })
    }
  }, [account, chainId])

  const addTransaction = useTransactionAdder()

  const addPopup = useAddPopup()

  const listBridges = useMemo(() => {
    if (showSFMBridge === undefined) {
      return undefined
    }

    return showSFMBridge
      ? BRIDGES(query?.viewMode === 'mobile' ? 'mainnet' : getChainType(chainId))
      : BRIDGES(query?.viewMode === 'mobile' ? 'mainnet' : getChainType(chainId))?.filter(item => item.symbol !== 'SFM')
  }, [showSFMBridge, chainId, query?.viewMode])

  const getBalances = async () => {
    try {
      const listChains = listBridges
      const web3Lib = new Web3(library?.provider as any)
      const [nativeBalance, ...balances] = await Promise.all([
        web3Lib.eth.getBalance(account),
        ...listChains.map((item: BirdgeChain) => {
          const web3 = new Web3(new Web3.providers.HttpProvider(item.rpcUrl))
          const erc20Contract = new web3.eth.Contract(ERC20_ABI as any, item.tokenAddress)
          return erc20Contract.methods.balanceOf(account).call()
        })
      ])

      const balanceObj = {
        nativeBalance: Number(nativeBalance) / 10 ** 18
      }

      listChains.forEach((item: BirdgeChain, i: number) => {
        balanceObj[item.id] = fromWei({ val: Number(balances[i]), decimals: +item?.decimals })
      })

      setBalances(balanceObj)
    } catch (e) {}
  }

  useEffect(() => {
    if (account && listBridges) {
      getBalances()
    }

    const interval = setInterval(() => {
      getBalances()
    }, 30000)

    return () => {
      clearInterval(interval)
    }
  }, [chainId, account, query.viewMode, listBridges])

  const getAllowances = async () => {
    try {
      const listChains = listBridges

      const listAllowances = await Promise.all(
        listChains.map((item: BirdgeChain) => {
          const web3 = new Web3(new Web3.providers.HttpProvider(item.rpcUrl))
          const erc20Contract = new web3.eth.Contract(ERC20_ABI as any, item.tokenAddress)
          return erc20Contract.methods.allowance(account, BRIDGE_CONTRACT[item.chainId]).call()
        })
      )

      const allowancesObj = {}

      listChains.forEach((item: BirdgeChain, i: number) => {
        allowancesObj[item.id] = Number(listAllowances[i])
      })

      setAllowances(allowancesObj)
    } catch (e) {
      console.log(e)
    }
  }

  const getWhitelist = async () => {
    try {
      const listChains = listBridges

      const listWhitelist = await Promise.all(
        listChains.map((item: BirdgeChain) => {
          const web3 = new Web3(new Web3.providers.HttpProvider(item.rpcUrl))
          const bridgeContract = new web3.eth.Contract(bridgeABI as any, BRIDGE_CONTRACT[item.chainId])
          return bridgeContract.methods.whitelist(account).call()
        })
      )

      const whitelistObj = {}

      listChains.forEach((item: BirdgeChain, i: number) => {
        whitelistObj[item.id] = listWhitelist[i]
      })

      setWhitelist(whitelistObj)
    } catch (e) {
      console.log(e)
    }
  }

  const getPaused = async () => {
    try {
      const listChains = listBridges

      const listPaused = await Promise.all(
        listChains.map((item: BirdgeChain) => {
          const web3 = new Web3(new Web3.providers.HttpProvider(item.rpcUrl))
          const bridgeContract = new web3.eth.Contract(bridgeABI as any, BRIDGE_CONTRACT[item.chainId])
          return bridgeContract.methods.paused().call()
        })
      )

      const pausedObj = {}

      listChains.forEach((item: BirdgeChain, i: number) => {
        pausedObj[item.id] = listPaused[i]
      })

      setPaused(pausedObj)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (listBridges) {
      getPaused()
    }
  }, [chainId, query.viewMode, listBridges])

  useEffect(() => {
    if (account && listBridges) {
      getAllowances()
      getWhitelist()
    }
  }, [chainId, account, query.viewMode, listBridges])

  useEffect(() => {
    // console.log('chainId', chainId)
    if (chainId && listBridges) {
      const listChains = listBridges
      const currentChain =
        inputChain?.chain?.chainId === chainId ? inputChain?.chain : listChains.find(item => item.chainId === chainId)

      // console.log('currentChain ===>', currentChain, listChains, chainId)
      if (currentChain) {
        setInputChain({
          value: '',
          chain: currentChain
        })
        setOutputChain({
          value: '',
          chain: outputChain?.chain?.chainId !== chainId ? outputChain?.chain : null
        })
      }
    }
  }, [chainId, query?.viewMode, listBridges])

  const handleApprove = async () => {
    try {
      setLoadingApprove(true)

      const tx = await tokenContract.approve(BRIDGE_CONTRACT[inputChain?.chain?.chainId], ethers.constants.MaxUint256)
      const result = await tx.wait()
      addTransaction(
        { ...result, hash: result.hash || result.transactionHash },
        {
          summary: `Approve ${inputChain?.chain?.symbol}/${inputChain?.chain?.shortChain}`,
          approval: {
            tokenAddress: inputChain?.chain?.tokenAddress,
            spender: BRIDGE_CONTRACT[inputChain?.chain?.chainId]
          }
        }
      )

      getAllowances()

      setLoadingApprove(false)
    } catch (e) {
      console.log('error ====>', e)
      setLoadingApprove(false)
      setShowConfirm(true)
      let message = e.message
      if (message.indexOf('insufficient funds') !== -1) {
        message = 'Insufficient Funds'
      }
      setSwapError(message)
    }
  }

  const getNetworkFee = async bridgeInfo => {
    try {
      const web3 = new Web3(library?.provider as any)

      const bridgeContract = new web3.eth.Contract(bridgeABI as any, BRIDGE_CONTRACT[chainId])

      const gasLimit = await bridgeContract.methods
        .burn(
          account,
          inputChain.chain?.tokenAddress,
          toWei({ val: inputChain.value, decimals: inputChain?.chain?.decimals }),
          outputChain.chain?.chain,
          bridgeInfo.fee,
          bridgeInfo.deadline,
          bridgeInfo.nonce,
          bridgeInfo?.isUseDeadAddress,
          bridgeInfo.signature
        )
        .estimateGas({
          from: account,
          value: bridgeInfo.fee
        })

      const networkFee = (Math.round(Number(gasLimit)) * (+gasPrice / 10 ** 9)) / 10 ** 9

      setBridgeInfo({
        ...bridgeInfo,
        networkFee,
        isInsufficient: +bridgeInfo?.fee / 10 ** 18 + networkFee > (balances as any)?.nativeBalance
      })
    } catch (e) {
      console.log('e', e)
      setBridgeInfo({
        ...bridgeInfo,
        networkFee: null,
        isInsufficient: e.message.indexOf('insufficient funds') !== -1
      })
    }
  }

  const handleSwap = async () => {
    try {
      setLoading(true)

      const result = await axios.post(`${BRIDGE_API_URL}/bridge/generate`, {
        recipient: account,
        tokenAddress: inputChain.chain?.tokenAddress,
        amount: toWei({ val: inputChain.value, decimals: inputChain?.chain?.decimals }),
        fromNetwork: inputChain.chain?.network,
        toNetwork: outputChain.chain?.network
      })

      await getNetworkFee(result?.data?.data)

      setSwapError('')
      setTransactionHash('')
      setShowConfirm(true)
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const onConfirm = async () => {
    try {
      setLoading(true)

      const gasLimit = await bridgeContractHook.estimateGas.burn(
        account,
        inputChain.chain?.tokenAddress,
        toWei({ val: inputChain.value, decimals: inputChain?.chain?.decimals }),
        outputChain.chain?.chain,
        bridgeInfo.fee,
        bridgeInfo.deadline,
        bridgeInfo.nonce,
        bridgeInfo?.isUseDeadAddress,
        bridgeInfo.signature,
        { value: bridgeInfo.fee }
      )

      const tx = await bridgeContractHook.burn(
        account,
        inputChain.chain?.tokenAddress,
        toWei({ val: inputChain.value, decimals: inputChain?.chain?.decimals }),
        outputChain.chain?.chain,
        bridgeInfo.fee,
        bridgeInfo.deadline,
        bridgeInfo.nonce,
        bridgeInfo.isUseDeadAddress,
        bridgeInfo.signature,
        {
          value: bridgeInfo.fee,
          gasLimit: Math.round(+gasLimit),
          gasPrice
        }
      )

      setLoading(false)
      setTransactionHash(tx.hash)
      addTransaction(
        {
          chainId,
          hash: tx.hash,
          from: account
        } as any,
        {
          summary: `Bridge ${inputChain.value} ${inputChain?.chain?.shortChain} ${inputChain?.chain?.symbol} to ${outputChain.value} ${outputChain?.chain?.shortChain} ${outputChain?.chain?.symbol}`
        }
      )
      setInputChain({ chain: inputChain.chain, value: '' })
      setOutputChain({ chain: outputChain.chain, value: '' })

      const result = await tx.wait()

      addTransaction(
        { ...result, hash: result.hash || result.transactionHash },
        {
          summary: `Bridge ${inputChain.value} ${inputChain?.chain?.shortChain} ${inputChain?.chain?.symbol} to ${outputChain.value} ${outputChain?.chain?.shortChain} ${outputChain?.chain?.symbol}`
        }
      )

      if (isMobile) {
        const hash = result.hash
        addPopup(
          {
            txn: {
              hash,
              success: true,
              summary: `Bridge ${inputChain.value} ${inputChain?.chain?.shortChain} ${inputChain?.chain?.symbol} to ${outputChain.value} ${outputChain?.chain?.shortChain} ${outputChain?.chain?.symbol}`
            }
          },
          hash
        )
      }

      getAllowances()

      setTimeout(() => {
        getBalances()
      }, 10000)
    } catch (e) {
      let message = e.message
      console.log('message', message)
      if (message?.indexOf('Internal JSON-RPC error.') !== -1) {
        const messageObj = JSON.parse(e.message?.split('Internal JSON-RPC error.')[1] || '{}')
        message = messageObj?.message || 'Internal JSON-RPC error.'
      }

      if (message?.indexOf('0x') !== -1) {
        message = message?.split('0x')[0]
      }

      if (message.indexOf('execution reverted: Pausable: paused') !== -1) {
        message = 'execution reverted: Pausable: paused'
      }

      if (message.indexOf('user rejected transaction') !== -1) {
        message = 'User rejected transaction'
      }

      setSwapError(message)
      setLoading(false)
    }
  }

  const handleChangeValue = (val: string) => {
    setInputChain(prev => ({
      ...prev,
      value: val
    }))
    setOutputChain(prev => ({
      ...prev,
      value: val
    }))
  }

  const { toggleWrongNetwork } = useWrongNetwork()

  const handleSelectChain = async (chain, type) => {
    if (type === 'input') {
      if (window.ethereum && (query?.viewMode === 'mobile' || connector === injected)) {
        if (chainId !== chain.chainId) {
          const web3 = new Web3(window.ethereum as any)
          await handleSwitchChainInjected(chain.chainId)
          const accounts = await web3.eth.getAccounts()
          if (accounts[0]) {
            activate(injected, undefined, true).catch(error => {
              console.error('Failed to activate after accounts changed', error)
            })
          }
        }
      } else if (connector === binanceinjected && window.BinanceChain) {
        if (+chain.chainId !== ChainId.BSC_MAINNET && +chain.chainId !== ChainId.MAINNET) {
          toggleWrongNetwork()
          return
        } else {
          await window.BinanceChain?.switchNetwork(BINANCE_NETWORK[chain.chainId])
          activate(binanceinjected, undefined, true).catch(error => {
            console.error('Failed to activate after accounts changed', error)
          })
        }
      } else if (connector instanceof WalletConnectConnector) {
        connector.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: chain.hexChain }]
        })
      } else {
        if (account) {
          setSwitchChainId(chain?.chainId)
          setShowChainWarning(true)
        } else {
          toggleWalletModal(chain.chainId)
          activeNetwork(chain.chainId, activate)
        }
        return
      }

      if (outputChain?.chain?.chainId === chain?.chainId) {
        setOutputChain(prev => ({
          ...prev,
          chain: inputChain?.chain
        }))
      }
      if (outputChain?.chain?.symbol !== chain?.symbol) {
        setOutputChain(prev => ({
          ...prev,
          chain: null
        }))
      }
      setInputChain(prev => ({
        ...prev,
        chain
      }))
    } else {
      if (inputChain.chain?.chainId === chain?.chainId) {
        setInputChain(prev => ({
          ...prev,
          chain: outputChain?.chain
        }))
      }
      setOutputChain(prev => ({
        ...prev,
        chain
      }))
    }
  }

  const handleSwitchChain = () => {
    if (outputChain?.chain) {
      handleSelectChain(outputChain?.chain, 'input')
    }
  }

  const error = useMemo(() => {
    if (!BRIDGE_CONTRACT[chainId]) {
      return 'Unsupported on this chain'
    }

    if (!inputChain?.chain) {
      return 'Select a token'
    }
    if (paused[inputChain?.chain?.id] && !whitelist[inputChain?.chain?.id]) {
      return 'Paused'
    }

    if (!outputChain?.chain) {
      return 'Select a token'
    }

    if (!inputChain.value || !outputChain?.value) {
      return 'Enter an amount'
    }

    if (+inputChain?.value > +balances[inputChain?.chain?.id]) {
      return `Insufficient ${inputChain?.chain?.symbol} ${inputChain?.chain?.shortChain} balance`
    }
  }, [inputChain, outputChain, balances, paused, whitelist, chainId])

  useEffect(() => {
    const getPriceUsd = async () => {
      try {
        const addresses: any = []

        let currencyInput: any
        let currencyOutput: any

        if (chainId === 56) {
          addresses.push(WETH[56].address)
        } else if (chainId === 1) {
          addresses.push(WETH[1].address)
        } else if (chainId === 137) {
          addresses.push(WETH[137].address)
        }

        addresses.push(SFM_V2[56])
        addresses.push(SFM_V2[1])
        addresses.push(SFM_V2[137])

        if (addresses?.length > 0) {
          const result = await axios.post(`https://marketdata.safemoon.com/api/cryptocurrency/tokens-price-info`, {
            tokenAddresses: addresses,
            chainId
          })

          const priceUsd = result.data.data

          const slugs: any = []

          if (
            currencyInput?.symbol !== 'ETH' &&
            currencyInput?.tokenInfo?.slug &&
            !priceUsd[currencyInput?.address?.toLowerCase()]
          ) {
            slugs.push(currencyInput?.tokenInfo?.slug)
          }

          if (
            currencyOutput?.symbol !== 'ETH' &&
            currencyOutput?.tokenInfo?.slug &&
            !priceUsd[currencyOutput?.address?.toLowerCase()]
          ) {
            slugs.push(currencyOutput?.tokenInfo?.slug)
          }

          if (slugs.length > 0) {
            const data = await axios.get(`${MARKETDATA_URL}/api/cryptocurrency/v7/quotes/latest`, {
              params: {
                slugs: slugs.join(',')
              }
            })

            Object.values(data.data).forEach((item: any) => {
              let address = item?.platform?.token_address
              if (item.symbol === 'BUSD' || item.symbol === 'USDC') {
                if (currencyInput?.symbol === 'BUSD' || currencyInput?.symbol === 'USDC') {
                  address = currencyInput?.address
                } else if (currencyOutput?.symbol === 'BUSD' || currencyOutput?.symbol === 'USDC') {
                  address = currencyOutput?.address
                }
              }
              if (!priceUsd[address?.toLowerCase()]) {
                priceUsd[address?.toLowerCase()] = item?.quote?.USD?.price
              }
            })
          }

          setPriceUsd(priceUsd)
        }
      } catch (e) {
        console.log(e)
      }
    }

    getPriceUsd()

    const id = setInterval(() => {
      getPriceUsd()
    }, 60000)
    return () => clearInterval(id)
  }, [chainId])

  return (
    <>
      {/* <Banner /> */}

      <AppBody disabled={false}>
        <RowBetween className="!mb-[24px]">
          <QuestionHelper
            text={
              <div>
                Depending on network conditions, minting your tokens on the receiving chain can take several minutes or
                more. Once your transaction has been submitted you may close this screen at any time. <br />
                Remember: it is always best practice to perform a small test transaction on any dApp before proceeding.
              </div>
            }
            className="!ml-[-4px]"
          />
          <p className="text-[24px] font-bold text-white">Bridge</p>
          <SettingsWrapper onClick={toggle}>
            <SVG src={'/images/settings.svg'} width={24} height={24} />
          </SettingsWrapper>
        </RowBetween>

        <SettingsModal open={open} onDismiss={handleClickOutside} isBridge={true} />

        <Wrapper id="swap-page">
          <AutoColumn gap={'lg'}>
            <CurrencyInputPanel
              label={t('fromCapitalized')}
              showMaxButton={true}
              id="bridge-input"
              chain={inputChain}
              onUserInput={handleChangeValue}
              handleSelectChain={chain => handleSelectChain(chain, 'input')}
              otherChain={outputChain}
              balances={balances}
              priceUsd={priceUsd}
              showBalance={query.showBalance}
              showSFMBridge={showSFMBridge}
            />

            <AutoColumn justify="space-between">
              <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                <ArrowWrapper
                  clickable
                  onClick={() => {
                    handleSwitchChain()
                  }}
                >
                  <SVG src={'/images/swap.svg'} width={24} height={24} />
                </ArrowWrapper>
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              label={t('toCapitalized')}
              showMaxButton={false}
              chain={outputChain}
              onUserInput={handleChangeValue}
              handleSelectChain={chain => handleSelectChain(chain, 'output')}
              id="bridge-output"
              otherChain={inputChain}
              balances={balances}
              priceUsd={priceUsd}
              showBalance={query.showBalance}
              showSFMBridge={showSFMBridge}
            />
          </AutoColumn>
          <div className="mt-[40px]">
            <BottomGrouping>
              <div className={classNames('flex items-center', error && 'flex-col')}>
                {inputChain?.chain?.chainId &&
                  allowances[inputChain?.chain?.id] < +inputChain?.value * 1000000000 &&
                  inputChain?.value &&
                  error !== 'Paused' && (
                    <ButtonError
                      onClick={handleApprove}
                      id="approve-button"
                      className={classNames(
                        '!mr-[10px]',
                        loadingApprove && 'pointer-events-none',
                        error && '!mb-[10px]'
                      )}
                    >
                      <Text fontSize={16} fontWeight={'bold'}>
                        Approve
                      </Text>
                      {loadingApprove && (
                        <CustomLightSpinner src={Circle} alt="loader" size={'20px'} style={{ marginLeft: '6px' }} />
                      )}
                    </ButtonError>
                  )}

                {!account ? (
                  <ButtonPrimary onClick={toggleWalletModal}>{t('connectWallet')}</ButtonPrimary>
                ) : (
                  <ButtonError
                    onClick={() => {
                      handleSwap()
                    }}
                    id="swap-button"
                    disabled={!!error || allowances[inputChain?.chain?.id] <= 0}
                  >
                    <Text fontSize={16} fontWeight={'bold'}>
                      {error || 'Bridge'}
                    </Text>
                    {loading && (
                      <CustomLightSpinner src={Circle} alt="loader" size={'20px'} style={{ marginLeft: '6px' }} />
                    )}
                  </ButtonError>
                )}
              </div>
            </BottomGrouping>
          </div>
        </Wrapper>
      </AppBody>
      {query?.viewMode !== 'mobile' && (
        <div className="mt-[30px] z-[2]">
          <p className="text-[16px] font-bold text-center mb-[15px]">
            Bridge your {inputChain?.chain?.symbol} to Arbitrum, Avalanche, Base, BSC, ETH, and Polygon
          </p>
          {/* <a
            className="mt-[15px] block text-center text-[16px] leading-[28px] link-color cursor-pointer link"
            href='https://www.safemoon.education/post/safemoon-crosschain'
            target='_blank'
            rel="noopener noreferrer"
          >
            Learn more about Crosschain Bridging
          </a> */}
        </div>
      )}

      <ConfirmBridgeModal
        onConfirm={onConfirm}
        onDismiss={() => {
          setShowConfirm(false)
        }}
        swapErrorMessage={swapError}
        isOpen={showConfirm}
        attemptingTxn={loading}
        txHash={transactionHash}
        inputChain={inputChain}
        outputChain={outputChain}
        priceUsd={priceUsd}
        bridgeInfo={bridgeInfo}
      />

      <ChainWarning
        show={showChainWarning}
        handleClose={() => {
          setShowChainWarning(false)
        }}
        chainId={switchChainId}
      />
    </>
  )
}
