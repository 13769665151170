import { Trade } from '@safemoon/sdk'
import React, { Fragment, memo, useContext } from 'react'
import { ChevronRight } from 'react-feather'
import { Flex } from 'rebass'
import { ThemeContext } from 'styled-components'
import { TYPE } from '../../theme'
import CurrencyLogo from '../CurrencyLogo'
import { useActiveWeb3React } from '../../hooks'
import getTokenSymbol from '../../utils/getTokenSymbol'
import { ExendToken } from '../../state/user/hooks'
import { Field } from '../../state/swap/actions'

export default memo(function SwapRoute({
  trade,
  priceInfo,
  currencies
}: {
  trade?: Trade
  priceInfo?: any
  currencies?: any
}) {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  return (
    <Flex
      px="1rem"
      py="0.5rem"
      my="0.5rem"
      style={{ border: `1px solid ${theme.bgDark}`, borderRadius: '30px', backgroundColor: theme.bgDark }}
      flexWrap="wrap"
      width="100%"
      justifyContent="space-evenly"
      alignItems="center"
    >
      {trade ? (
        <>
          {trade?.route.path.map((token, i, path) => {
            const isLastItem: boolean = i === path.length - 1
            return (
              <Fragment key={i}>
                <Flex my="0.5rem" alignItems="center" style={{ flexShrink: 0 }}>
                  <CurrencyLogo currency={token} size="1.5rem" />
                  <TYPE.black fontSize={14} color={theme.text1} ml="0.5rem">
                    {getTokenSymbol(token, chainId)}
                  </TYPE.black>
                </Flex>
                {isLastItem ? null : <ChevronRight color={theme.text2} />}
              </Fragment>
            )
          })}
        </>
      ) : (
        <>
          {priceInfo?.route?.tokens?.map((token, i, path) => {
            const isLastItem: boolean = i === path.length - 1
            return (
              <Fragment key={i}>
                <Flex my="0.5rem" alignItems="center" style={{ flexShrink: 0 }}>
                  <CurrencyLogo
                    currency={
                      (i === 0 ? currencies?.[Field.INPUT] : isLastItem ? currencies?.[Field.OUTPUT] : token) || token
                    }
                    size="1.5rem"
                  />
                  <TYPE.black fontSize={14} color={theme.text1} ml="0.5rem">
                    {getTokenSymbol(token, chainId)}
                  </TYPE.black>
                </Flex>
                {isLastItem ? null : <ChevronRight color={theme.text2} />}
              </Fragment>
            )
          })}
        </>
      )}
    </Flex>
  )
})
