import React, { Component } from 'react'
import './SwitchField.css'
import classNames from 'classnames'

export default class SwitchField extends Component<any> {
  handleChange = ({ active }) => e => {
    e.stopPropagation()
    const { onChange } = this.props
    onChange(active)
  }

  render() {
    const { value, size } = this.props

    return (
      <div className={'wrapper'}>
        {value ? (
          <span onClick={this.handleChange({ active: false })}>
            <span className={classNames('active switch', size === 'sm' && 'switch-small')} />
          </span>
        ) : (
          <span onClick={this.handleChange({ active: true })}>
            <span className={classNames('inActive switch', size === 'sm' && 'switch-small')} />
          </span>
        )}
      </div>
    )
  }
}
