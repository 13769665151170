import { ChainId, Currency, Token } from '@safemoon/sdk'
import { consolidation } from '../constants'

export default function getTokenSymbol(token: Token | Currency | undefined, chainId: ChainId | undefined) {
  return token instanceof Token &&
    token?.address?.toLowerCase() === consolidation.addresses.v2[chainId as ChainId]?.toLowerCase()
    ? token?.symbol
    : token?.symbol !== 'ETH' || (token as any).address
    ? token?.symbol
    : chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
    ? 'BNB'
    : chainId === ChainId.MUMBAI_TESTNET
    ? 'MATIC'
    : chainId === ChainId.POLYGON
    ? 'POL'
    : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
    ? 'AVAX'
    : chainId === ChainId.FANTOM
    ? 'FTM'
    : 'ETH'
}

export const getNativeSymbol = (chainId: ChainId | undefined) => {
  return chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
    ? 'BNB'
    : chainId === ChainId.MUMBAI_TESTNET
    ? 'MATIC'
    : chainId === ChainId.POLYGON
    ? 'POL'
    : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
    ? 'AVAX'
    : chainId === ChainId.FANTOM
    ? 'FTM'
    : 'ETH'
}

export const getWrappedNativeSymbol = (chainId: ChainId | undefined) => {
  return chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
    ? 'WBNB'
    : chainId === ChainId.MUMBAI_TESTNET
    ? 'WMATIC'
    : chainId === ChainId.POLYGON
    ? 'WPOL'
    : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
    ? 'WAVAX'
    : chainId === ChainId.FANTOM
    ? 'WFTM'
    : 'WETH'
}

export const getChainType = (chainId: ChainId | undefined) => {
  return chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
    ? 'BEP20'
    : chainId === ChainId.MUMBAI_TESTNET || chainId === ChainId.POLYGON
    ? 'Polygon'
    : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
    ? 'Avalanche'
    : chainId === ChainId.FANTOM
    ? 'Fantom'
    : 'ERC20'
}
