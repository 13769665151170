import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  SEPOLIA_TESTNET = 11155111,
  MUMBAI_TESTNET = 80002,
  POLYGON = 137,
  ARB_TESTNET = 421614,
  AVALANCHE_FUJI = 43113,
  BASE_SEPOLIA = 84532,
  ARB_MAINNET = 42161,
  AVALANCHE_C = 43114,
  BASE_MAINNET = 8453,
  LINEA = 59144,
  FANTOM = 250,
  OPTIMISM = 10,
  BLAST = 81457
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

type ChainAddress = {
  [chainId in ChainId]: string
}

export const FACTORY_ADDRESS: ChainAddress = {
  [ChainId.MAINNET]: '0x7F09d4bE6bbF4b0fF0C97ca5c486a166198aEAeE',
  [ChainId.ROPSTEN]: '0xDfD8bbA37423950bD8050C65E698610C57E55cea',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0x505751023083BfC7D2AFB0588717dCC6182e54B2',
  [ChainId.BSC_TESTNET]: '0x4f5c641ee8f06a64d5ba792aedab502a73878c22',
  [ChainId.SEPOLIA_TESTNET]: '0xb33Db820e58E38b3f8E0C812e957AAE6Df268942',
  [ChainId.MUMBAI_TESTNET]: '0xB9AA9e8d78e905D3e6e331aC67823d9aAc9b9cE7',
  [ChainId.POLYGON]: '0x8a00a21f2f654c4545a0272888377929dc3df4df',
  [ChainId.ARB_TESTNET]: '0x431b62bD94EC4480B6A7E44EE783A7F8c791ca2B',
  [ChainId.AVALANCHE_FUJI]: '0x5c54F1ccAA4Bbe812D11C911E4ad42A653374C9B',
  [ChainId.BASE_SEPOLIA]: '0x431b62bD94EC4480B6A7E44EE783A7F8c791ca2B',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export const INIT_CODE_HASH: ChainAddress = {
  [ChainId.MAINNET]: '0xaf22d00bd30ebd0976c262e993c9d8df7423973a794d1d429c2322f0dc80e3ed',
  [ChainId.ROPSTEN]: '0x8bc8f8336dcfcba44096a139671d89637695b1be1cf88aad5d7de56ae35b8bfd',
  [ChainId.RINKEBY]: '',
  [ChainId.GÖRLI]: '',
  [ChainId.KOVAN]: '',
  [ChainId.BSC_MAINNET]: '0xc5c0f41407c4baa59af66c427d16b88ec7c38d2a749bd6347c223ef8b0abb7cf',
  [ChainId.BSC_TESTNET]: '0xf93833d8e0bd369793bc7cbd2f15916f6f1d8b68461cacf8189314b42aa10355',
  [ChainId.SEPOLIA_TESTNET]: '0xb69ee2b7d5af3ba458c87da5ac5c6729a2604bbf919b04f9dbde4e824eb5dd96',
  [ChainId.MUMBAI_TESTNET]: '0xf93833d8e0bd369793bc7cbd2f15916f6f1d8b68461cacf8189314b42aa10355',
  [ChainId.POLYGON]: '0xaf22d00bd30ebd0976c262e993c9d8df7423973a794d1d429c2322f0dc80e3ed',
  [ChainId.ARB_TESTNET]: '0xdf487d22c43efaf01de2278d5d4b023e9e64422b3d2e7d0dfabd857c058f325f',
  [ChainId.AVALANCHE_FUJI]: '0xa726b8b71d99ed4cdb5390aff9456ce7b8a7ffd6ed2f408e3e43a883ed9afe26',
  [ChainId.BASE_SEPOLIA]: '0xa726b8b71d99ed4cdb5390aff9456ce7b8a7ffd6ed2f408e3e43a883ed9afe26',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _9975 = JSBI.BigInt(9975)
export const _10000 = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
