import { ChainId, Currency, Token, currencyEquals } from '@safemoon/sdk'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import CurrencyLogo from '../CurrencyLogo'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { getNativeSymbol } from '../../utils/getTokenSymbol'

const CurrencyItem = ({
  token,
  selectedCurrency,
  onCurrencySelect,
  chainId
}: {
  token: Currency | Token
  selectedCurrency: Currency
  onCurrencySelect: (currency: Currency) => void
  chainId: number
}) => {
  const isSelected = Boolean(selectedCurrency && currencyEquals(token, selectedCurrency))
  return (
    <div
      className={classNames(
        'px-[12px] cursor-pointer inline-flex items-center',
        isSelected && 'opacity-[0.5] pointer-events-none'
      )}
      onClick={() => onCurrencySelect(token)}
    >
      <CurrencyLogo currency={token} size={'24px'} style={{ marginRight: '8px' }} />
      <p className="text-[16px] font-bold main-text">
        {token?.symbol !== 'ETH' || (token as Token)?.address ? token?.symbol : getNativeSymbol(chainId)}
      </p>
    </div>
  )
}

const FixedCurrencies = ({
  tokens,
  onCurrencySelect,
  selectedCurrency,
  chainId
}: {
  tokens: Currency[]
  selectedCurrency: Currency
  onCurrencySelect: (currency: Currency) => void
  chainId: number
}) => {
  const tokensByChainId = useMemo(
    () => tokens.filter((item: WrappedTokenInfo) => !item.chainId || item.chainId === chainId),
    [tokens, chainId]
  )
  return (
    <div className="h-[66px] pl-[8px] py-[20px] w-full bg-gray border-b-[2px] border-dark overflow-x-auto overflow-y-hidden	whitespace-nowrap">
      {tokensByChainId?.map(token => (
        <CurrencyItem
          token={token}
          selectedCurrency={selectedCurrency}
          onCurrencySelect={onCurrencySelect}
          key={token?.symbol}
          chainId={chainId}
        />
      ))}
    </div>
  )
}

export default FixedCurrencies
