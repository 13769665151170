import { Token } from '@safemoon/sdk'
import React, { useState } from 'react'
import styled from 'styled-components'

import { WrappedTokenInfo } from '../../state/lists/hooks'

const getTokenLogoURL = symbol => {
  return `/images/${symbol}.svg`
}
const BAD_URIS: { [tokenAddress: string]: true } = {}

const Image = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 1rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const Emoji = styled.span<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ size }) => size};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  margin-bottom: -4px;
`

export default function CurrencyLogoAdded({
  symbol,
  size = '24px',
  ...rest
}: {
  symbol?: string
  size?: string
  style?: React.CSSProperties
  className?: any
}) {
  const [error, setError] = useState<boolean>(false)

  const uri = getTokenLogoURL(symbol)

  if (uri && !error) {
    return (
      <Image
        {...rest}
        alt={`${symbol} Logo`}
        src={uri}
        size={size}
        onError={() => {
          setError(true)
        }}
      />
    )
  }

  return (
    <Emoji {...rest} size={size}>
      <span role="img" aria-label="Thinking" style={{ fontSize: 16 }}>
        🤔
      </span>
    </Emoji>
  )
}
