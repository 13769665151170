import { Currency } from '@safemoon/sdk'
import React, { useState, useContext, useCallback, useMemo } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { darken } from 'polished'
import Dotdotdot from 'react-dotdotdot'
// import { useCurrencyBalance } from '../../../state/wallet/hooks'
import CurrencySearchModal from './CurrencySearchModal'
import { RowBetween } from '../../../components/Row'
import { TYPE } from '../../../theme'
import { Input as NumericalInput } from '../../../components/NumericalInput'
import { ReactComponent as DropDown } from '../../../assets/images/dropdown.svg'

import { useActiveWeb3React } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { BirdgeChain } from '../../../constants'
import { convertObjectToSearchParams, formatStringToNumber, formatStringToPrice, routingNumber } from '../../../utils'
import { useHistory } from 'react-router'
import useParsedQueryString from '../../../hooks/useParsedQueryString'

const InputRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  padding: 5px;
  border: 0;
  height: 50px;
  border-radius: 30px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.bgDark};
`

const InputContainer = styled.div`
  padding: 2px 2px 2px 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 280px;
`

const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.bgMain};
  color: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
  border-radius: 20px;
  box-shadow: ${({ selected }) => (selected ? 'none' : '0px 6px 10px rgba(0, 0, 0, 0.075)')};
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0 0.5rem;
  min-width: 160px;
  transition: 0.3s ease all;

  :focus,
  :hover {
    background-color: ${({ theme }) => theme.bg2};
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 115px;
  `};
`

const CurrencyInput = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
`

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.text3};
  font-size: 0.875rem;
  line-height: 1rem;
  height: 24px;

  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.text2)};
  }
`

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledDropDown = styled(DropDown)<{ selected: boolean }>`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) => (selected ? theme.text1 : theme.white)};
    stroke-width: 1.5px;
  }
`

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  z-index: 1;
`

const Container = styled.div<{ hideInput: boolean }>``

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) => (active ? '  margin: 0 0 0 0' : '  margin: 0 0.25rem 0 0.25rem;')}
  font-size: 16px;
  font-weight: 700;
  color: #edf7f6;
`

const StyledBalanceMax = styled.button`
  height: 40px;
  background-color: ${({ theme }) => theme.bgMain};
  border-radius: 20px;
  font-size: 0.875rem;
  padding: 0.5rem 1.5rem;
  font-weight: 500;

  cursor: pointer;
  color: ${({ theme }) => theme.text1};
  transition: 0.3s ease all;

  :hover {
    background-color: ${({ theme }) => theme.bg4};
  }
  :focus {
    background-color: ${({ theme }) => theme.bg2};
    outline: none;
  }

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0.25rem 1rem;
  `};
`

interface CurrencyInputPanelProps {
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  hideInput?: boolean
  showSendWithSwap?: boolean
  otherChain?: any
  id: string
  priceUsd?: any
  chain: {
    chain: BirdgeChain | null
    value: string
  }
  handleSelectChain: (chain: BirdgeChain) => void
  balances: any
  showBalance: any
  showSFMBridge?: boolean
}

export default function CurrencyInputPanel({
  onUserInput,
  onMax,
  showMaxButton,
  label = 'Input',
  currency = null,
  disableCurrencySelect = false,
  hideBalance = false,
  hideInput = false,
  otherChain = null,
  id,
  chain,
  handleSelectChain,
  balances,
  priceUsd,
  showBalance,
  showSFMBridge
}: CurrencyInputPanelProps) {
  const { account } = useActiveWeb3React()
  const { t } = useTranslation()
  const [modalOpen, setModalOpen] = useState(false)
  const theme = useContext(ThemeContext)

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const tokenValueUsd = useMemo(() => {
    return +(chain?.value || 0) * +(priceUsd[chain?.chain?.tokenAddress?.toLowerCase()] || 0)
  }, [chain, priceUsd])

  const history = useHistory()
  const query = useParsedQueryString()

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <div className="flex items-center">
                <TYPE.body color={theme.textLabel} fontWeight={400} fontSize={16}>
                  {label}
                </TYPE.body>
                {chain?.chain?.chainName && (
                  <p className="text-[16px] leading-[18px] mb-[0] ml-[4px]" style={{ color: chain?.chain?.color }}>
                    {chain?.chain?.shortChain}
                  </p>
                )}
              </div>

              {account && (
                <TYPE.body
                  onClick={() => {
                    history.push(
                      `/bridge${convertObjectToSearchParams({
                        ...query,
                        showBalance: showBalance === 'true' ? 'false' : 'true'
                      })}`
                    )
                  }}
                  color={theme.textLabel}
                  fontWeight={400}
                  fontSize={16}
                  style={{ display: 'inline', cursor: 'pointer', textAlign: 'right' }}
                >
                  {!hideBalance && balances[chain?.chain?.id]
                    ? `Balance: ${
                        showBalance === 'false' ? '****' : formatStringToNumber(balances[chain?.chain?.id], 6)
                      }`
                    : ' -'}
                </TYPE.body>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}}>
          <CurrencySelect
            selected={!!currency}
            className="open-currency-select-button"
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Aligner>
              <CurrencyInput>
                {chain?.chain?.images ? (
                  <div className="flex items-center mr-[5px]">
                    {chain?.chain?.images.map((item, i) => (
                      <img src={item} key={i} className="w-[24px] mr-[5px]" alt="icon" />
                    ))}
                  </div>
                ) : null}
                <StyledTokenName className="token-symbol-container" active={Boolean(chain?.chain?.chain)}>
                  {chain?.chain?.chain ? chain?.chain?.symbol : t('selectChain')}
                </StyledTokenName>
              </CurrencyInput>
              {!disableCurrencySelect && <StyledDropDown selected={!!currency} />}
            </Aligner>
          </CurrencySelect>
          {!hideInput && (
            <InputContainer>
              <NumericalInput
                className="token-amount-input text-right mr-[10px]"
                value={chain?.value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
              {account && showMaxButton && label !== 'To' && (
                <StyledBalanceMax
                  onClick={() => {
                    onUserInput(balances[chain?.chain?.id])
                  }}
                >
                  Max
                </StyledBalanceMax>
              )}
            </InputContainer>
          )}
        </InputRow>
        <div className="flex items-center justify-between">
          {chain?.chain ? (
            <p className="text-[16px] leading-[18px] mt-[10px] label-text2">
              Price: ${formatStringToPrice(priceUsd[chain?.chain?.tokenAddress?.toLowerCase()])}
            </p>
          ) : (
            <p className="text-[16px] leading-[18px] mt-[10px] text-white">&nbsp;</p>
          )}

          {tokenValueUsd > 0 && (
            <div className="flex-1 pl-[16px]">
              <Dotdotdot clamp={1} truncationChar="...+">
                <p className="text-[16px] leading-[18px] mt-[10px] label-text2 text-right">
                  <b>&#8764;</b> $
                  {tokenValueUsd > 0.01 ? routingNumber(tokenValueUsd, 2) : routingNumber(tokenValueUsd, 8)}
                </p>
              </Dotdotdot>
            </div>
          )}
        </div>
      </Container>
      {!disableCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          handleSelectChain={handleSelectChain}
          otherChain={otherChain}
          chain={chain}
          balances={balances}
          id={id}
          showSFMBridge={showSFMBridge}
        />
      )}
    </InputPanel>
  )
}
