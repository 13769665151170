import React, { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import { ButtonGray, ButtonPrimary, ButtonSecondary } from '../../components/Button'
import { useHistory } from 'react-router'
import axios from 'axios'
import { CHECK_USA_LOCATION } from '../../constants'

const LPFWarningPopup = () => {
  const [showUsPopup, setShowUsPopup] = useState(false)

  const [showConfirmPopup, setShowConfirmPopup] = useState(false)

  const history = useHistory()

  useEffect(() => {
    const checkUsaLocation = async () => {
      try {
        const result = await axios.get(CHECK_USA_LOCATION)
        if (result?.data?.isUsa) {
          setShowUsPopup(true)
        } else if (!localStorage.getItem('IS_CONFIRM_LOCATION')) {
          setShowConfirmPopup(true)
        }
      } catch (e) {
        console.log(e)
      }
    }

    const isConfirmLocation = localStorage.getItem('IS_CONFIRM_LOCATION')
    if (isConfirmLocation) {
      checkUsaLocation()
    }
  }, [])

  return (
    <>
      <Modal isOpen={showUsPopup} maxHeight={100}>
        <div className="w-full pt-[48px] pb-[36px] px-[30px]">
          <div className="flex justify-center mb-[8px]">
            <img src={'/images/danger.svg'} className="w-[58px]" alt="danger" />
          </div>

          <h2 className="text-[22px] font-bold text-white text-center mb-[0]">Important</h2>
          <p className="text-[20px] leading-[30px] text-white mt-[42px] mb-[8px] text-center">
            Based on your IP address, we currently do not provide services in your country or region due to local laws,
            regulations, or policies. We apologize for any inconvenience this may cause.
          </p>

          <p className="text-[20px] text-white mb-[42px] text-center">
            View <a className="link">Privacy Policy</a> and <a className="link">Terms of Use</a>
          </p>

          <ButtonPrimary
            onClick={() => {
              history.push('/swap')
            }}
          >
            OK
          </ButtonPrimary>
        </div>
      </Modal>

      <Modal isOpen={showConfirmPopup} maxHeight={100}>
        <div className="w-full pt-[48px] pb-[36px] px-[30px]">
          <div className="flex justify-center mb-[8px]">
            <img src={'/images/danger.svg'} className="w-[58px]" alt="danger" />
          </div>

          <h2 className="text-[22px] font-bold text-white text-center mb-[0]">Important</h2>
          <p className="text-[20px] leading-[30px] text-white my-[42px] text-center">
            Do you confirm you are not a US citizen?
          </p>

          <div className="flex items-center">
            <ButtonGray
              onClick={() => {
                history.push('/swap')
              }}
              marginRight={'8px'}
            >
              No
            </ButtonGray>
            <ButtonPrimary
              onClick={() => {
                localStorage.setItem('IS_CONFIRM_LOCATION', 'true')
                setShowConfirmPopup(false)
              }}
              marginLeft={'8px'}
            >
              Yes
            </ButtonPrimary>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LPFWarningPopup
