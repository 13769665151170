import React, { useEffect, useState } from 'react'
import { Field, Form, localForm } from '../../Form'
import InputField from '../../Form/InputField/InputField'
// import { useTokenAllowance } from '../../data/Allowances'
// import { useToken } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
// import { LPF_ADDRESS } from '../../constants'
import { useLPFContract } from '../../hooks/useContract'
import { ButtonPrimary } from '../../components/Button'
import { parseUnits } from 'ethers/lib/utils'
import { CustomLightSpinner } from '../../components/TransactionConfirmationModal'
import Circle from '../../assets/images/blue-loader.svg'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import { displayAddress, fromWei, getEtherscanLink, shortenAddress } from '../../utils'
import QuestionHelper from '../../components/QuestionHelper'
import { useTransactionAdder } from '../../state/transactions/hooks'
import { getNativeSymbol } from '../../utils/getTokenSymbol'
import CurrencyLogo from '../../components/CurrencyLogo'
import { ETHER } from '@safemoon/sdk'
import CurrencyLogoAdded from '../../components/CurrencyLogo/CurrencyLogoAdded'

const ClaimReward = ({
  handleClose,
  selectedItem,
  updateProperty,
  values,
  handleTouched,
  initialValues,
  submitForm,
  getLpsPriceRangeInfo,
  chainId
}: any) => {
  const [loading, setLoading] = useState(false)

  const addTransaction = useTransactionAdder()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    setTimeout(() => {
      initialValues({
        pairAddress: selectedItem.tokens,
        maxRewards: selectedItem.rewards / 10 ** 18,
        amount: `${fromWei({ val: selectedItem.rewards, decimals: 18 })}`
      })
    }, 300)
  }, [selectedItem])

  const LPF = useLPFContract()

  const handleClaim = async values => {
    try {
      setLoading(true)

      const amount = parseUnits(values.amount, 'ether').toString()

      if (selectedItem?.pairAddress) {
        await LPF.claimFromPair(selectedItem?.pairAddress, amount)
          .then(receipt => {
            addTransaction(
              {
                chainId,
                hash: receipt.hash,
                from: account
              } as any,
              {
                summary: `Claim reward from pair: ${shortenAddress(selectedItem?.pairAddress)} with amount ${
                  values.amount
                } ${getNativeSymbol(chainId)}`
              }
            )

            receipt.wait().then(result => {
              getLpsPriceRangeInfo()
              setLoading(false)
              toast('Amount Claimed Successfully', {
                type: 'success',
                theme: 'dark'
              })
              handleClose()
              addTransaction(
                { ...result, hash: result.hash || result.transactionHash },
                {
                  summary: `Claim reward from pair: ${shortenAddress(selectedItem?.pairAddress)} with amount ${
                    values.amount
                  } ${getNativeSymbol(chainId)}`
                }
              )
            })
          })
          .catch((e: any) => {
            console.log('error', e.message)
            setLoading(false)
            let message = ''
            if (e?.code === 4001) {
              message = 'User Rejected the transaction'
            } else {
              message = e.data?.message || 'Some thing went wrong, please try again later'
            }
            toast(message, {
              type: 'error',
              theme: 'dark'
            })
          })
      } else {
        await LPF.claim(amount)
          .then(receipt => {
            addTransaction(
              {
                chainId,
                hash: receipt.hash,
                from: account
              } as any,
              {
                summary: `Claim all rewards with amount ${values.amount} ${getNativeSymbol(chainId)}`
              }
            )

            receipt.wait().then(result => {
              getLpsPriceRangeInfo()
              setLoading(false)
              toast('Amount Claimed Successfully', {
                type: 'success',
                theme: 'dark'
              })
              handleClose()
              addTransaction(
                { ...result, hash: result.hash || result.transactionHash },
                {
                  summary: `Claim all rewards with amount ${values.amount} ${getNativeSymbol(chainId)}`
                }
              )
            })
          })
          .catch((e: any) => {
            console.log('error', e.message)
            setLoading(false)
            let message = ''
            if (e?.code === 4001) {
              message = 'User Rejected the transaction'
            } else {
              message = e.data?.message || 'Some thing went wrong, please try again later'
            }
            toast(message, {
              type: 'error',
              theme: 'dark'
            })
          })
      }
    } catch (e) {
      setLoading(false)
    }
  }

  return (
    <div className="w-full">
      <div className="px-[20px] py-[12px] relative">
        <h2 className={'text-[24px] font-bold text-center'}>
          {selectedItem?.pairAddress ? 'Claim Reward' : 'Claim All Rewards'}
        </h2>
        <a className="cursor-pointer absolute left-0 top-[50%] translate-y-[-50%] p-[20px]" onClick={handleClose}>
          <img src={'/images/left.svg'} className="w-[24px]" />
        </a>
      </div>

      <Form className="p-[20px]" onSubmit={submitForm(handleClaim, true)}>
        {selectedItem?.pairAddress && (
          <div>
            <div className="flex items-center justify-between">
              <p className="text-[16px] label-text2 mb-[10px]">Pair</p>
              <QuestionHelper text={`You have selected to claim rewards from your ${selectedItem.tokens} pair`} />
            </div>
            <a
              className="row items-center mb-[8px] rounded-full bg-black !h-[50px] px-[12px]"
              href={`${getEtherscanLink(chainId, selectedItem.pairAddress, 'address')}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="flex items-center flex-1">
                <span className="relative mr-[22px]">
                  <span className="w-[22px] h-[22px] rounded-full bg-gray flex items-center justify-center relative z-[2]">
                    {selectedItem.logo1 ? (
                      <img src={selectedItem.logo1} className="h-[22px] " />
                    ) : selectedItem.token0 === 'WETH' ||
                      selectedItem.token0 === 'WBNB' ||
                      selectedItem.token0 === 'WPOL' ||
                      selectedItem.token0 === 'WMATIC' ? (
                      <CurrencyLogo currency={ETHER} size="20px" />
                    ) : (
                      <CurrencyLogoAdded symbol={selectedItem.token0} size="20px" />
                    )}
                  </span>
                  <span className="w-[22px] h-[22px] rounded-full bg-gray flex items-center justify-center ml-[-8px] absolute top-0 right-[-16px] z-[1]">
                    {selectedItem.logo2 ? (
                      <img src={selectedItem.logo2} className="h-[22px] " />
                    ) : selectedItem.token1 === 'WETH' ||
                      selectedItem.token1 === 'WBNB' ||
                      selectedItem.token1 === 'WPOL' ||
                      selectedItem.token1 === 'WMATIC' ? (
                      <CurrencyLogo currency={ETHER} size="20px" />
                    ) : (
                      <CurrencyLogoAdded symbol={selectedItem.token1} size="20px" />
                    )}
                  </span>
                </span>

                <span className="text-[16px] text-white font-bold">{selectedItem.tokens}</span>
              </span>
              <span className="text-[16px] gray-text">{displayAddress(selectedItem.pairAddress)}</span>
            </a>
          </div>
        )}

        <Field
          name="amount"
          value={values.amount}
          updateProperty={updateProperty}
          handleTouched={handleTouched}
          component={InputField}
          label={
            selectedItem?.pairAddress ? (
              'Amount to claim'
            ) : (
              <div className="flex items-center">
                Amount to claim in (<CurrencyLogo currency={ETHER} size="16px" className="mr-[8px]" />{' '}
                {getNativeSymbol(chainId)})
              </div>
            )
          }
          placeholder="Amount To Claim"
          description={
            selectedItem?.pairAddress
              ? 'Amount of native coin you have available to collect from this pair'
              : 'Total amount of rewards available from all your pools combined.'
          }
        />

        <ButtonPrimary type="submit" className={classNames(loading && 'pointer-events-none')}>
          Claim Reward
          {loading && <CustomLightSpinner src={Circle} alt="loader" size={'20px'} className="ml-[10px]" />}
        </ButtonPrimary>
      </Form>
    </div>
  )
}

const validate = values => {
  const errors: any = {}
  if (!values.amount) {
    errors.amount = 'Please enter transfer amount'
  } else if (+values.amount <= 0) {
    errors.amount = 'Please enter valid amount > 0'
  } else if (+values.amount > +values.maxRewards) {
    errors.amount = `Please enter amount < ${values.maxRewards}`
  }

  return errors
}

export default localForm({
  validate,
  MyComponent: ClaimReward
})
