/* eslint-disable */
import { ChainId } from '@safemoon/sdk'
import React from 'react'
import closeIcon from '../../assets/images/close.svg'
import Modal from '../../components/Modal'
import { walletconnectBSC, walletconnect, walletconnectPolygon } from '../../connectors'
import { useActiveWeb3React } from '../../hooks'

interface Props {
  show: boolean
  handleClose: any
  chainId: ChainId
}

const ChainWarning = ({ show, handleClose, chainId }: Props) => {
  const { connector, activate } = useActiveWeb3React()
  return (
    <Modal isOpen={show} onDismiss={handleClose} forceMaxHeight={'70vh'} maxHeight={70}>
      <a
        className={'btn-close'}
        onClick={() => {
          handleClose()
        }}
      >
        <img className={'close-icon'} src={closeIcon} alt="closeIcon" />
      </a>
      <div className="consolidate-intro mt-[40px]">
        <h2 className="text-[20px] font-bold link-color mb-[20px] text-center">Change network</h2>
        <p className="consolidate-intro-description">
          Please disconnect your wallet and switch to the correct chain for this transaction.
        </p>

        <a
          className="btn btnMain"
          onClick={() => {
            ;(connector as any)?.close()
            setTimeout(() => {
              activate(
                chainId === ChainId.BSC_MAINNET
                  ? walletconnectBSC
                  : chainId === ChainId.MAINNET
                  ? walletconnect
                  : walletconnectPolygon
              )
            }, 1000)

            handleClose()
          }}
        >
          Disconnect
        </a>
      </div>
    </Modal>
  )
}

export default ChainWarning
