import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import ISafeswapRouterABI from '../constants/abis/ISafeswapRouter02.json'
import ISafeswapRouterETHABI from '../constants/abis/ISafeswapRouterETH.json'
import ISafeSwapTradeABI from '../constants/abis/ISafeSwapTradeABI.json'
import ILPFABI from '../constants/abis/ILPF.json'
import { CHAIN_TO_METAMASK, HEX_CHAINS, LPF_ADDRESS, ROUTER_ADDRESS } from '../constants'
import { TRADE_SWAP_ROUTER_ADDRESS } from '../constants'
import { ChainId, Currency, CurrencyAmount, ETHER, JSBI, Percent, Token } from '@safemoon/sdk'
import { TokenAddressMap } from '../state/lists/hooks'
import Web3 from 'web3'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'etherscan.io',
  3: 'ropsten.etherscan.io',
  4: 'rinkeby.etherscan.io',
  5: 'goerli.etherscan.io',
  42: 'kovan.etherscan.io',
  [ChainId.BSC_MAINNET]: 'bscscan.com',
  [ChainId.BSC_TESTNET]: 'testnet.bscscan.com',
  [ChainId.SEPOLIA_TESTNET]: 'sepolia.etherscan.io',
  [ChainId.MUMBAI_TESTNET]: 'amoy.polygonscan.com',
  [ChainId.POLYGON]: 'polygonscan.com',
  [ChainId.ARB_TESTNET]: 'sepolia.arbiscan.io',
  [ChainId.AVALANCHE_FUJI]: 'testnet.snowtrace.io',
  [ChainId.BASE_SEPOLIA]: 'sepolia.basescan.org',
  [ChainId.ARB_MAINNET]: 'arbiscan.io',
  [ChainId.AVALANCHE_C]: 'snowtrace.io',
  [ChainId.BASE_MAINNET]: 'basescan.org',
  [ChainId.LINEA]: 'lineascan.build',
  [ChainId.FANTOM]: 'ftmscan.com',
  [ChainId.OPTIMISM]: 'optimistic.etherscan.io',
  [ChainId.BLAST]: 'blastscan.io'
}

const ANALYSIS_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: '',
  4: '',
  5: '',
  42: '',
  [ChainId.BSC_MAINNET]: '',
  [ChainId.BSC_TESTNET]: 'testnet.',
  [ChainId.SEPOLIA_TESTNET]: '',
  [ChainId.MUMBAI_TESTNET]: '',
  [ChainId.POLYGON]: '',
  [ChainId.ARB_TESTNET]: '',
  [ChainId.AVALANCHE_FUJI]: '',
  [ChainId.BASE_SEPOLIA]: '',
  [ChainId.ARB_MAINNET]: '',
  [ChainId.AVALANCHE_C]: '',
  [ChainId.BASE_MAINNET]: '',
  [ChainId.LINEA]: '',
  [ChainId.FANTOM]: '',
  [ChainId.OPTIMISM]: '',
  [ChainId.BLAST]: ''
}

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'home'
): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId] || ETHERSCAN_PREFIXES[1]}`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'home':
      return `${prefix}}`
    case 'address':
    default:
      return `${prefix}/address/${data}`
  }
}

export function getAnalysisLink(chainId: ChainId): string {
  return `https://${ANALYSIS_PREFIXES[chainId] || ANALYSIS_PREFIXES[1]}bscswap.info`
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)), // Amount IN
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)) // Amount Min Out
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function getLPFContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(LPF_ADDRESS[_ || ChainId.BSC_TESTNET], ILPFABI, library, account)
}

// account is optional
export function getRouterContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[_ || ChainId.BSC_TESTNET], ISafeswapRouterABI.abi, library, account)
}

export function getRouterETHContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(ROUTER_ADDRESS[_ || ChainId.BSC_TESTNET], ISafeswapRouterETHABI.abi, library, account)
}

export function getTradeSwapContract(_: ChainId, library: Web3Provider, account?: string): Contract {
  return getContract(TRADE_SWAP_ROUTER_ADDRESS[_ || ChainId.BSC_TESTNET], ISafeSwapTradeABI, library, account)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isDefaultToken(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens?.[currency.chainId]?.[currency.address])
}

export function getExplore(chainId: number) {
  return chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
    ? 'BscScan'
    : chainId === ChainId.MUMBAI_TESTNET || chainId === ChainId.POLYGON
    ? 'Polygonscan'
    : chainId === ChainId.ARB_TESTNET || chainId === ChainId.ARB_MAINNET
    ? 'Arbiscan'
    : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
    ? 'Snowtrace'
    : chainId === ChainId.BASE_SEPOLIA || chainId === ChainId.BASE_MAINNET
    ? 'Basescan'
    : chainId === ChainId.LINEA
    ? 'Lineascan'
    : chainId === ChainId.FANTOM
    ? 'Ftmscan'
    : chainId === ChainId.OPTIMISM
    ? 'Optimistic'
    : chainId === ChainId.BLAST
    ? 'Blastscan'
    : 'Etherscan'
}

export function getChainName(chainId: number) {
  return chainId === ChainId.BSC_TESTNET || chainId === ChainId.BSC_MAINNET
    ? 'BNB Smart Chain'
    : chainId === ChainId.MUMBAI_TESTNET || chainId === ChainId.POLYGON
    ? 'Polygon'
    : chainId === ChainId.ARB_TESTNET || chainId === ChainId.ARB_MAINNET
    ? 'Arbitrum'
    : chainId === ChainId.AVALANCHE_FUJI || chainId === ChainId.AVALANCHE_C
    ? 'Avalanche'
    : chainId === ChainId.BASE_SEPOLIA || chainId === ChainId.BASE_MAINNET
    ? 'Base'
    : chainId === ChainId.LINEA
    ? 'Linea'
    : chainId === ChainId.FANTOM
    ? 'Fantom'
    : chainId === ChainId.OPTIMISM
    ? 'Optimism'
    : chainId === ChainId.BLAST
    ? 'Blast'
    : 'Ethereum'
}

export function displayTokenPrice(value: any) {
  if (!value && value !== 0) {
    return '-'
  }
  if (+value > 0.9) {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })

    return formatter.format(value).replace(/,/g, ',')
  } else {
    if (`${value}`.length <= 11) {
      return value
    } else {
      const m = -Math.floor(Math.log10(+value) + 1)
      const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: Math.max(m + 2, 9)
      })
      return formatter.format(value).replace(/,/g, ',')
    }
  }
}

export function routingNumber(value: any, maximumFractionDigits: number) {
  if (!value && value !== 0) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: maximumFractionDigits || 2
  })

  return formatter.format(value).replace(/,/g, ',')
}

export function formatStringToNumber(value: any, maximumFractionDigits = 2) {
  if (!value && value !== 0) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits
  })

  return formatter.format(value).replace(/,/g, ',')
}

export function formatStringToPrice(value: any) {
  if (!value && value !== 0) {
    return '-'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: +value > 0.01 ? 2 : 8
  })

  return formatter.format(value).replace(/,/g, ',')
}

export function isStableCoin(symbol: string | undefined) {
  return symbol === 'BUSD' || symbol === 'USDC' || symbol === 'USDT'
}

export const DECIMALS_TO_UNIT: any = {
  9: 'nano',
  12: 'szabo',
  18: 'ether',
  6: 'picoether'
}

const convertNumber = (val: any, maximumFractionDigits = 0) =>
  val ? formatStringToNumber(val, maximumFractionDigits).replace(/,/g, '') : 0

export const toWei = ({ val, decimals }: { val: number | string; decimals: number }) => {
  return convertNumber(
    DECIMALS_TO_UNIT[decimals]
      ? Web3.utils.toWei(`${convertNumber(val, decimals)}`, DECIMALS_TO_UNIT[decimals])
      : +val * 10 ** decimals,
    0
  )
}

export const fromWei = ({ val, decimals }: any) => {
  return convertNumber(
    DECIMALS_TO_UNIT[decimals] ? Web3.utils.fromWei(val || '0', DECIMALS_TO_UNIT[decimals]) : `${val / 10 ** decimals}`,
    20
  )
}

export const convertObjectToSearchParams = values => {
  if (values) {
    // console.log(values)
    const search = Object.entries(values)
      .filter(entry => entry[1])
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
    return `?${search}`
  }
  return ''
}

export const displayAddress = (address: string) =>
  address?.length > 18 ? `${address.slice(0, 9)}...${address.slice(address.length - 9, address.length)}` : address

export async function handleSwitchChainInjected(chainId: ChainId) {
  try {
    await (window.ethereum as any)?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: HEX_CHAINS[chainId || ''] }] // chainId must be in hexadecimal numbers
    })
  } catch (e) {
    if ((e as any)?.code === 4902 && CHAIN_TO_METAMASK[chainId || '']) {
      // console.log(CHAIN_TO_METAMASK[chain])\
      await (window.ethereum as any)?.request({
        method: 'wallet_addEthereumChain',
        params: [CHAIN_TO_METAMASK[chainId || '']] // chainId must be in hexadecimal numbers
      })
    }
  }
}
