/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ChainId, Token } from '@safemoon/sdk'
import { TokenInfo, TokenList } from '@uniswap/token-lists'
import { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DEFAULT_TOKEN_LIST_URL } from '../../constants'
import { AppState, AppDispatch } from '../index'
import { updateListPairs } from './actions'
import { useActiveWeb3React } from '../../hooks'

/**
 * Token instances created from token info.
 */
export class WrappedTokenInfo extends Token {
  public readonly tokenInfo: TokenInfo
  constructor(tokenInfo: TokenInfo) {
    super(tokenInfo.chainId, tokenInfo.address, tokenInfo.decimals, tokenInfo.symbol, tokenInfo.name)
    this.tokenInfo = tokenInfo
  }
  public get logoURI(): string | undefined {
    return this.tokenInfo.logoURI
  }
}

export type TokenAddressMap = Readonly<{ [chainId in ChainId]: Readonly<{ [tokenAddress: string]: WrappedTokenInfo }> }>

/**
 * An empty result, useful as a default.
 */
const EMPTY_LIST: TokenAddressMap = {
  [ChainId.BSC_TESTNET]: {},
  [ChainId.BSC_MAINNET]: {},
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {},
  [ChainId.MAINNET]: {},
  [ChainId.SEPOLIA_TESTNET]: {},
  [ChainId.MUMBAI_TESTNET]: {},
  [ChainId.POLYGON]: {},
  [ChainId.ARB_TESTNET]: {},
  [ChainId.AVALANCHE_FUJI]: {},
  [ChainId.BASE_SEPOLIA]: {},
  [ChainId.ARB_MAINNET]: {},
  [ChainId.AVALANCHE_C]: {},
  [ChainId.BASE_MAINNET]: {},
  [ChainId.LINEA]: {},
  [ChainId.FANTOM]: {},
  [ChainId.OPTIMISM]: {},
  [ChainId.BLAST]: {}
}

const listCache: WeakMap<TokenList, TokenAddressMap> | null =
  'WeakMap' in window ? new WeakMap<TokenList, TokenAddressMap>() : null

export function listToTokenMap(list: TokenList): TokenAddressMap {
  const result = listCache?.get(list)
  if (result) return result

  const map = list?.tokens?.reduce<TokenAddressMap>(
    (tokenMap, tokenInfo) => {
      // console.log(tokenMap)
      const token = new WrappedTokenInfo(tokenInfo)
      if (tokenMap?.[token.chainId] && tokenMap?.[token.chainId]?.[token.address] !== undefined) {
        console.log('dupplicate', token)
      }
      return {
        ...tokenMap,
        [token.chainId]: {
          ...tokenMap[token.chainId],
          [token.address]: token
        }
      }
    },
    { ...EMPTY_LIST }
  )
  listCache?.set(list, map)
  return map
}

export function useUpdateListPairs(): { pairs: any; setPairs: (pairs: any) => void } {
  const dispatch = useDispatch<AppDispatch>()
  const pairs = useSelector<AppState, AppState['lists']['pairs']>(state => {
    return state.lists.pairs
  })

  const setPairs = useCallback(
    (pairs: any) => {
      dispatch(updateListPairs({ pairs }))
    },
    [dispatch]
  )

  return { pairs, setPairs }
}

export function useTokenList(url: string): TokenAddressMap {
  const lists = useSelector<AppState, AppState['lists']['byUrl']>(state => state.lists.byUrl)

  return useMemo(() => {
    const current = lists[url]?.current
    if (!current) return EMPTY_LIST
    return listToTokenMap(current)
  }, [lists, url])
}

export function useDefaultTokenList(): TokenAddressMap {
  return useTokenList(DEFAULT_TOKEN_LIST_URL)
}

export function useFixedTokenList(): WrappedTokenInfo[] {
  const { chainId } = useActiveWeb3React()
  const lists = useSelector<AppState, AppState['lists']['byUrl']>(state => state.lists.byUrl)

  return useMemo(() => {
    const current = lists[DEFAULT_TOKEN_LIST_URL]?.current
    if (!current) return []
    return (
      (current as any)?.fixedTokens
        ?.filter((item: TokenInfo) => item.chainId === chainId)
        ?.map((item: TokenInfo) => new WrappedTokenInfo(item)) || []
    )
  }, [lists, chainId])
}

export function useFixedTokens(): { [address: string]: WrappedTokenInfo } {
  const tokens = useFixedTokenList()
  return useMemo(() => {
    const map: { [address: string]: WrappedTokenInfo } = {}
    tokens.forEach(token => {
      map[token.address] = token
    })

    return map
  }, [tokens])
}

// returns all downloaded current lists
export function useAllLists(): TokenList[] {
  const lists = useSelector<AppState, AppState['lists']['byUrl']>(state => state.lists.byUrl)

  return useMemo(
    () =>
      Object.keys(lists)
        .map(url => lists[url].current)
        .filter((l): l is TokenList => Boolean(l)),
    [lists]
  )
}
