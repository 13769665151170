import { useMemo } from 'react'
import { useActiveWeb3React } from '.'
import { ChainId } from '@safemoon/sdk'

const GASLESS_SUPPORT_CHAINS = [
  ChainId.BSC_MAINNET,
  ChainId.AVALANCHE_C,
  ChainId.MAINNET,
  ChainId.POLYGON,
  ChainId.ARB_MAINNET,
  ChainId.BASE_MAINNET,
  ChainId.FANTOM,
  ChainId.OPTIMISM,
  ChainId.BLAST
]

const OX_SUPPORT_CHAINS = [
  ChainId.BSC_MAINNET,
  ChainId.MAINNET,
  ChainId.POLYGON,
  ChainId.ARB_MAINNET,
  ChainId.AVALANCHE_C,
  ChainId.BASE_MAINNET,
  ChainId.LINEA,
  ChainId.FANTOM,
  ChainId.OPTIMISM,
  ChainId.BLAST
]

export const useSupportedGasless = () => {
  const { chainId } = useActiveWeb3React()

  const isSupportedGasLess = useMemo(() => {
    return chainId && GASLESS_SUPPORT_CHAINS?.indexOf(chainId) !== -1
  }, [chainId])

  return isSupportedGasLess
}

export const use0xApiSupport = () => {
  const { chainId } = useActiveWeb3React()

  const isSupport0xApi = useMemo(() => {
    return chainId && OX_SUPPORT_CHAINS?.indexOf(chainId) !== -1
  }, [chainId])

  return isSupport0xApi
}
