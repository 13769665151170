import { ChainId, WETH } from '@safemoon/sdk'
import React, { useCallback, useContext, useMemo } from 'react'
import { ArrowDown } from 'react-feather'
import { ThemeContext } from 'styled-components'
import QuestionHelper from '../../components/QuestionHelper'
import { RowBetween, RowFixed } from '../../components/Row'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../../components/TransactionConfirmationModal'
import { BirdgeChain } from '../../constants'
import { TYPE } from '../../theme'
import { formatStringToNumber, formatStringToPrice } from '../../utils'

export default function ConfirmBridgeModal({
  onConfirm,
  onDismiss,
  swapErrorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  inputChain,
  outputChain,
  priceUsd,
  bridgeInfo
}: {
  isOpen: boolean
  attemptingTxn: boolean
  txHash: string | undefined
  onConfirm: () => void
  swapErrorMessage: string | undefined
  onDismiss: () => void
  inputChain: {
    value: string
    chain: BirdgeChain
  }
  outputChain: {
    value: string
    chain: BirdgeChain
  }
  priceUsd?: any
  bridgeInfo: any
}) {
  const theme = useContext(ThemeContext)

  // text to show while loading
  const pendingText = `Swapping ${formatStringToNumber(inputChain.value, 6)} ${
    inputChain?.chain?.chainName
  }/SFM for ${formatStringToNumber(outputChain?.value, 6)} ${outputChain?.chain?.chainName}/SFM`

  const modalHeader = useCallback(() => {
    return (
      <>
        <div className="flex items-center justify-between mt-[30px] mb-[10px]">
          <div className="flex items-center flex-1">
            <img src={inputChain?.chain?.images[0]} className="w-[30px] mr-[12px]" alt="icon" />
            <p className="text-[20px] font-bold">Send: {formatStringToNumber(inputChain.value)}</p>
          </div>
          <div className="flex items-center">
            <img src={inputChain?.chain?.images[1]} className="w-[30px] mr-[12px]" alt="icon" />
            <p className="text-[20px] font-bold capitalize">{inputChain?.chain?.shortChain}</p>
          </div>
        </div>

        <RowFixed>
          <ArrowDown size="24" color={theme.text2} style={{ marginLeft: '3px', minWidth: '24px' }} />
        </RowFixed>

        <div className="flex items-center justify-between mt-[10px]">
          <div className="flex items-center flex-1">
            <img src={outputChain?.chain?.images[0]} className="w-[30px] mr-[12px]" alt="icon" />
            <p className="text-[20px] font-bold">Receive: {formatStringToNumber(outputChain.value)}</p>
          </div>
          <div className="flex items-center">
            <img src={outputChain?.chain?.images[1]} className="w-[30px] mr-[12px]" alt="icon" />
            <p className="text-[20px] font-bold capitalize">{outputChain?.chain?.shortChain}</p>
          </div>
        </div>
      </>
    )
  }, [inputChain, outputChain, theme.text2])

  const chainPriceUsd = useMemo(() => {
    return +(priceUsd[WETH[inputChain?.chain?.chainId as ChainId]?.address?.toLowerCase()] || 0)
  }, [priceUsd, inputChain])

  const modalBottom = useCallback(() => {
    return (
      <div>
        <p className="text-[14px] label-text mb-[12px]">
          Conversion rate for bridging is 1:1 - You will receive an identical amount of tokens on the receiving
          blockchain.
        </p>

        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Tokenomics Fee
            </TYPE.black>
            <QuestionHelper size={'xs'} text="There is 0% Token Tax for Bridge Transactions." />
          </RowFixed>
          <TYPE.black fontSize={14}>0</TYPE.black>
        </RowBetween>

        <RowBetween marginTop={'8px'}>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Bridge Fee
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="There is a small fee in native coin to cover the gas costs and bridge maintenance. The fee is dynamic based on network activity and the cost to mint your tokens on the receiving chain."
            />
          </RowFixed>
          <TYPE.black fontSize={14}>
            {formatStringToNumber(+bridgeInfo.fee / 10 ** 18, 8)} {inputChain?.chain?.currency} ($
            {formatStringToPrice(chainPriceUsd * (+bridgeInfo.fee / 10 ** 18))})
          </TYPE.black>
        </RowBetween>

        <RowBetween marginTop={'8px'}>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Network Fee
            </TYPE.black>
            <QuestionHelper size={'xs'} text="This is the gas fee charged by the blockchain for this transaction." />
          </RowFixed>
          <TYPE.black fontSize={14}>
            {bridgeInfo?.networkFee
              ? `${formatStringToNumber(+bridgeInfo?.networkFee, 8)} ${
                  inputChain?.chain?.currency
                } ($${formatStringToPrice(+bridgeInfo?.networkFee * chainPriceUsd)})`
              : '-'}
          </TYPE.black>
        </RowBetween>

        <RowBetween marginTop={'8px'}>
          <RowFixed>
            <TYPE.black fontSize={14} fontWeight={400} color={theme.textLabel}>
              Max {inputChain?.chain?.currency} Total
            </TYPE.black>
            <QuestionHelper
              size={'xs'}
              text="This is the maximum amount you will spend on this transaction in the chains Native Coin."
            />
          </RowFixed>
          <TYPE.black fontSize={14} color={theme.yellow1}>
            {bridgeInfo?.fee
              ? `${formatStringToNumber(+bridgeInfo?.fee / 10 ** 18 + +bridgeInfo.networkFee, 8)} ${
                  inputChain?.chain?.currency
                } ($${formatStringToPrice((+bridgeInfo?.fee / 10 ** 18 + +bridgeInfo.networkFee) * chainPriceUsd)})`
              : '-'}
          </TYPE.black>
        </RowBetween>
        {bridgeInfo.isInsufficient ? (
          <div className="btn btnError mt-[24px]">Insufficient {inputChain?.chain?.currency}</div>
        ) : (
          <div className="btn btnMain mt-[24px]" onClick={onConfirm}>
            Confirm
          </div>
        )}
      </div>
    )
  }, [inputChain, theme.textLabel, onConfirm, bridgeInfo, chainPriceUsd, theme.yellow1])

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Bridge Transaction"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
