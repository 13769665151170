import { useEffect, useMemo, useState } from 'react'
import { Token } from '@safemoon/sdk'
import { AddressZero } from '@ethersproject/constants'

import { toV2LiquidityToken } from '../state/user/hooks'
import { useFactoryContract } from '../hooks/useContract'
import { useSingleContractMultipleData, NEVER_RELOAD } from '../state/multicall/hooks'
import { useActiveWeb3React } from '../hooks'

export function usePairAddresses(tokens: (Token | undefined)[][]): (string | undefined)[] {
  const [hasPair, setHasPair] = useState(false)

  const { chainId } = useActiveWeb3React()

  useEffect(() => {
    if (chainId === 1) {
      setHasPair(false)
    }
  }, [chainId])

  const contract = useFactoryContract()

  const tokensList = tokens?.map(([tokenA, tokenB]) => [
    tokenA?.address || '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    tokenB?.address || '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
  ])
  const args = useMemo(() => tokensList, [tokensList])

  const data = useSingleContractMultipleData(contract, 'getPair', args, hasPair ? NEVER_RELOAD : undefined)
  if (
    !hasPair &&
    (data?.map(({ result }) => result?.[0] && result[0] !== AddressZero && result[0]) ?? [])?.find(item => item)
  ) {
    setHasPair(true)
  }

  return useMemo(() => data?.map(({ result }) => result?.[0] && result[0] !== AddressZero && result[0]) ?? [], [data])
}

export function useTokenPairsWithLiquidityTokens(
  tokens: [Token, Token][]
): {
  liquidityToken: Token
  tokens: [Token, Token]
}[] {
  const contract = useFactoryContract()

  const tokensList = tokens?.map(([tokenA, tokenB]) => [tokenA?.address, tokenB?.address])
  const args = useMemo(() => tokensList, [tokensList])

  const data = useSingleContractMultipleData(contract, 'getPair', args, NEVER_RELOAD)

  return useMemo(
    () =>
      data
        .map(
          ({ result }, index) =>
            result?.[0] &&
            result[0] !== AddressZero && {
              liquidityToken: toV2LiquidityToken(tokens[index], result[0]),
              tokens: tokens[index]
            }
        )
        .filter(result => !!result),
    [data, tokens]
  )
}
