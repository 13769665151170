import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { ChainId, JSBI, Percent, Router, SwapParameters, Trade, CurrencyAmount, TradeType } from '@safemoon/sdk'
import { useEffect, useMemo, useState } from 'react'
import { BIPS_BASE, DEFAULT_DEADLINE_FROM_NOW, INITIAL_ALLOWED_SLIPPAGE } from '../constants'
import { getTradeVersion, useV1TradeExchangeAddress } from '../data/V1'
import { useSwapFee } from '../data/SwapFee'
import { useTransactionAdder } from '../state/transactions/hooks'
import { calculateGasMargin, getTradeSwapContract, getRouterETHContract, isAddress, shortenAddress } from '../utils'
import isZero from '../utils/isZero'
import v1SwapArguments from '../utils/v1SwapArguments'
import { useActiveWeb3React } from './index'
import { useV1ExchangeContract } from './useContract'
import useENS from './useENS'
import { Version } from './useToggledVersion'
import { useGasPrice } from '../state/user/hooks'
import getTokenSymbol, { getNativeSymbol } from '../utils/getTokenSymbol'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}

interface SwapCall {
  contract: Contract
  parameters: SwapParameters
}

interface SuccessfulCall {
  call: SwapCall
  gasEstimate: BigNumber
}

interface FailedCall {
  call: SwapCall
  error: Error
}

type EstimatedSwapCall = SuccessfulCall | FailedCall

/**
 * Returns the swap calls that can be used to make the trade
 * @param trade trade to execute
 * @param allowedSlippage user allowed slippage
 * @param deadline the deadline for the trade
 * @param recipientAddressOrName
 */
function useSwapCallArguments(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  deadline: number = DEFAULT_DEADLINE_FROM_NOW, // in seconds from now
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): SwapCall[] {
  const { account, chainId, library } = useActiveWeb3React()
  const amountIn = `0x${trade?.maximumAmountIn(new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE)).raw.toString(16)}`
  const ethFee = useSwapFee(amountIn.toString(), trade?.route.path)

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  const v1Exchange = useV1ExchangeContract(useV1TradeExchangeAddress(trade), true)

  return useMemo(() => {
    const tradeVersion = getTradeVersion(trade)
    if (!trade || !recipient || !library || !account || !tradeVersion || !chainId) return []

    const isTradeRouter = true

    let contract: any

    if (isTradeRouter) {
      contract = tradeVersion === Version.v2 ? getTradeSwapContract(chainId, library, account) : v1Exchange
    } else {
      contract = tradeVersion === Version.v2 ? getRouterETHContract(chainId, library, account) : v1Exchange
    }

    if (!contract) {
      return []
    }

    const swapMethods = []

    switch (tradeVersion) {
      case Version.v2:
        if (isTradeRouter && ethFee) {
          swapMethods.push(
            Router.swapCallParameters(trade, {
              // feeOnTransfer: false,
              // allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
              // recipient,
              // ttl: deadline,
              // fee
              chainId,
              allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
              recipient,
              ttl: deadline,
              ethFee: CurrencyAmount.ether(JSBI.BigInt(ethFee))
            })
          )
        } else if (!isTradeRouter) {
          swapMethods.push(
            Router.swapCallParameters(trade, {
              feeOnTransfer: false,
              allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
              recipient,
              ttl: deadline,
              chainId
            })
          )

          if (trade.tradeType === TradeType.EXACT_INPUT) {
            swapMethods.push(
              Router.swapCallParameters(trade, {
                feeOnTransfer: true,
                allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
                recipient,
                ttl: deadline,
                chainId
              })
            )
          }
        }

        break
      case Version.v1:
        swapMethods.push(
          v1SwapArguments(trade, {
            allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
            recipient,
            ttl: deadline
          })
        )
        break
    }
    return swapMethods.map(parameters => ({ parameters, contract }))
  }, [account, allowedSlippage, chainId, deadline, library, recipient, trade, ethFee, v1Exchange])
}

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: Trade | undefined, // trade to execute, required
  allowedSlippage: number = INITIAL_ALLOWED_SLIPPAGE, // in bips
  deadline: number = DEFAULT_DEADLINE_FROM_NOW, // in seconds from now
  recipientAddressOrName: string | null // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender
): { state: SwapCallbackState; callback: null | (() => Promise<string>); error: string | null; networkFee?: any } {
  const { account, chainId, library } = useActiveWeb3React()
  const gasPrice = useGasPrice()
  const [networkFee, setNetworkFee] = useState<any>({
    networkFee: 0,
    maxNetworkFee: 0,
    totalMaxFee: 0
  })
  const [currentTrade, setCurrentTrade] = useState<any>({
    inputCurrency: '',
    outputCurrency: '',
    executionPrice: 0,
    numberCalls: 0,
    gasPrice: 0
  })

  const swapCalls = useSwapCallArguments(trade, allowedSlippage, deadline, recipientAddressOrName)

  const addTransaction = useTransactionAdder()

  const { address: recipientAddress } = useENS(recipientAddressOrName)
  const recipient = recipientAddressOrName === null ? account : recipientAddress

  useEffect(() => {
    setNetworkFee(undefined)
  }, [chainId])

  useEffect(() => {
    const getNetworkFee = async () => {
      if (!trade || !library || !account || !chainId) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
      }
      if (!recipient) {
        if (recipientAddressOrName !== null) {
          return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
        } else {
          return { state: SwapCallbackState.LOADING, callback: null, error: null }
        }
      }

      const estimatedCalls: EstimatedSwapCall[] = await Promise.all(
        swapCalls.map(call => {
          const {
            parameters: { methodName, args, value },
            contract
          } = call
          const options = !value || isZero(value) ? {} : { value }

          // console.log('test ===>', methodName, args, options)

          return contract.estimateGas[methodName](...args, options)
            .then(gasEstimate => {
              return {
                call,
                gasEstimate
              }
            })
            .catch(gasError => {
              const errorMessage = gasError?.data?.message || gasError?.message || gasError
              // console.log('gasError', `${errorMessage}`)
              if (errorMessage.indexOf('insufficient funds') !== -1) {
                setNetworkFee({
                  insufficient: true
                })
              } else {
                setNetworkFee(null)
              }

              return contract.callStatic[methodName](...args, options)
                .then(result => {
                  // console.log('result ===> ', result)
                  return { call, error: new Error('Unexpected issue with estimating the gas. Please try again.') }
                })
                .catch(callError => {
                  let errorMessage: string
                  switch ((callError?.reason || callError?.data?.message || '')?.trim()) {
                    case 'UniswapV2Router: INSUFFICIENT_OUTPUT_AMOUNT':
                    case 'UniswapV2Router: EXCESSIVE_INPUT_AMOUNT':
                    case 'execution reverted: SafeswapRouter: INSUFFICIENT_OUTPUT_AMOUNT':
                    case 'execution reverted: SafeswapRouter: EXCESSIVE_INPUT_AMOUNT':
                    case 'SafeswapRouter: INSUFFICIENT_OUTPUT_AMOUNT':
                    case 'SafeswapRouter: EXCESSIVE_INPUT_AMOUNT':
                      errorMessage =
                        'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.'
                      break
                    case 'execution reverted: Safeswap: K':
                    case 'Safeswap: K':
                      errorMessage =
                        'This transaction will not succeed either due to price movement or fee on transfer. Try modifying the amount on "From" field.'
                      break
                    case 'SafeswapRouter: You must send enough BNB to cover fee':
                      errorMessage = `The transaction cannot succeed due to error: SafeswapRouter: You must send enough Native coin to cover fee. Make sure you have enough ${getNativeSymbol(
                        chainId
                      )} for this transaction, or try raising the slippage amount.`
                      break
                    default:
                      errorMessage = `The transaction cannot succeed due to error: ${
                        callError.reason
                      }. Make sure you have enough ${getNativeSymbol(
                        chainId
                      )} for this transaction, or try raising the slippage amount.`
                  }
                  return { call, error: new Error(errorMessage) }
                })
            })
        })
      )

      // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
      const successfulEstimation = estimatedCalls.find(
        (el, ix, list): el is SuccessfulCall =>
          'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
      )

      if (successfulEstimation) {
        const {
          call: {
            parameters: { value }
          },
          gasEstimate
        } = successfulEstimation
        console.log('value ? +value : 0 ===>', value ? +value : 0)
        const gasWithMargin = +calculateGasMargin(gasEstimate).toString()
        const gasPriceInWei = +gasPrice / 10 ** 9
        const networkFee = (gasWithMargin * (+gasPrice / 10 ** 9)) / 10 ** 9
        const maxNetworkFee = (gasWithMargin * gasPriceInWei) / 10 ** 9
        setNetworkFee({
          networkFee,
          maxNetworkFee,
          value: value ? +value / 10 ** 18 : 0
        })
      }
    }

    if (
      trade?.inputAmount?.currency?.symbol &&
      trade?.outputAmount?.currency?.symbol &&
      trade?.executionPrice?.toFixed() &&
      (trade?.inputAmount?.currency?.symbol !== currentTrade.inputCurrency ||
        trade?.outputAmount?.currency?.symbol !== currentTrade.outputCurrency ||
        trade?.executionPrice?.toFixed() !== currentTrade.executionPrice ||
        swapCalls?.length !== currentTrade.numberCalls ||
        gasPrice !== currentTrade.gasPrice)
    ) {
      setCurrentTrade({
        inputCurrency: trade?.inputAmount?.currency?.symbol,
        outputCurrency: trade?.outputAmount?.currency?.symbol,
        executionPrice: trade?.executionPrice?.toFixed(),
        numberCalls: swapCalls?.length,
        gasPrice: gasPrice
      })
      getNetworkFee()
    }
  }, [trade, library, account, chainId, recipient, recipientAddressOrName, swapCalls, currentTrade, gasPrice])

  return useMemo(() => {
    if (!trade || !library || !account || !chainId) {
      return { state: SwapCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }
    if (!recipient) {
      if (recipientAddressOrName !== null) {
        return { state: SwapCallbackState.INVALID, callback: null, error: 'Invalid recipient' }
      } else {
        return { state: SwapCallbackState.LOADING, callback: null, error: null }
      }
    }

    const tradeVersion = getTradeVersion(trade)

    return {
      networkFee,
      state: SwapCallbackState.VALID,

      callback: async function onSwap(): Promise<string> {
        const estimatedCalls: EstimatedSwapCall[] = await Promise.all(
          swapCalls.map(call => {
            const {
              parameters: { methodName, args, value },
              contract
            } = call
            const options = !value || isZero(value) ? {} : { value }

            console.log('estimateGas calls ====>', methodName, args, value, options)

            return contract.estimateGas[methodName](...args, options)
              .then(gasEstimate => {
                return {
                  call,
                  gasEstimate
                }
              })
              .catch(gasError => {
                // console.log('gasError ===> ', gasError)
                return contract.callStatic[methodName](...args, options)
                  .then(result => {
                    // console.log('result ===> ', result)
                    return { call, error: new Error('Unexpected issue with estimating the gas. Please try again.') }
                  })
                  .catch(callError => {
                    // console.log('callError ----<', callError)
                    let errorMessage: string
                    switch ((callError?.reason || callError?.data?.message || '')?.trim()) {
                      case 'UniswapV2Router: INSUFFICIENT_OUTPUT_AMOUNT':
                      case 'UniswapV2Router: EXCESSIVE_INPUT_AMOUNT':
                      case 'execution reverted: SafeswapRouter: INSUFFICIENT_OUTPUT_AMOUNT':
                      case 'execution reverted: SafeswapRouter: EXCESSIVE_INPUT_AMOUNT':
                      case 'SafeswapRouter: INSUFFICIENT_OUTPUT_AMOUNT':
                      case 'SafeswapRouter: EXCESSIVE_INPUT_AMOUNT':
                        errorMessage =
                          'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.'
                        break
                      case 'execution reverted: Safeswap: K':
                      case 'Safeswap: K':
                        errorMessage =
                          'This transaction will not succeed either due to price movement or fee on transfer. Try modifying the amount on "From" field.'
                        break
                      case 'SafeswapRouter: You must send enough BNB to cover fee':
                        errorMessage = `The transaction cannot succeed due to error: SafeswapRouter: You must send enough Native coin to cover fee. Make sure you have enough ${getNativeSymbol(
                          chainId
                        )} for this transaction, or try raising the slippage amount.`
                        break
                      default:
                        errorMessage = `The transaction cannot succeed due to error: ${
                          callError.reason
                        }. Make sure you have enough ${getNativeSymbol(
                          chainId
                        )} for this transaction, or try raising the slippage amount.`
                    }
                    return { call, error: new Error(errorMessage) }
                  })
              })
          })
        )

        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        const successfulEstimation = estimatedCalls.find(
          (el, ix, list): el is SuccessfulCall =>
            'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
        )

        if (!successfulEstimation) {
          const errorCalls = estimatedCalls.filter((call): call is FailedCall => 'error' in call)
          if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
          throw new Error('Unexpected error. Please contact support: none of the calls threw an error')
        }

        const {
          call: {
            contract,
            parameters: { methodName, args, value }
          },
          gasEstimate
        } = successfulEstimation

        console.log('swap calls ====>', methodName, args, value, gasPrice)

        return contract[methodName](...args, {
          gasLimit: calculateGasMargin(gasEstimate),
          gasPrice: gasPrice,
          ...(value && !isZero(value) ? { value, from: account } : { from: account })
        })
          .then((response: any) => {
            const inputSymbol = getTokenSymbol(trade.inputAmount.currency, chainId)
            const outputSymbol = getTokenSymbol(trade.outputAmount.currency, chainId)
            const inputAmount = trade.inputAmount.toSignificant(3)
            const outputAmount = trade.outputAmount.toSignificant(3)

            const base = `Swap ${inputAmount} ${inputSymbol} for ${outputAmount} ${outputSymbol}`
            const withRecipient =
              recipient === account
                ? base
                : `${base} to ${
                    recipientAddressOrName && isAddress(recipientAddressOrName)
                      ? shortenAddress(recipientAddressOrName)
                      : recipientAddressOrName
                  }`

            const withVersion =
              tradeVersion === Version.v2 ? withRecipient : `${withRecipient} on ${(tradeVersion as any).toUpperCase()}`

            addTransaction(response, {
              summary: withVersion
            })

            return response.hash
          })
          .catch((error: any) => {
            // if the user rejected the tx, pass this along
            if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') {
              throw new Error('Transaction rejected.')
            } else {
              // otherwise, the error was unexpected and we need to convey that
              throw new Error(`Swap failed: ${error.message || 'Transaction canceled by user'}`)
            }
          })
      },
      error: null
    }
  }, [
    trade,
    library,
    account,
    chainId,
    recipient,
    recipientAddressOrName,
    swapCalls,
    addTransaction,
    gasPrice,
    networkFee
  ])
}
